import { Box, Button } from '@mui/material';
import React, { FC } from 'react';
import { CaseAttachment } from '@oh-vcp/components-common';
import CaseService from '../services/caseService';
import useFileService from '../services/useFileService';

interface EconsultAttachmentLinkProps {
  attachment: CaseAttachment;
}

const EconsultAttachmentLink: FC<EconsultAttachmentLinkProps> = ({ attachment }) => {
  const { data: fileDetails } = useFileService(attachment?.fileId);
  const downloadAttachment = async (fileId: string | undefined, fileName: string | undefined) => {
    if (!fileId || !fileName) return;
    const fileUrl = await CaseService.getFileUrl(fileId);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const getFileName = () => {
    const fileName = `${attachment.uploadedFile?.fileName}${
      fileDetails?.fileSize ? ` - ${(fileDetails.fileSize / 1024 / 1024).toFixed(2)} MB` : ''
    }`;
    if (attachment.fileDescription) {
      return `${attachment.fileDescription} (${fileName})`;
    }
    return fileName;
  };

  return (
    <Box>
      <Button
        LinkComponent="a"
        onClick={() =>
          downloadAttachment(
            attachment.uploadedFile?.fileId?.toString(),
            attachment.uploadedFile?.fileName
          )
        }>
        {getFileName()}
      </Button>
    </Box>
  );
};

export default EconsultAttachmentLink;
