import { LinearProgress, Stack, styled, Typography } from '@mui/material';
import { FileWithProgress } from '@oh-vcp/components-common';
import React, { FC } from 'react';

interface EconsultAttachmentFileItemProps {
  file: FileWithProgress;
  onDelete?: (fileName: string) => void;
}

const FileProgressBar = styled(LinearProgress)({
  height: 10,
  marginTop: 6,
  width: '30%'
});

const EconsultAttachmentFileItem: FC<EconsultAttachmentFileItemProps> = ({ file, onDelete }) => {
  const handleDelete = (fileName: string) => {
    if (onDelete) {
      onDelete(fileName);
    }
  };
  return (
    <Stack direction="row" justifyContent="space-between" mb={1}>
      <Typography variant="body1" color="base.grey4">
        {file.file.name}
      </Typography>
      {file.progress > 0 && file.progress < 100 && (
        <FileProgressBar variant="determinate" value={file.progress} />
      )}
      <Typography
        variant="body1"
        color="base.grey4"
        sx={{ cursor: 'pointer' }}
        onClick={() => handleDelete(file.file.name)}>
        X
      </Typography>
    </Stack>
  );
};

export default EconsultAttachmentFileItem;
