import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  Theme,
  Typography,
  useMediaQuery
} from '@mui/material';
import React, { useState } from 'react';
import useSpecialtyLookup from '@oh-vcp/webapp-econsult/src/api/useSpecialtyLookup';
import useSubSpecialtyLookup from '@oh-vcp/webapp-econsult/src/api/useSubSpecialtyLookup';
import { useTranslation } from 'react-i18next';
import { useEconsultCaseDetailsContext } from '../econsultCaseDetailsContext/EconsultCaseDetailsContext';

const SpecialtySelect = styled(Select<string>)({
  minWidth: 200,
  height: 40
});

const EconsultCaseSpecialtySection = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const { caseDetails, setCaseDetails } = useEconsultCaseDetailsContext();
  const { data: programSpecialties } = useSpecialtyLookup(caseDetails?.referrerId);
  const { data: programSubSpecialties } = useSubSpecialtyLookup(
    caseDetails?.referrerId,
    caseDetails?.specialty?.code
  );
  if (!programSpecialties) {
    return null;
  }
  const handleSpecialtyChanged = (event: SelectChangeEvent) => {
    const specialtyCode = event.target.value;
    const selectedSpecialty = programSpecialties.find((s) => s.code === specialtyCode);
    const updatedCaseDetails = {
      ...caseDetails,
      specialty: selectedSpecialty,
      subSpecialty: undefined
    };
    setCaseDetails(updatedCaseDetails);
  };
  const handleSubSpecialtyChanged = (event: SelectChangeEvent) => {
    if (!programSubSpecialties) {
      return;
    }
    const subSpecialtyCode = event.target.value;
    const selectedSubSpecialty = programSubSpecialties.find((s) => s.subCode === subSpecialtyCode);
    const updatedCaseDetails = {
      ...caseDetails,
      subSpecialty: selectedSubSpecialty
    };
    setCaseDetails(updatedCaseDetails);
  };
  return (
    <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
      <SpecialtySelect
        onChange={handleSpecialtyChanged}
        value={caseDetails?.specialty?.code ?? '0'}>
        <MenuItem value="0">
          <Typography variant="body2">{t('Case.draft.selectCategory')}</Typography>
        </MenuItem>
        {programSpecialties.map((specialty) => (
          <MenuItem key={specialty.code} value={specialty.code}>
            <Typography variant="body2">{specialty.description}</Typography>
          </MenuItem>
        ))}
      </SpecialtySelect>
      {programSubSpecialties && (
        <SpecialtySelect
          onChange={handleSubSpecialtyChanged}
          value={caseDetails?.subSpecialty?.subCode ?? '0'}>
          <MenuItem value="0">
            <Typography variant="body2">{t('Case.draft.selectOption')}</Typography>
          </MenuItem>
          {programSubSpecialties.map((specialty, index) => (
            <MenuItem key={index} value={specialty.subCode}>
              <Typography variant="body2">{specialty.description}</Typography>
            </MenuItem>
          ))}
        </SpecialtySelect>
      )}
    </Stack>
  );
};

export default EconsultCaseSpecialtySection;
