import {
  CaseDetails,
  CaseDetailsResponsePayloadDTO,
  CaseNote,
  EconsultManagedSpecialtyProgram,
  FileMetaData,
  MirthResponse,
  NoteActionTypes,
  NoteStatusCodes,
  NoteTypes,
  UserDTO
} from '@oh-vcp/components-common';
import { AxiosProgressEvent } from 'axios';
import apiService from './apiService';

const CaseService = {
  upsertCaseDetails: async (caseDetails: CaseDetails): Promise<CaseDetailsResponsePayloadDTO> => {
    const url = `cases/`;
    const response = await apiService.post(url, caseDetails);
    return response.data;
  },
  upsertCaseNote: async (caseNote: CaseNote): Promise<CaseNote> => {
    const response = await apiService.post('cases/note', caseNote);
    return response.data?.data;
  },
  fetchCaseSpecialistsAndGroups: async (
    searchTerm: string
  ): Promise<(UserDTO | EconsultManagedSpecialtyProgram)[]> => {
    const url = `search/econsult/specialistsandprograms/list?q=${searchTerm}`;
    const response = await apiService.get(url);
    return response.data;
  },
  uploadFile: async (file: File, progressCallback: (progress: number) => void): Promise<any> => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const metadataResponse = await apiService.post('hubapidocuments/documents/', {
        fileName: file.name
      });
      const { url } = metadataResponse.data;
      const response = await apiService.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          if (progressEvent && progressEvent.total) {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            progressCallback(progress);
          }
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error during file upload:', error);
      throw error;
    }
  },
  getFileUrl: async (fileId: string): Promise<string> => {
    const response = await apiService.get(`hubapidocuments/documents/${fileId}`);
    if (response && response.data) {
      return response.data.url;
    }
    return '';
  },
  getLastActiveNote: (caseDetails: CaseDetails): CaseNote | null => {
    const sortedNotes = caseDetails.notes?.sort((a: CaseNote, b: CaseNote) =>
      !a.lastUpdated || !b.lastUpdated || a.lastUpdated > b.lastUpdated ? -1 : 1
    );
    let lastActiveNote: CaseNote | null = null;
    sortedNotes?.forEach((currentNote) => {
      if (
        lastActiveNote === null &&
        currentNote.statusCd === NoteStatusCodes.active &&
        currentNote.actionTypeCd !== NoteActionTypes.addNote
      ) {
        lastActiveNote = currentNote;
      }
    });
    return lastActiveNote;
  },
  containsConsultProvidedNote: (caseDetails: CaseDetails) => {
    let contains = false;
    const { notes } = caseDetails;
    notes?.forEach((currentNote) => {
      if (
        currentNote.noteTypeCd === NoteTypes.consultant &&
        currentNote.statusCd === NoteStatusCodes.active &&
        currentNote.actionTypeCd === NoteActionTypes.writeConsult
      ) {
        contains = true;
      }
    });
    return contains;
  },
  hasSpecialistResponded: (caseDetails: CaseDetails) => {
    let responded = false;
    const { notes } = caseDetails;
    notes?.forEach((currentNote) => {
      if (
        currentNote.noteTypeCd === NoteTypes.consultant &&
        currentNote.statusCd === NoteStatusCodes.active &&
        (currentNote.actionTypeCd === NoteActionTypes.writeConsult ||
          currentNote.actionTypeCd === NoteActionTypes.askForMoreInfo)
      ) {
        responded = true;
      }
    });
    return responded;
  },
  exportToHrm: async (caseId: string): Promise<MirthResponse> => {
    const response = await apiService.get(`cases/hrm/export/${caseId}`);
    return response.data;
  },
  getCaseDetails: async (caseId: number): Promise<CaseDetailsResponsePayloadDTO> => {
    const url = `cases/${caseId}`;
    const response = await apiService.get(url);
    return response.data;
  }
};

export default CaseService;
