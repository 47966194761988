import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Autocomplete, Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  EconsultManagedSpecialtyProgram,
  EconsultUtils,
  NameUtils,
  UserDTO
} from '@oh-vcp/components-common';
import { TextFieldNoOutline } from '@oh-vcp/components-ui';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useEconsultCaseDetailsContext } from '../econsultCaseDetailsContext/EconsultCaseDetailsContext';

interface EconsultSpecialistSearchProps {
  options: (UserDTO | EconsultManagedSpecialtyProgram)[];
  onInputChange: (inputValue: string) => void;
  onSelectionChange?: (selectedValue: UserDTO | EconsultManagedSpecialtyProgram | null) => void;
}

export interface EconsultSpecialistSearchRef {
  clear: () => void;
}

const EconsultSpecialistSearchTextField = styled(TextFieldNoOutline)({
  '& .MuiOutlinedInput-root': {
    paddingLeft: 0,
    '&.Mui-focused fieldset': {
      borderColor: 'transparent'
    }
  },
  '& .MuiOutlinedInput-input': {
    paddingLeft: '0px !important'
  },
  '& .MuiInputBase-input': {
    fontSize: '0.875rem'
  },
  '& .MuiInputBase-input::placeholder': {
    fontSize: '0.875rem'
  }
});

const EconsultSpecialistSearch = forwardRef<
  EconsultSpecialistSearchRef,
  EconsultSpecialistSearchProps
>((props, ref) => {
  const { t } = useTranslation();
  const { caseDetails } = useEconsultCaseDetailsContext();
  const { options, onInputChange, onSelectionChange } = props;
  const [internalInputValue, setInternalInputValue] = React.useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    clear() {
      setInternalInputValue('');
    }
  }));

  const getOptionLabel = (option: UserDTO | EconsultManagedSpecialtyProgram | string) => {
    if (typeof option === 'string') {
      return option;
    }
    if ('programName' in option) {
      const specialtyProgram = option as EconsultManagedSpecialtyProgram;
      return `${specialtyProgram.programName}${
        specialtyProgram.organization ? `, ${specialtyProgram.organization}` : ''
      }`;
    }
    return NameUtils.makeName(
      option.lastName || '',
      option.firstName,
      option.salutation,
      option.specialty,
      option.middleName,
      null,
      option.city,
      option.lhin?.number?.toString(),
      option?.unavailability
    );
  };

  const getLabelColor = (isDisabled: boolean, isHighlight: boolean) => {
    if (isDisabled) return 'base.grey3';
    if (isHighlight) return 'primary.custom5';
    return 'primary.main';
  };

  return (
    <Autocomplete
      options={options}
      inputValue={internalInputValue}
      value={caseDetails?.participant?.consultant || caseDetails?.participant?.program}
      getOptionLabel={(option: UserDTO | EconsultManagedSpecialtyProgram | string) =>
        getOptionLabel(option)
      }
      getOptionDisabled={(option) =>
        EconsultUtils.getOutOfOfficeEndDate(option.unavailability) !== null
      }
      freeSolo
      onChange={(event, newValue: UserDTO | EconsultManagedSpecialtyProgram | string | null) => {
        if (onSelectionChange) {
          if (typeof newValue !== 'string') {
            onSelectionChange(newValue);
          }
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInternalInputValue(newInputValue);
        onInputChange(newInputValue);
      }}
      renderInput={(params) => (
        <EconsultSpecialistSearchTextField
          {...params}
          ref={inputRef}
          placeholder={t('Case.draft.searchSpecialist') as string}
        />
      )}
      renderOption={(
        renderProps,
        option: UserDTO | EconsultManagedSpecialtyProgram | string,
        { inputValue }
      ) => {
        const optionLabel = getOptionLabel(option);
        const unavailable =
          typeof option !== 'string' && !('programName' in option)
            ? EconsultUtils.getOutOfOfficeEndDate(option.unavailability || '') !== null
            : '';
        const matches = match(optionLabel, inputValue, { insideWords: true });
        const parts = parse(optionLabel, matches);
        const { key, ...optionProps } = renderProps;
        return (
          <li key={key} {...optionProps}>
            <Box>
              {parts.map((part: any, index: any) => (
                <Typography
                  component="span"
                  variant="body2"
                  key={index}
                  sx={{
                    color: getLabelColor(Boolean(unavailable), part.highlight),
                    fontWeight: part.highlight && !unavailable ? 600 : 400
                  }}>
                  {part.text}
                </Typography>
              ))}
            </Box>
          </li>
        );
      }}
      componentsProps={{
        popper: {
          sx: {
            '& .MuiAutocomplete-listbox': {
              fontSize: '0.875rem'
            }
          }
        }
      }}
    />
  );
});

export default EconsultSpecialistSearch;
