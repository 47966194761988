import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup, Stack, TextField } from '@mui/material';

import { useTranslation } from 'react-i18next';
import {
  DraftNoteAttachment,
  EconsultProviderTypes,
  NoteActionTypes,
  NoteStatusCodes,
  NoteTypes
} from '@oh-vcp/components-common';
import {
  CaseAttachment,
  CaseDetails,
  Kpi
} from '@oh-vcp/components-common/src/models/case/CaseDetails';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CheckBoxOptionControl,
  EconsultTextField,
  useNotifierContext
} from '@oh-vcp/components-ui';
import moment from 'moment';
import EconsultDraftCaseActions from '../econsultDraftCaseActions/EconsultDraftCaseActions';
import EconsultSendDialog from '../econsultSendDialog/EconsultSendDialog';
import CaseService from '../services/caseService';
import { useAuth } from '../auth/AuthContext';
import { useEconsultCaseDetailsContext } from '../econsultCaseDetailsContext/EconsultCaseDetailsContext';
import { NotificationFactory } from '../notifier/NotificationFactory';
import NotificationsConfig from '../notifier/NotificationsConfig';
import EconsultDragAndDrop, {
  EconsultDragAndDropRef
} from '../econsultDragAndDrop/EconsultDragAndDrop';

interface EconsultCaseProvideConsultProps {
  showRecommendation?: boolean;
  noteFieldLabel?: string;
  actionType: (typeof NoteActionTypes)[keyof typeof NoteActionTypes];
  defaultContent?: string;
}

const EconsultCaseProvideConsult: FC<EconsultCaseProvideConsultProps> = ({
  showRecommendation,
  noteFieldLabel,
  actionType,
  defaultContent
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addNotification } = useNotifierContext();
  const { caseDetails, setCaseDetails } = useEconsultCaseDetailsContext();
  const { providerType } = useParams();
  const [caseAttachments, setCaseAttachments] = useState<DraftNoteAttachment[]>([]);
  const [isSendDialogOpen, setIsSendDialogOpen] = useState<boolean>(false);
  const [isRecommendedToBeSeen, setIsRecommendedToBeSeen] = useState<boolean>(false);
  const [noteContent, setNoteContent] = useState<string>(defaultContent || '');
  const attachFileRef = useRef<EconsultDragAndDropRef>(null);
  const { user } = useAuth();

  if (!user || !caseDetails) {
    return null;
  }

  const getNotificationMessage = () => {
    switch (actionType) {
      case NoteActionTypes.addNote:
        return t('EconsultNoteAdd.success.message');
      case NoteActionTypes.writeConsult:
        return t('EconsultConsultProvided.success.message');
      case NoteActionTypes.reply:
        return t('EconsultMoreInfoProvided.success.message');
      case NoteActionTypes.askForMoreInfo:
        return t('EconsultMoreInfoRequested.success.message');
      default:
        return t('EconsultGeneral.success.message');
    }
  };

  const handleSaved = async (kpi?: Kpi) => {
    const note = await CaseService.upsertCaseNote({
      ownerId: user.userId as number,
      noteTypeCd:
        providerType === EconsultProviderTypes.consultant
          ? NoteTypes.consultant
          : NoteTypes.referrer,
      actionTypeCd: actionType,
      caseId: caseDetails.caseId,
      statusCd: NoteStatusCodes.active,
      kpis: kpi ? [kpi] : undefined,
      noteContent,
      attachments: caseAttachments
        ? caseAttachments.map((c) => ({
            fileId: c.fileId,
            statusCd: c.statusCd
          }))
        : []
    });
    if (actionType !== NoteActionTypes.addNote) {
      const updatedCaseDetails = {
        ...caseDetails,
        statusCd: NoteStatusCodes.active,
        notes: [
          {
            ...note,
            patientToBeSeen: isRecommendedToBeSeen,
            kpis: note.kpis?.map(({ kpiId, caseId, ...rest }) => rest)
          }
        ]
      };
      setCaseDetails(updatedCaseDetails);
      await CaseService.upsertCaseDetails(updatedCaseDetails);
    }
    setIsSendDialogOpen(false);
    addNotification({
      type: 'success',
      message: getNotificationMessage()
    });
    navigate('/case/consultant/needsaction');
  };
  const handleDraftSaved = () => {};
  const handleSend = () => {
    if (providerType === EconsultProviderTypes.consultant) {
      setIsSendDialogOpen(true);
    } else {
      handleSaved(undefined);
    }
  };
  const handleCancel = () => {
    setIsSendDialogOpen(false);
  };
  const handleAddNoteAttachment = async (fileId: number, fileName: string) => {
    setCaseAttachments((prevAttachments) => [
      ...prevAttachments,
      {
        fileId,
        statusCd: NoteStatusCodes.active,
        fileName
      }
    ]);
    addNotification({
      type: 'success',
      message: t('Case.draft.fileAddedSuccessfully')
    });
  };
  const handleAttachmentDeleted = async (fileName: string) => {
    setCaseAttachments((prevFiles) => prevFiles.filter((f) => f.fileName !== fileName));
  };
  const handleNoteContentChanged = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNoteContent(event.target.value);
  };
  const handleFileAttach = () => {
    if (attachFileRef && attachFileRef.current) {
      attachFileRef.current.attachFile();
    }
  };
  return (
    <>
      <Stack direction="column">
        <EconsultTextField
          value={noteContent}
          onChange={handleNoteContentChanged}
          multiline
          rows={6}
          fullWidth
          placeholder={`${noteFieldLabel || (t('Case.consult.enterNote') as string)}...`}
        />
        {showRecommendation && (
          <FormGroup
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setIsRecommendedToBeSeen(event.target.checked)
            }>
            <CheckBoxOptionControl
              checked={isRecommendedToBeSeen}
              control={<Checkbox />}
              label={t('Case.consult.recommendation')}
            />
          </FormGroup>
        )}
        <Box mt={3}>
          <EconsultDraftCaseActions
            onSaveDraft={handleDraftSaved}
            onSend={handleSend}
            onAttach={handleFileAttach}
          />
        </Box>
        <Box mt={3} mb={3}>
          <EconsultDragAndDrop
            ref={attachFileRef}
            onFileUploaded={handleAddNoteAttachment}
            onFileDeleted={handleAttachmentDeleted}
          />
        </Box>
      </Stack>
      <EconsultSendDialog
        isOpen={isSendDialogOpen}
        onCancel={handleCancel}
        onConfirm={handleSaved}
      />
    </>
  );
};

export default EconsultCaseProvideConsult;
