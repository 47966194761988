import { Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CaseNote,
  NoteActionTypes,
  NoteStatusCodes,
  NoteStatusReasons,
  NoteTypes
} from '@oh-vcp/components-common';
import { useNavigate } from 'react-router-dom';
import { useNotifierContext } from '@oh-vcp/components-ui';
import { useEconsultCaseDetailsContext } from '../econsultCaseDetailsContext/EconsultCaseDetailsContext';
import CaseService from '../services/caseService';
import { useAuth } from '../auth/AuthContext';

const EconsultCaseRedirect = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { caseDetails, setCaseDetails } = useEconsultCaseDetailsContext();
  const navigate = useNavigate();
  const { addNotification } = useNotifierContext();
  const handleRedirect = async () => {
    if (!user) {
      return;
    }
    const { userid, firstName, lastName } = user;
    const noteToRedirect = await CaseService.upsertCaseNote({
      ownerId: user?.userId as number,
      noteTypeCd: NoteTypes.referrer,
      actionTypeCd: NoteActionTypes.forward,
      caseId: caseDetails?.caseId,
      statusCd: NoteStatusCodes.active
    });
    const updatedCaseDetails = {
      ...caseDetails,
      statusCd: NoteStatusCodes.closed,
      statusReason: NoteStatusReasons.cancelled,
      participant: {
        ...caseDetails?.participant,
        statusCd: NoteStatusCodes.closed,
        ...(caseDetails?.participant?.triageStatusCd && { triageStatusCd: NoteStatusCodes.closed })
      },
      notes: [noteToRedirect]
    };
    setCaseDetails(updatedCaseDetails);
    await CaseService.upsertCaseDetails(updatedCaseDetails);
    if (userid && firstName && lastName) {
      const firstNote: CaseNote | undefined = caseDetails?.notes?.[0];
      const newCaseNote: CaseNote = {};
      if (firstNote) {
        newCaseNote.statusCd = NoteStatusCodes.draft;
        newCaseNote.noteContent = firstNote.noteContent;
        newCaseNote.noteTypeCd = NoteTypes.referrer;
        newCaseNote.attachments = firstNote.attachments;
      }
      const { patientId, ...patientWithoutId } = caseDetails?.patient || {};
      const response = await CaseService.upsertCaseDetails({
        statusCd: NoteActionTypes.draft,
        originalCaseId: caseDetails?.caseId,
        patient: patientWithoutId,
        referrer: {
          userId: userid
        },
        notes: newCaseNote ? [newCaseNote] : []
      });

      if (response?.data?.caseId) {
        navigate(`/case/${NoteTypes.referrer}/${NoteActionTypes.draft}/${response.data.caseId}`);
        addNotification({
          type: 'success',
          message: t('Case.consult.redirectSuccess')
        });
      }
    }
  };
  return (
    <>
      <Typography component="p">{t('Case.consult.redirectConfirmation')}</Typography>
      <Typography variant="body4" component="p" sx={{ mt: 1 }}>
        {t('Case.consult.redirectSecondaryConfirmation')}
      </Typography>
      <Typography variant="body4" component="p" sx={{ mt: 1 }}>
        {t('Case.consult.redirectCurrentCaseWillBeCancelled')}
      </Typography>
      <Button sx={{ mt: 1, mb: 3 }} variant="contained" onClick={handleRedirect}>
        {t('Case.consult.redirect')}
      </Button>
    </>
  );
};

export default EconsultCaseRedirect;
