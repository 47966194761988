import { useMutation } from 'react-query';
import apiService from './apiService';

const addSeenPriorityRegion = async () => {
  await apiService.post(`/users/selfserve/seenPriorityRegionPrompt`, {
    ignoreError: true
  });
};

const usePriorityRegion = () => {
  return useMutation({
    mutationFn: () => addSeenPriorityRegion()
  });
};

export default usePriorityRegion;
