import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { EconsultCancelButton } from '@oh-vcp/components-ui';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface EconsultCompleteCaseDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
}

const EconsultCompleteCaseDialog: FC<EconsultCompleteCaseDialogProps> = ({
  isOpen,
  onClose,
  onComplete
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h2" color="base.grey4">
          {t('Case.consult.completeCase')}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ width: 400 }}>
        <Typography color="base.grey4" mb={1}>
          {t('Case.consult.completeCase2')}
        </Typography>
        <Typography color="base.grey4" mb={3}>
          {t('Case.consult.completeCase3')}
        </Typography>
        <Stack direction="row" justifyContent="end">
          <Button variant="contained" color="primary" sx={{ mr: 1 }} onClick={onComplete}>
            {t('Case.button.complete')}
          </Button>
          <EconsultCancelButton variant="contained" onClick={onClose}>
            {t('Case.button.cancel')}
          </EconsultCancelButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default EconsultCompleteCaseDialog;
