import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography
} from '@mui/material';
import { CaseDetails, CaseNote, Kpi } from '@oh-vcp/components-common/src/models/case/CaseDetails';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  NoteActionTypes,
  NoteParticipantStatusReasons,
  NoteStatusCodes,
  NoteStatusReasons,
  NoteTypes
} from '@oh-vcp/components-common';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CheckBoxOptionControl,
  EconsultTextField,
  useNotifierContext
} from '@oh-vcp/components-ui';
import { useEconsultCaseDetailsContext } from '../econsultCaseDetailsContext/EconsultCaseDetailsContext';
import CaseService from '../services/caseService';
import { useAuth } from '../auth/AuthContext';
import { useEconsultTabContext } from '../econsultTabContext/EconsultTabContext';
import NotificationsConfig from '../notifier/NotificationsConfig';
import { NotificationFactory } from '../notifier/NotificationFactory';

interface EconsultCaseCompleteProps {
  actionType: 'complete' | 'cancel';
}

const EconsultCaseComplete: FC<EconsultCaseCompleteProps> = ({ actionType }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addNotification } = useNotifierContext();
  const { providerType } = useParams();
  const { user } = useAuth();
  const { caseDetails, setCaseDetails } = useEconsultCaseDetailsContext();
  const { setSelectedTab } = useEconsultTabContext();
  const [specialistFeedback, setSpecialistFeedback] = useState<string>('');
  const [programFeedback, setProgramFeedback] = useState<string>('');
  const [feedbackConsent, setFeedbackConsent] = useState<boolean>(false);
  const [contactConsent, setContactConsent] = useState<boolean>(false);

  const kpis: Kpi[] = [
    {
      kpiQuestion: 'Cancelled case feedback to the eConsult Program'
    },
    {
      kpiQuestion:
        'I consent to be contacted by the eConsult Centre of Excellence or Ontario Health in response to my feedback.',
      isCheckbox: true
    },
    {
      kpiQuestion: 'I consent to have my feedback shared with the specialist.',
      isCheckbox: true
    }
  ];
  const getNotificationMessage = () => {
    let message = { message: '' };
    switch (actionType) {
      case 'cancel':
        message = NotificationsConfig.econsultCancelConsult.success;
        break;
      case 'complete':
      default:
        message = NotificationsConfig.econsultCompleteConsult.success;
        break;
    }

    return message;
  };
  const handleComplete = async () => {
    if (
      ((contactConsent || feedbackConsent) && programFeedback) ||
      (!contactConsent && !feedbackConsent)
    ) {
      kpis[1].kpiAnswer = Number(contactConsent);
      kpis[2].kpiAnswer = Number(feedbackConsent);
      kpis[1].kpiComment = contactConsent ? 'Yes' : 'No';
      kpis[2].kpiComment = feedbackConsent ? 'Yes' : 'No';
      kpis[0].kpiComment = programFeedback;

      let noteToCancelOrComplete: CaseNote | undefined;

      if (actionType === 'cancel') {
        const cancelledNote = caseDetails?.notes?.find(
          (n) => n.actionTypeCd === NoteActionTypes.cancel
        );

        if (cancelledNote) {
          noteToCancelOrComplete = cancelledNote;
          noteToCancelOrComplete.kpis = kpis[1].kpiComment ? kpis : undefined;
        }
      }

      if (!noteToCancelOrComplete) {
        noteToCancelOrComplete = await CaseService.upsertCaseNote({
          ownerId: user?.userId as number,
          noteTypeCd: NoteTypes.referrer,
          actionTypeCd: actionType === 'complete' ? NoteActionTypes.finish : NoteActionTypes.cancel,
          caseId: caseDetails?.caseId,
          statusCd: NoteStatusCodes.active
        });
        noteToCancelOrComplete.kpis = kpis[1].kpiComment ? kpis : undefined;
      }
      const updatedCaseDetails: CaseDetails = {
        ...caseDetails,
        statusCd: NoteStatusCodes.closed,
        statusReason:
          actionType === 'complete' ? NoteStatusReasons.closed : NoteStatusReasons.cancelled,
        participant: {
          ...caseDetails?.participant,
          statusCd: NoteStatusCodes.closed,
          statusReason:
            actionType === 'complete'
              ? NoteParticipantStatusReasons.closedCompleted
              : NoteParticipantStatusReasons.consultantUnavailable,
          ...(caseDetails?.participant?.triageStatusCd && {
            triageStatusCd: NoteStatusCodes.closed
          })
        },
        notes: [noteToCancelOrComplete]
      };
      setCaseDetails(updatedCaseDetails);
      CaseService.upsertCaseDetails(updatedCaseDetails);
      addNotification(NotificationFactory.createNotification('success', getNotificationMessage()));
      setSelectedTab(undefined);
      navigate(`/case/${providerType}/needsaction`);
    }
  };

  return (
    <>
      {actionType === 'complete' && (
        <EconsultTextField
          value={specialistFeedback}
          onChange={(event) => setSpecialistFeedback(event.target.value)}
          multiline
          rows={3}
          fullWidth
          placeholder={t('Case.consult.provideCommentsOrFeedback') as string}
        />
      )}
      <EconsultTextField
        sx={{ mt: 3 }}
        value={programFeedback}
        onChange={(event) => setProgramFeedback(event.target.value)}
        multiline
        rows={3}
        fullWidth
        placeholder={t('Case.consult.provideCommentsOrFeedbackProgram') as string}
      />
      <FormGroup
        sx={{ mt: 1 }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setContactConsent(event.target.checked)
        }>
        <CheckBoxOptionControl
          checked={contactConsent}
          name="noOhip"
          control={<Checkbox />}
          label={t('Case.consult.contactConsent')}
        />
      </FormGroup>
      <FormGroup
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setFeedbackConsent(event.target.checked)
        }>
        <CheckBoxOptionControl
          checked={feedbackConsent}
          name="noOhip"
          control={<Checkbox />}
          label={t('Case.consult.feedbackConsent')}
        />
      </FormGroup>
      <Box>
        {actionType === 'complete' && (
          <Typography variant="body4">{t('Case.consult.consentNote')}</Typography>
        )}
        {actionType === 'complete' && (
          <>
            <br />
            <Typography variant="body4">{t('Case.consult.consentNote2')}</Typography>
          </>
        )}
        {actionType !== 'complete' && (
          <Typography variant="body4">{t('Case.consult.cancelNote')}</Typography>
        )}
      </Box>
      <Button sx={{ mt: 1, mb: 3 }} variant="contained" onClick={handleComplete}>
        {actionType === 'complete' ? t('Case.consult.complete') : t('Case.consult.cancel')}
      </Button>
    </>
  );
};

export default EconsultCaseComplete;
