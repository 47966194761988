import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Stack,
  styled,
  SvgIcon,
  Typography
} from '@mui/material';
import { textAlign } from '@mui/system';
import { RegionIcon } from '@oh-vcp/components-ui';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EconsultUserService from '../services/econsultUserService';
import { useAuth } from '../auth/AuthContext';
import usePriorityRegion from '../services/usePriorityRegion';

interface EconsultPriorityRegionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
}

const PriorityRegionDialogHeader = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(0deg, rgba(4, 158, 208, 1) 0%, rgba(0, 120, 207, 1) 100%)',
  width: 500,
  color: 'white'
}));

const EconsultPriorityRegionDialog: FC<EconsultPriorityRegionDialogProps> = ({
  isOpen,
  onClose,
  onComplete
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [isDelegate, setIsDelegate] = useState<boolean>(false);
  const [isPriorityRegionSet, setIsPriorityRegionSet] = useState<boolean>(false);
  const usePriorityRegionMutation = usePriorityRegion();

  useEffect(() => {
    if (user) {
      const isDelegateUser = EconsultUserService.isEconsultDelegate(user);
      setIsDelegate(Boolean(isDelegateUser));
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      setIsPriorityRegionSet(false);
    }
  }, [isOpen]);

  const handleSkip = async () => {
    await usePriorityRegionMutation.mutateAsync();
    onClose();
  };
  const handleSetPriorityRegion = () => {
    onComplete();
    setIsPriorityRegionSet(true);
  };
  return (
    <Dialog open={isOpen}>
      <DialogContent sx={{ padding: 0 }}>
        <PriorityRegionDialogHeader p={2}>
          <Stack direction="column" alignItems="center">
            <SvgIcon component={RegionIcon} viewBox="-10 0 100 50" sx={{ width: 80, height: 80 }} />
            <Typography variant="h3">{t('Case.priorityRegionDialog.priorityRegions')}</Typography>
            {isDelegate ? (
              <Typography variant="body1">
                {t('Case.priorityRegionDialog.getAccessDelegate')}
              </Typography>
            ) : (
              <Typography variant="body1">{t('Case.priorityRegionDialog.getAccess')}</Typography>
            )}
          </Stack>
        </PriorityRegionDialogHeader>
        <Box width={500} px={4} pt={4}>
          {isPriorityRegionSet ? (
            <Typography variant="body2" color="base.grey4">
              {t('Case.priorityRegionDialog.thankYou')}
            </Typography>
          ) : (
            <Box>
              {isDelegate ? (
                <>
                  <Typography variant="body2" color="base.grey4">
                    {t('Case.priorityRegionDialog.note1Delegate')}
                  </Typography>
                  <Typography variant="body2" color="base.grey4" mt={1.5}>
                    {t('Case.priorityRegionDialog.note2Delegate')}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="body2" color="base.grey4">
                    {t('Case.priorityRegionDialog.note1')}
                  </Typography>
                  <Typography variant="body2" color="base.grey4" mt={1.5}>
                    {t('Case.priorityRegionDialog.note2')}
                  </Typography>
                </>
              )}
            </Box>
          )}
        </Box>
        <Stack
          direction="row"
          ml={isDelegate || isPriorityRegionSet ? '' : 1.5}
          mb={3}
          mt={3}
          justifyContent={isDelegate || isPriorityRegionSet ? 'center' : ''}>
          {isDelegate || isPriorityRegionSet ? (
            <Button variant="contained" onClick={handleSkip}>
              <Typography variant="body2" color="white">
                {t('Case.priorityRegionDialog.close')}
              </Typography>
            </Button>
          ) : (
            <>
              <Button variant="text" onClick={handleSkip}>
                <Typography variant="body2" color="primary.main">
                  {t('Case.priorityRegionDialog.skip')}
                </Typography>
              </Button>
              <Button variant="contained" sx={{ ml: 8 }} onClick={handleSetPriorityRegion}>
                <Typography variant="body2" color="white">
                  {t('Case.priorityRegionDialog.setPriorityRegions')}
                </Typography>
              </Button>
            </>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default EconsultPriorityRegionDialog;
