import React, { useContext, useRef, useState } from 'react';
import { useEconsultCaseDetailsContext } from '@oh-vcp/components-web/src/econsultCaseDetailsContext/EconsultCaseDetailsContext';
import { useEffect } from 'react';
import useCaseDetails from '../../api/useCaseDetails';
import EconsultCaseConsultDetailsView from '../econsultCaseConsultDetailsView/EconsultCaseConsultDetailsView';
import {
  CaseDetails,
  FhoProfile,
  FhoProfileContext,
  NoteActionTypes
} from '@oh-vcp/components-common';
import EconsultCaseDraftDetailsView from '../econsultCaseDraftDetailsView/EconsultCaseDraftDetailsView';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DisplayMobile, useNotifierContext } from '@oh-vcp/components-ui';
import { Box } from '@mui/material';
import useAssignerPrograms from '../../api/useAssignerPrograms';
import { useTranslation } from 'react-i18next';
import CaseService from '@oh-vcp/components-web/src/services/caseService';
import { hasCaseChanged } from '@oh-vcp/components-common/src/utils/econsultCaseUtils';
import {useQueryClient} from "react-query";

const EconsultCaseDetailsView = () => {
  const { t } = useTranslation();
  const { addNotification } = useNotifierContext();
  const {
    setCaseDetails,
    caseDetails,
    setDraftCaseSaveDisable,
    draftCaseSaveDisable,
    setUserRole,
    setAssignerPrograms
  } = useEconsultCaseDetailsContext();
  const { caseId, providerType, statusType } = useParams();
  const navigate = useNavigate();
  const { data: caseDetailsResponse } = useCaseDetails(
    caseId ? parseInt(caseId) : undefined,
    (errorDetails: any) => {
      addNotification({
        id: caseId ? parseInt(caseId) : 1,
        type: 'error',
        message: t('Case.consult.errorLoadingCase'),
        solution: t('Case.consult.errorLoadingCaseSolution') as string,
        details: JSON.stringify(errorDetails?.response)
      });
    }
  );
  const { data: programs } = useAssignerPrograms();

  const autoSaveTimer = 30000;
  const intervalIdRef = useRef<NodeJS.Timer>();
  const updatedCaseDetails = useRef<CaseDetails>();
  const previousCaseDetails = useRef<CaseDetails>();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (caseDetailsResponse?.data) {
      setUserRole(caseDetailsResponse?.userRole);
      setCaseDetails(caseDetailsResponse.data);
    }
  }, [caseDetailsResponse, setUserRole, setCaseDetails]);

  useEffect(() => {
    setAssignerPrograms(programs);
  }, [programs, setAssignerPrograms]);

  const handleGoBack = () => {
    navigate(`/case/${providerType}/${statusType}`);
  };

  useEffect(() => {
    console.log('caseDetails changed - ' + caseDetails?.caseId);
    updatedCaseDetails.current = caseDetails;
  }, [caseDetails]);

  useEffect(() => {
    return () => {
      clearDraftSaveTimer();
      console.log('cleaned up');
    };
  }, []);

  const handleDraftSaveTimer = async () => {
    console.log(
      'timer starting... id=' + intervalIdRef.current + ', ' + updatedCaseDetails.current?.statusCd
    );
    if (
      updatedCaseDetails.current &&
      updatedCaseDetails.current.caseId &&
      updatedCaseDetails.current.statusCd === 'DRAFT' &&
      hasCaseChanged(updatedCaseDetails.current, previousCaseDetails.current)
    ) {
      console.log('autoSave - ' + updatedCaseDetails.current.caseId + ', ' + intervalIdRef.current);
      setDraftCaseSaveDisable(true);
      await CaseService.upsertCaseDetails(updatedCaseDetails.current);
      previousCaseDetails.current = JSON.parse(JSON.stringify(updatedCaseDetails.current));
      setTimeout(() => {
        queryClient.invalidateQueries(['caseResults']);
      }, 500);
      setDraftCaseSaveDisable(false);
    }
  };

  const clearDraftSaveTimer = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      console.log('cleared, ' + intervalIdRef.current);
      intervalIdRef.current = undefined;
    }
  };

  if (!intervalIdRef.current) {
    intervalIdRef.current = setInterval(handleDraftSaveTimer, autoSaveTimer);
    console.log('timer created... id=' + intervalIdRef.current);
  }

  return (
    <>
      <DisplayMobile>
        <Box mt={2}>
          <ArrowBackIcon onClick={handleGoBack} />
        </Box>
      </DisplayMobile>
      {caseDetails?.statusCd?.toUpperCase() === NoteActionTypes.draft ? (
        <EconsultCaseDraftDetailsView />
      ) : (
        <EconsultCaseConsultDetailsView />
      )}
    </>
  );
};

export default EconsultCaseDetailsView;
