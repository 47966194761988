import React, { useEffect } from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Stack } from '@mui/material';
import {
  Footer,
  NotificationsViewer,
  NotifierContextProvider,
  OHTheme,
  TopNavigation
} from '@oh-vcp/components-ui';
import { AppLayout, storageUtils } from '@oh-vcp/components-common';
import { Login, LoginCallback, Logout, PrivateRoute, useAuth } from '@oh-vcp/components-web';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import EconsultSettings from './cases/econsultSettings/EconsultSettings';
import EconsultCaseView from './cases/econsultCaseView/EconsultCaseView';
import { useEconsultUserSettingService } from '@oh-vcp/components-web';

const App = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const token = storageUtils.getUserToken();
  const { data: userSettings } = useEconsultUserSettingService(token);

  useEffect(() => {
    document.title = t('title.eConsult.home');
  }, [t]);

  return (
    <ThemeProvider theme={OHTheme}>
      <CssBaseline />
      <AppLayout about="AppLayout">
        <TopNavigation user={user} hideProfileOption userSettings={userSettings} />
        <Stack flexGrow={1} sx={{ flexDirection: { lg: 'column', xs: 'row' } }}>
          <NotifierContextProvider>
            <NotificationsViewer />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/login_callback" element={<LoginCallback />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/settings" element={<EconsultSettings />} />
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <EconsultCaseView />
                  </PrivateRoute>
                }
              />
              <Route
                path="/case/:providerType/:statusType?/:caseId?"
                element={
                  <PrivateRoute>
                    <EconsultCaseView />
                  </PrivateRoute>
                }
              />
            </Routes>
          </NotifierContextProvider>
        </Stack>
        <Footer />
      </AppLayout>
    </ThemeProvider>
  );
};

export default App;
