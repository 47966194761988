import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { Paper, Typography, styled } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Notification } from './NotifierContext';
import CopyToClipboardButton from '../copyToClipboardButton/CopyToClipboardButton';
import AlertSuccessIcon from '../assets/alert_success.png';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2)
}));

const StyledTypography = styled(Typography)(() => ({
  overflow: 'auto'
}));

type DismissibleNotificationProps = {
  notification: Notification;
  onDismiss: () => void;
};
const DismissibleNotification: FC<DismissibleNotificationProps> = ({ notification, onDismiss }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (notification.duration) {
      timer = setTimeout(() => {
        onDismiss();
      }, notification.duration);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
    onDismiss();
  };

  const toggleDetails = () => {
    setIsDetailsOpen(!isDetailsOpen);
  };

  return (
    <Collapse in={open}>
      <Alert
        icon={
          notification.type === 'success' ? (
            <img
              src={AlertSuccessIcon}
              alt="Success Icon"
              style={{ width: 35, height: 35, marginTop: 10 }}
            />
          ) : (
            <ErrorOutlineIcon />
          )
        }
        severity={notification.type}
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={handleClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }>
        <Typography variant="body1" gutterBottom>
          <Typography variant="body1b">{t(notification.type)}</Typography>
          <Stack direction="row">
            <Typography>{t(notification.message)}</Typography>
            {notification.solution && (
              <>
                <Typography gutterBottom ml={0.6}>
                  - {t(notification.solution)}
                </Typography>
                {notification.details && (
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={toggleDetails}>
                    {!isDetailsOpen && <KeyboardDoubleArrowDownIcon fontSize="inherit" />}
                    {isDetailsOpen && <KeyboardDoubleArrowUpIcon fontSize="inherit" />}
                  </IconButton>
                )}
              </>
            )}
          </Stack>
        </Typography>
        {notification.details && (
          <Collapse in={isDetailsOpen}>
            <StyledPaper>
              <StyledTypography>{notification.details}</StyledTypography>
              <Stack direction="row" justifyContent="flex-end">
                <CopyToClipboardButton textToCopy={notification.details} />
              </Stack>
            </StyledPaper>
          </Collapse>
        )}
      </Alert>
    </Collapse>
  );
};

export default DismissibleNotification;
