import { MenuItem, Select, SelectChangeEvent, Stack, styled, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flag } from '@mui/icons-material';
import { useNotifierContext } from '@oh-vcp/components-ui';
import { EconsultUtils } from '@oh-vcp/components-common';
import { getCaseActionTitle } from '@oh-vcp/components-common/src/utils/econsultCaseUtils';
import { useQueryClient } from 'react-query';
import { useEconsultCaseDetailsContext } from '../econsultCaseDetailsContext/EconsultCaseDetailsContext';
import { useAddCaseFlag, useEconsultFlagService } from '../services/useEconsultFlagService';

const SelectContainer = styled(Select)({
  width: 130,
  minWidth: 130,
  height: 40,
  borderRadius: 5
});

const EconsultCaseFlagSelector = () => {
  const { t } = useTranslation();
  const { data: flags } = useEconsultFlagService();
  const { addNotification } = useNotifierContext();
  const { caseDetails, setCaseDetails } = useEconsultCaseDetailsContext();
  const queryClient = useQueryClient();
  const useAddCaseFlagMutation = useAddCaseFlag();

  useEffect(() => {
    if (caseDetails?.flagCd === 'NONE') {
      const { flagCd, ...rest } = caseDetails;
      setCaseDetails({
        ...rest
      });
    }
  }, []);

  const handleFlagChanged = async (e: SelectChangeEvent<unknown>) => {
    if (!caseDetails?.caseId) {
      return;
    }
    if (e.target.value === 'NONE') {
      await useAddCaseFlagMutation.mutateAsync({
        caseId: caseDetails?.caseId,
        caseFlagCode: 'NONE'
      });
      setCaseDetails({
        ...caseDetails,
        flagCd: 'NONE'
      });
    } else {
      const updatedFlagCd = e.target.value as string;
      await useAddCaseFlagMutation.mutateAsync({
        caseId: caseDetails?.caseId,
        caseFlagCode: updatedFlagCd
      });
      setCaseDetails({
        ...caseDetails,
        flagCd: updatedFlagCd
      });
    }
    addNotification({
      type: 'success',
      message: t('Case.draft.caseFlagHasBeenSaved')
    });
    queryClient.invalidateQueries(['caseResults']);
  };
  return (
    <SelectContainer
      sx={{
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: EconsultUtils.getFlagColor(caseDetails?.flagCd || '')
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: EconsultUtils.getFlagColor(caseDetails?.flagCd || '')
        },
        '& .MuiSelect-icon': {
          color: EconsultUtils.getFlagColor(caseDetails?.flagCd || '')
        }
      }}
      value={caseDetails?.flagCd || ''}
      renderValue={(selected) => {
        if (!selected) {
          return (
            <Stack direction="row">
              <Flag sx={{ color: 'primary.main', mr: 0.5, fontSize: 20 }} />
              <Typography variant="body2" sx={{ color: 'primary.main' }} component="span">
                {t('Case.draft.setFlag')}
              </Typography>
            </Stack>
          );
        }
        const flag = flags?.find((f) => f.flagCode === selected);
        if (flag) {
          return (
            <Stack direction="row">
              <Flag
                sx={{ color: EconsultUtils.getFlagColor(flag.flagCode), mr: 0.5, fontSize: 20 }}
              />
              <Typography variant="body2" sx={{ color: EconsultUtils.getFlagColor(flag.flagCode) }}>
                {flag.description}
              </Typography>
            </Stack>
          );
        }
        if (selected === 'NONE') {
          return (
            <Stack direction="row">
              <Flag sx={{ color: 'base.grey4', mr: 0.5, fontSize: 20 }} />
              <Typography variant="body2" sx={{ color: 'base.grey4' }}>
                {t('Case.draft.NONE')}
              </Typography>
            </Stack>
          );
        }
        return null;
      }}
      displayEmpty
      onChange={handleFlagChanged}
      title={getCaseActionTitle('flag')}>
      {flags?.map((f) => (
        <MenuItem value={f.flagCode} key={f.flagCode}>
          <Stack direction="row">
            <Flag sx={{ color: EconsultUtils.getFlagColor(f.flagCode), mr: 0.5, fontSize: 20 }} />
            <Typography variant="body2" sx={{ color: EconsultUtils.getFlagColor(f.flagCode) }}>
              {f.description}
            </Typography>
          </Stack>
        </MenuItem>
      ))}
      <MenuItem value="NONE">
        <Stack direction="row">
          <Flag sx={{ color: 'base.grey4', mr: 1 }} />
          <Typography variant="body2" sx={{ color: 'base.grey4' }}>
            {t('Case.draft.NONE')}
          </Typography>
        </Stack>
      </MenuItem>
    </SelectContainer>
  );
};

export default EconsultCaseFlagSelector;
