import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  ConsultTab,
  EconsultActionTab,
  NoteActionTypes,
  NoteStatusCodes
} from '@oh-vcp/components-common';
import { useTranslation } from 'react-i18next';
import { Box, styled } from '@mui/material';
import { getCaseActionTitle } from '@oh-vcp/components-common/src/utils/econsultCaseUtils';
import EconsultCaseProvideConsult from '../econsultCaseProvideConsult/EconsultCaseProvideConsult';
import EconsultCaseReturnConsult from '../econsultCaseReturnConsult/EconsultCaseReturnConsult';
import EconsultCaseComplete from '../econsultCaseComplete/EconsultCaseComplete';
import EconsultCaseRedirect from '../econsultCaseRedirect/EconsultCaseRedirect';
import { useEconsultCaseDetailsContext } from '../econsultCaseDetailsContext/EconsultCaseDetailsContext';
import { useEconsultTabContext } from '../econsultTabContext/EconsultTabContext';

interface EconsultCaseConsultActionDetailsProps {
  options: EconsultActionTab[];
}

const EconsultCaseConsultActionTabPanel = styled(TabPanel)({
  padding: 0,
  paddingTop: 10
});
const EconsultActionTabStyle = styled(Tab)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&.Mui-selected': {
    color: theme.palette.primary.custom3
  }
}));
const EconsultActionTabList = styled(TabList)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.custom3
  }
}));

const EconsultCaseConsultActionDetails: FC<EconsultCaseConsultActionDetailsProps> = ({
  options
}) => {
  const { t } = useTranslation();
  const { caseDetails } = useEconsultCaseDetailsContext();
  const [reasonForReturning, setReasonForReturning] = useState<string>('');
  const [defaulAddNoteContent, setDefaulAddNoteContent] = useState<string | undefined>(undefined);
  const [defaultProvideMoreInfoContent, setDefaultProvideMoreInfoContent] = useState<
    string | undefined
  >(undefined);
  const { selectedTab, setSelectedTab } = useEconsultTabContext();

  useEffect(() => {
    const draftNote = caseDetails?.notes?.find(
      (n) => n.statusCd === NoteStatusCodes.draft && n.actionTypeCd === NoteActionTypes.addNote
    );
    const draftFinishNote = caseDetails?.notes?.find(
      (n) => n.statusCd === NoteStatusCodes.draft && n.actionTypeCd === NoteActionTypes.finish
    );
    const draftCancelNote = caseDetails?.notes?.find(
      (n) => n.statusCd === NoteStatusCodes.draft && n.actionTypeCd === NoteActionTypes.cancel
    );
    const draftReplyNote = caseDetails?.notes?.find(
      (n) => n.statusCd === NoteStatusCodes.draft && n.actionTypeCd === NoteActionTypes.reply
    );
    const addOption = options.find((o) => o.tab === ConsultTab.AddNote);
    const completeOption = options.find((o) => o.tab === ConsultTab.Complete);
    if (draftNote && addOption && !selectedTab) {
      setSelectedTab(ConsultTab.AddNote);
      setDefaulAddNoteContent(draftNote.noteContent);
    } else if (draftFinishNote && completeOption && !selectedTab) {
      setSelectedTab(ConsultTab.Complete);
    } else if (draftCancelNote && !selectedTab) {
      setSelectedTab(ConsultTab.Cancel);
    } else if (draftReplyNote && !selectedTab) {
      setSelectedTab(ConsultTab.ProvideMoreInfo);
      setDefaultProvideMoreInfoContent(draftReplyNote?.noteContent);
    } else {
      setDefaulAddNoteContent(undefined);
      setDefaultProvideMoreInfoContent(undefined);
    }
  }, [caseDetails?.notes, options]);
  const handleTabSelected = (event: SyntheticEvent<Element, Event>, tab: ConsultTab) => {
    setSelectedTab(tab);
  };
  const handleOnReasonForReturningChanged = (reason: string) => {
    setReasonForReturning(reason);
  };
  const getTitle = (option: string) => {
    console.log(`getTitle - ${option}`);
    let tab = option;
    if (option && option === 'Add Note') {
      tab = 'AddNoteRef';
      if (options.length === 1) {
        if (options[0].role && options[0].role === 'consultant') {
          tab = 'AddNoteSpec';
        }
      } else {
        for (let i = 0; i < options.length; i++) {
          if (options[i].label.indexOf('Consult') !== -1) {
            tab = 'AddNoteSpec';
            break;
          }
        }
      }
    }
    return getCaseActionTitle(tab);
  };
  return (
    <Box>
      <TabContext value={selectedTab || ''}>
        <EconsultActionTabList onChange={handleTabSelected}>
          {options.map((o) => (
            <EconsultActionTabStyle
              label={o.label}
              value={o.tab}
              icon={<img src={o.imageSrc} alt={o.imageAltText} />}
              iconPosition={o.iconPosition}
              title={getTitle(o.label)}
            />
          ))}
        </EconsultActionTabList>
        <EconsultCaseConsultActionTabPanel value={ConsultTab.Provided}>
          <EconsultCaseProvideConsult
            actionType={NoteActionTypes.writeConsult}
            showRecommendation
          />
        </EconsultCaseConsultActionTabPanel>
        <EconsultCaseConsultActionTabPanel value={ConsultTab.MoreInfo}>
          <EconsultCaseProvideConsult actionType={NoteActionTypes.askForMoreInfo} />
        </EconsultCaseConsultActionTabPanel>
        <EconsultCaseConsultActionTabPanel value={ConsultTab.Returned}>
          <EconsultCaseReturnConsult
            reasonForReturning={reasonForReturning}
            onReasonChanged={handleOnReasonForReturningChanged}
          />
        </EconsultCaseConsultActionTabPanel>
        <EconsultCaseConsultActionTabPanel value={ConsultTab.AddNote}>
          <EconsultCaseProvideConsult
            actionType={NoteActionTypes.addNote}
            defaultContent={defaulAddNoteContent}
          />
        </EconsultCaseConsultActionTabPanel>
        <EconsultCaseConsultActionTabPanel value={ConsultTab.Complete}>
          <EconsultCaseComplete actionType="complete" />
        </EconsultCaseConsultActionTabPanel>
        <EconsultCaseConsultActionTabPanel value={ConsultTab.RequestClarification}>
          <EconsultCaseProvideConsult
            actionType={NoteActionTypes.reopen}
            noteFieldLabel={t('Case.consult.enterNoteForFurtherClarification') as string}
          />
        </EconsultCaseConsultActionTabPanel>
        <EconsultCaseConsultActionTabPanel value={ConsultTab.Cancel}>
          <EconsultCaseComplete actionType="cancel" />
        </EconsultCaseConsultActionTabPanel>
        <EconsultCaseConsultActionTabPanel value={ConsultTab.Redirect}>
          <EconsultCaseRedirect />
        </EconsultCaseConsultActionTabPanel>
        <EconsultCaseConsultActionTabPanel value={ConsultTab.ProvideMoreInfo}>
          <EconsultCaseProvideConsult
            actionType={NoteActionTypes.reply}
            defaultContent={defaultProvideMoreInfoContent}
          />
        </EconsultCaseConsultActionTabPanel>
      </TabContext>
    </Box>
  );
};

export default EconsultCaseConsultActionDetails;
