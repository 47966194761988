import { Box, Button, Card, CardActionArea, Stack, Typography, styled } from '@mui/material';
import {
  CaseDetails,
  CaseNote,
  CaseResultDetails,
  EconsultSpecialist,
  EconsultStatusTypes,
  EconsultUnavailabilityDate,
  EconsultUserRoles,
  EconsultUtils,
  NameUtils,
  NoteActionTypes,
  NoteStatusCodes,
  NoteTypes
} from '@oh-vcp/components-common';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useNotifierContext } from '@oh-vcp/components-ui';
import { Flag } from '@mui/icons-material';
import { useQueryClient } from 'react-query';
import { useEconsultTabContext } from '../econsultTabContext/EconsultTabContext';
import EconsultProgramSpecialistSelector from '../econsultProgramSpecialistSelector/EconsultProgramSpecialistSelector';
import CaseService from '../services/caseService';
import { useAuth } from '../auth/AuthContext';
import EconsultOutOfOfficeDialog from '../econsultOutOfOfficeDialog/EconsultOutOfOfficeDialog';
import { useEconsultCaseDetailsContext } from '../econsultCaseDetailsContext/EconsultCaseDetailsContext';
import EconsultUserService from '../services/econsultUserService';
import EconsultCompleteCaseDialog from '../econsultCompleteCaseDialog/EconsultCompleteCaseDialog';

interface EconsultCaseInboxItemProps {
  caseDetails: CaseResultDetails;
}

const EconsultCaseInboxItemContainer = styled(Card)({
  marginTop: 10,
  marginRight: 5,
  marginLeft: 5,
  minWidth: 400
});

const EconsultCaseInboxItemActionArea = styled(Box)({
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 20,
  paddingRight: 20,
  cursor: 'pointer'
});

const TrimmedTypography = styled(Typography)({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: 180
});

const EconsultCaseInboxItem: FC<EconsultCaseInboxItemProps> = ({ caseDetails }) => {
  const {
    referrerFirstName,
    referrerLastName,
    referrerSalutation,
    chiefComplaint,
    caseId,
    lastAction,
    sentOn,
    consultantFirstName,
    consultantLastName,
    consultantSalutation,
    toBeViewed,
    programId,
    userRole,
    isConsultActioned,
    nextActionBy,
    lastActionBy,
    programName,
    flagCode
  } = caseDetails;
  const { t } = useTranslation();
  const { user } = useAuth();
  const { caseDetails: selectedCaseDetails, setCaseDetails } = useEconsultCaseDetailsContext();
  const { addNotification } = useNotifierContext();
  const [isCaseCompleteDialogOpen, setIsCaseCompleteDialogOpen] = useState<boolean>(false);
  const [isToBeViewed, setIsToBeViewed] = useState<boolean>(Boolean(toBeViewed));
  const [selectedSpecialist, setSelectedSpecialist] = useState<EconsultSpecialist | undefined>(
    undefined
  );
  const [selectedOutOfOfficeSpecialist, setSelectedOutOfOfficeSpecialist] = useState<
    EconsultSpecialist | undefined
  >(undefined);
  const [disableAssignClick, setDisableAssignClick] = useState<boolean>(false);
  const [readyToBeAssigned, setReadyToBeAssigned] = useState<boolean>(false);
  const [isOutOfOfficeDialogShown, setIsOutOfOfficeDialogShown] = useState<boolean>(false);
  const [outOfOfficeStartDate, setOutOfOfficeStartDate] = useState<string | null>(null);
  const [outOfOfficeEndDate, setOutOfOfficeEndDate] = useState<string | null>(null);
  const [isFlagShown, setIsFlagShown] = useState<boolean>(false);
  const navigate = useNavigate();
  const { providerType, statusType } = useParams();
  const { setSelectedTab } = useEconsultTabContext();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (
      programId &&
      userRole === NoteTypes.triageAdmin &&
      !isConsultActioned &&
      (lastAction === NoteActionTypes.send ||
        lastAction === NoteActionTypes.assign ||
        lastAction === NoteActionTypes.unassign ||
        (lastAction === NoteActionTypes.addNote && nextActionBy === NoteTypes.triageAdmin) ||
        (lastAction === NoteActionTypes.addNote &&
          lastActionBy === NoteTypes.referrer &&
          nextActionBy === NoteTypes.consultant))
    ) {
      setReadyToBeAssigned(true);
    } else {
      setReadyToBeAssigned(false);
    }
  }, [programId, userRole, isConsultActioned, lastAction, lastActionBy, nextActionBy]);

  useEffect(() => {
    if (
      userRole === EconsultUserRoles.consultant ||
      userRole === EconsultUserRoles.consultantDelegate
    ) {
      setIsFlagShown(true);
    } else {
      setIsFlagShown(false);
    }
  }, [userRole]);

  const getStatus = () => {
    switch (lastAction) {
      case NoteActionTypes.sendDraft:
        return t('Case.status.sendDraft');
      case NoteActionTypes.cancel:
        return t('Case.status.cancel');
      case NoteActionTypes.send:
        return t('Case.status.send');
      case NoteActionTypes.writeConsult:
        return t('Case.status.writeConsult');
      case NoteActionTypes.writeConsultDraft:
        return t('Case.status.writeConsultDraft');
      case NoteActionTypes.askForMoreInfo:
        return t('Case.status.askForMoreInfo');
      case NoteActionTypes.askForMoreInfoDraft:
        return t('Case.status.askForMoreInfoDraft');
      case NoteActionTypes.decline:
        return t('Case.status.decline');
      case NoteActionTypes.declineDraft:
        return t('Case.status.declineDraft');
      case NoteActionTypes.reply:
        return t('Case.status.reply');
      case NoteActionTypes.replyDraft:
        return t('Case.status.replyDraft');
      case NoteActionTypes.finish:
        return t('Case.status.finish');
      case NoteActionTypes.reopen:
        return t('Case.status.reopen');
      case NoteActionTypes.reopenDraft:
        return t('Case.status.reopenDraft');
      case NoteActionTypes.recomplete:
        return t('Case.status.recomplete');
      case NoteActionTypes.forward:
        return t('Case.status.forward');
      case NoteActionTypes.forwardDraft:
        return t('Case.status.forwardDraft');
      case NoteActionTypes.addNote:
        return t('Case.status.addNote');
      case NoteActionTypes.assign:
        return t('Case.status.assign');
      case NoteActionTypes.unassign:
        return t('Case.status.unassign');
      case NoteActionTypes.draft:
        return t('Case.status.draft');
      case NoteActionTypes.addNoteDraft:
        return t('Case.status.addNoteDraft');
      case NoteActionTypes.assignDraft:
        return t('Case.status.assignDraft');
      default:
        return t('Case.status.unknown');
    }
  };

  const daysAgo = (): string => {
    if (!sentOn) {
      return '';
    }

    const now = new Date().getTime();
    const daysDifference = Math.floor((now - sentOn) / (24 * 60 * 60 * 1000));

    if (daysDifference === 0) {
      return t('Case.inbox.today');
    }
    if (daysDifference === 1) {
      return t('Case.inbox.yesterday');
    }
    return `${daysDifference} ${t('Case.inbox.daysAgo')}`;
  };

  const navigateToCase = () => {
    setSelectedTab(undefined);
    setIsToBeViewed(false);
    setTimeout(() => {
      queryClient.invalidateQueries(['caseResults']);
    }, 1000);
    navigate(`/case/${providerType || 'consultant'}/${statusType || 'all'}/${caseId}`);
  };

  const handleCaseClicked = () => {
    if (
      selectedCaseDetails &&
      EconsultUserService.isReferrerResolveCompletedConsultAction(selectedCaseDetails, userRole)
    ) {
      setIsCaseCompleteDialogOpen(true);
    } else {
      navigateToCase();
    }
  };

  const onCompleteDialogCancelled = () => {
    setIsCaseCompleteDialogOpen(false);
    navigateToCase();
  };

  const onCompleteDialogCompleted = async () => {
    setIsCaseCompleteDialogOpen(false);
    if (!selectedCaseDetails) return;

    const draftFinishNote = selectedCaseDetails?.notes?.find(
      (n) => n.statusCd === NoteStatusCodes.draft && n.actionTypeCd === NoteActionTypes.finish
    );
    if (!draftFinishNote) {
      const newNote: CaseNote = {
        actionTypeCd: NoteActionTypes.finish,
        caseId: selectedCaseDetails?.caseId,
        noteTypeCd: NoteTypes.referrer,
        ownerId: user?.userid,
        statusCd: NoteStatusCodes.draft
      };
      await CaseService.upsertCaseNote(newNote);
      setCaseDetails({
        ...selectedCaseDetails,
        notes: [...(selectedCaseDetails.notes ? selectedCaseDetails.notes : []), newNote]
      });
    }
  };

  const consultantString = () => {
    if (programName) {
      return ` | ${programName}`;
    }
    if (consultantFirstName && consultantLastName) {
      return ` | ${NameUtils.makeName(
        consultantLastName,
        consultantFirstName,
        consultantSalutation,
        null,
        null,
        null
      )}`;
    }
    return ``;
  };

  const handleOutOfOfficeDialogClose = () => {
    setOutOfOfficeStartDate(null);
    setOutOfOfficeEndDate(null);
    setIsOutOfOfficeDialogShown(false);
    setSelectedOutOfOfficeSpecialist(undefined);
  };

  const handleOutOfOfficeDialogCancel = () => {
    setSelectedSpecialist(undefined);
    handleOutOfOfficeDialogClose();
  };

  const handleOutOfficeDialogConfirm = () => {
    setSelectedSpecialist(selectedOutOfOfficeSpecialist);
    handleOutOfOfficeDialogClose();
  };

  const handleSpecialistSelected = (
    specialist: EconsultSpecialist | undefined,
    outOfOfficeInAWeek?: EconsultUnavailabilityDate | null
  ) => {
    if (outOfOfficeInAWeek) {
      setOutOfOfficeStartDate(outOfOfficeInAWeek.startDate);
      setOutOfOfficeEndDate(outOfOfficeInAWeek.endDate);
      setIsOutOfOfficeDialogShown(true);
      setSelectedOutOfOfficeSpecialist(specialist);
    } else {
      setSelectedSpecialist(specialist);
    }
  };

  const getFirstNoteFunction = (caseObject: CaseDetails) => {
    if (caseObject.notes && caseObject.notes.length === 0) {
      return null;
    }

    const sortedNotes = caseObject.notes?.sort((a, b) => {
      if (a.lastUpdated === null && b.lastUpdated === null) {
        return a.noteId! - b.noteId!;
      }
      if (a.lastUpdated === null) return 1;
      if (b.lastUpdated === null) return -1;

      if (a.lastUpdated! < b.lastUpdated!) return -1;
      if (a.lastUpdated! > b.lastUpdated!) return 1;

      return a.noteId! - b.noteId!;
    });

    if (sortedNotes && sortedNotes.length > 1) {
      return sortedNotes[0];
    }
    return null;
  };

  const createForwardedCase = (originalCase: CaseDetails) => {
    const forwardCase: CaseDetails = {
      originalCaseId: originalCase.caseId,
      priorityCd: originalCase.priorityCd,
      referrer: {
        userId: originalCase.referrer?.userId
      },
      patient: {
        firstName: originalCase.patient?.firstName,
        middleName: originalCase.patient?.middleName,
        lastName: originalCase.patient?.lastName,
        dateOfBirth: originalCase.patient?.dateOfBirth,
        gender: originalCase.patient?.gender === '***' ? null : originalCase.patient?.gender,
        ohipNumber: originalCase.patient?.ohipNumber,
        ohipVersion: originalCase.patient?.ohipVersion,
        noOhip: originalCase.patient?.noOhip
      },
      chiefComplaint: originalCase.chiefComplaint,
      patientConsent: originalCase.patientConsent
    };

    return forwardCase;
  };

  const assignAndUpdateCase = async (
    caseObject: CaseDetails,
    consultantUserId: string,
    consultantName: string
  ) => {
    // Make a deep copy
    const caseToSave = JSON.parse(JSON.stringify(caseObject));

    // Detect reassign, and create a new participant object in that case
    if (caseObject.participant?.triageAssignedOn && caseObject.participant?.consultant) {
      const oldParticipant = caseObject.participant;
      caseToSave.participant = {
        statusCd: NoteStatusCodes.active,
        triageStatusCd: NoteStatusCodes.active,
        program: oldParticipant.program,
        consultant: {
          userId: consultantUserId
        }
      };
    } else {
      caseToSave.participant.consultant = {
        userId: consultantUserId
      };
    }

    caseToSave.participant.statusReason = '';
    caseToSave.participant.triageStatusReasonCd = null;

    const newNote = {
      ownerId: user?.userid,
      statusCd: NoteStatusCodes.active,
      noteTypeCd: NoteTypes.triageAdmin,
      actionTypeCd: NoteActionTypes.assign,
      parameter1: consultantName
    };
    caseToSave.notes = [newNote];

    const response = await CaseService.upsertCaseDetails(caseToSave);
    if (response?.data?.caseId) {
      addNotification({
        type: 'success',
        message: t('Case.inbox.caseAssigned')
      });
      navigate(`/case/${providerType}/needsaction`);
    }
  };

  const reassignAndUpdateCase = async (
    originalCase: CaseDetails,
    consultantUserId: string,
    consultantName: string
  ) => {
    if (
      consultantUserId &&
      originalCase.participant?.consultant &&
      originalCase.participant?.consultant?.userId === parseInt(consultantUserId, 10)
    ) {
      addNotification({
        type: 'error',
        message: t('Case.inbox.caseAssignFailed')
      });
      return;
    }

    const forwardedCase = createForwardedCase(originalCase);

    forwardedCase.statusCd = NoteStatusCodes.active;
    forwardedCase.programType = originalCase.programType;
    forwardedCase.specialty = originalCase.specialty;
    forwardedCase.subSpecialty = originalCase.subSpecialty;
    forwardedCase.specialtyOption = originalCase.specialtyOption;

    forwardedCase.participant = {
      statusCd: NoteStatusCodes.active,
      triageStatusCd: NoteStatusCodes.active,
      program: {
        programId: originalCase.participant?.program.programId,
        programName: originalCase.participant?.program.programName,
        programDescription: originalCase.participant?.program.programDescription
      },
      consultant: {
        userId: parseInt(consultantUserId, 10)
      }
    };

    const originalCaseFirstNote = getFirstNoteFunction(originalCase);
    const sendNote = {
      ownerId: user?.userid,
      noteContent: originalCaseFirstNote?.noteContent,
      noteTypeCd: originalCaseFirstNote?.noteTypeCd,
      statusCd: originalCaseFirstNote?.statusCd,
      actionTypeCd: originalCaseFirstNote?.actionTypeCd,
      patientToBeSeen: originalCaseFirstNote?.patientToBeSeen,
      attachments: originalCaseFirstNote?.attachments
    };

    const assignNote = {
      ownerId: user?.userid,
      statusCd: NoteStatusCodes.active,
      noteTypeCd: NoteTypes.triageAdmin,
      actionTypeCd: NoteActionTypes.assign,
      parameter1: consultantName
    };

    forwardedCase.notes = [sendNote, assignNote];

    originalCase.statusCd = NoteStatusCodes.closed;
    originalCase.statusReason = EconsultStatusTypes.cancelled;
    if (originalCase.participant) {
      originalCase.participant.statusCd = NoteStatusCodes.closed;
      originalCase.participant.triageStatusCd = NoteStatusCodes.closed;
      delete originalCase.participant.triageAssignedOn;
    }

    originalCase.notes = [
      {
        ownerId: user?.userid,
        statusCd: NoteStatusCodes.active,
        noteTypeCd: NoteTypes.triageAdmin,
        actionTypeCd: NoteActionTypes.cancel
      }
    ];

    const response = await CaseService.upsertCaseDetails(originalCase);
    if (response?.data?.caseId) {
      addNotification({
        type: 'success',
        message: t('Case.inbox.caseAssigned')
      });
      navigate(`/case/${providerType}/needsaction`);
    }
  };

  const unassignAndUpdateCase = async (caseObject: CaseDetails) => {
    // Make a deep copy
    const caseToSave = JSON.parse(JSON.stringify(caseObject));

    delete caseToSave.participant.consultant;
    delete caseToSave.participant.triageStatusReasonCd;
    delete caseToSave.participant.triageAssignedOn;

    const newNote = {
      statusCd: NoteStatusCodes.active,
      caseId: caseObject.caseId,
      ownerId: user?.userid,
      noteTypeCd: NoteTypes.triageAdmin,
      actionTypeCd: NoteActionTypes.unassign
    };
    caseToSave.notes = [newNote];

    const response = await CaseService.upsertCaseDetails(caseToSave);
    if (response?.data?.caseId) {
      addNotification({
        type: 'success',
        message: t('Case.inbox.caseUnssinged')
      });
      navigate(`/case/${providerType}/needsaction`);
    }
  };

  const assignCase = async (specialist: EconsultSpecialist | undefined) => {
    if (!specialist) return;
    setDisableAssignClick(true);
    const assignedCaseDetailResponse = await CaseService.getCaseDetails(caseId);
    if (assignedCaseDetailResponse && assignedCaseDetailResponse.data) {
      const { data: assignedCaseDetails } = assignedCaseDetailResponse;
      const { notes } = assignedCaseDetails;
      const notesWithAssignFunction = notes?.filter(
        (n) => n.statusCd === NoteStatusCodes.active && n.actionTypeCd === NoteActionTypes.assign
      );
      const specialistName = NameUtils.makeName(
        specialist.lastName,
        specialist.firstName,
        specialist.salutation,
        specialist.specialty,
        specialist.middleName,
        null
      );
      if (notesWithAssignFunction && notesWithAssignFunction.length > 0) {
        await reassignAndUpdateCase(
          assignedCaseDetails,
          specialist.userId?.toString(),
          specialistName
        );
      } else {
        await assignAndUpdateCase(
          assignedCaseDetails,
          specialist.userId?.toString(),
          specialistName
        );
      }
    }
  };

  const unassignCase = async () => {
    if (
      caseDetails?.lastAction === NoteActionTypes.assign ||
      (caseDetails?.lastAction === NoteActionTypes.addNote &&
        caseDetails?.lastActionBy === EconsultUserRoles.referrer &&
        caseDetails?.nextActionBy === EconsultUserRoles.consultant)
    ) {
      const assignedCaseDetailResponse = await CaseService.getCaseDetails(caseId);
      if (assignedCaseDetailResponse && assignedCaseDetailResponse.data) {
        await unassignAndUpdateCase(assignedCaseDetailResponse.data);
      }
    } else {
      setSelectedSpecialist(undefined);
    }
  };

  const isAssignCaseDisabled = () => {
    const originalConsultantId = caseDetails.consultantId;
    if (
      disableAssignClick ||
      (!selectedSpecialist &&
        originalConsultantId &&
        caseDetails.lastAction !== NoteActionTypes.unassign)
    ) {
      return true;
    }
    return (
      selectedSpecialist?.userId === originalConsultantId &&
      caseDetails.lastAction !== NoteActionTypes.unassign
    );
  };

  const unpropagateClickEvent = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <>
      <EconsultCaseInboxItemContainer
        variant="outlined"
        sx={{ backgroundColor: caseId === selectedCaseDetails?.caseId ? 'primary.light' : '' }}>
        <EconsultCaseInboxItemActionArea onClick={handleCaseClicked}>
          <Stack direction="column">
            <Stack direction="row">
              <TrimmedTypography
                color="base.grey4"
                variant="body2"
                flexGrow={1}
                sx={{ fontWeight: isToBeViewed ? 'bold' : 400 }}>
                {NameUtils.makeName(
                  referrerLastName,
                  referrerFirstName,
                  referrerSalutation,
                  null,
                  null,
                  null
                )}
                {consultantString()}
              </TrimmedTypography>
              {lastAction !== NoteActionTypes.draft && (
                <Typography
                  color="base.grey4"
                  variant="body5"
                  title={`${t('Case.inbox.submitted')} ${daysAgo()}`}>
                  {t('Case.inbox.submitted')} {daysAgo()}
                </Typography>
              )}
            </Stack>
            <TrimmedTypography
              color="primary.main"
              sx={{ fontWeight: isToBeViewed ? 600 : 400, width: '95%' }}>
              {caseDetails.userRole !== NoteTypes.triageAdmin && chiefComplaint}
            </TrimmedTypography>
            <Stack direction="row" alignItems="baseline">
              <Typography
                variant="body1b"
                sx={{ fontWeight: isToBeViewed ? 600 : 400 }}
                color="base.grey3"
                flexGrow={1}>
                {getStatus()}
              </Typography>
              <Typography variant="body5" color="base.grey4">
                {t('Case.detials.caseId')}: {caseId}
              </Typography>
            </Stack>
            {flagCode && flagCode !== 'NONE' && isFlagShown && (
              <Stack direction="row">
                <Flag
                  sx={{
                    color: EconsultUtils.getFlagColor(flagCode),
                    mr: 0.5,
                    fontSize: 24
                  }}
                />
                <Typography
                  pt={0.5}
                  variant="body2"
                  sx={{
                    color: EconsultUtils.getFlagColor(flagCode),
                    fontWeight: isToBeViewed ? 800 : 400
                  }}
                  component="span">
                  {t(`Case.draft.${flagCode}`)}
                </Typography>
              </Stack>
            )}
            {readyToBeAssigned && (
              <>
                <EconsultProgramSpecialistSelector
                  caseResult={caseDetails}
                  selectedSpecialist={
                    selectedSpecialist?.userId?.toString() ||
                    (caseDetails?.consultantId?.toString() &&
                    caseDetails?.lastAction !== NoteActionTypes.unassign
                      ? caseDetails?.consultantId?.toString()
                      : '0')
                  }
                  onSpecialistSelected={handleSpecialistSelected}
                />
                {Boolean(
                  (selectedSpecialist &&
                    selectedSpecialist.userId &&
                    selectedSpecialist.userId.toString() !== '0') ||
                    (caseDetails.consultantId &&
                      caseDetails?.lastAction !== NoteActionTypes.unassign)
                ) && (
                  <Stack direction="row" mt={1} columnGap={1} onClick={unpropagateClickEvent}>
                    <Button
                      variant="contained"
                      onClick={() => assignCase(selectedSpecialist)}
                      disabled={isAssignCaseDisabled()}>
                      {t('Case.inbox.assign')}
                    </Button>
                    <Button variant="contained" onClick={unassignCase}>
                      {t('Case.inbox.unassign')}
                    </Button>
                  </Stack>
                )}
              </>
            )}
          </Stack>
        </EconsultCaseInboxItemActionArea>
      </EconsultCaseInboxItemContainer>
      <EconsultOutOfOfficeDialog
        userName={NameUtils.makeName(
          selectedOutOfOfficeSpecialist?.lastName || '',
          selectedOutOfOfficeSpecialist?.firstName,
          selectedOutOfOfficeSpecialist?.salutation,
          null,
          null,
          null
        )}
        startDate={outOfOfficeStartDate}
        endDate={outOfOfficeEndDate}
        isDialogOpen={isOutOfOfficeDialogShown}
        onCancelClicked={handleOutOfOfficeDialogCancel}
        onContinueClicked={handleOutOfficeDialogConfirm}
      />
      <EconsultCompleteCaseDialog
        isOpen={isCaseCompleteDialogOpen}
        onClose={onCompleteDialogCancelled}
        onComplete={onCompleteDialogCompleted}
      />
    </>
  );
};

export default EconsultCaseInboxItem;
