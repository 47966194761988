import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { CaseDetailsResponsePayloadDTO } from '@oh-vcp/components-common';
import CaseService from '@oh-vcp/components-web/src/services/caseService';

const useCaseDetails = (caseId: number | undefined | null, onError?: (error: any) => void) => {
  return useQuery<CaseDetailsResponsePayloadDTO, AxiosError>(
    ['caseDetails', caseId],
    () => CaseService.getCaseDetails(caseId!),
    {
      enabled: !!caseId,
      refetchOnWindowFocus: false,
      retry: 0,
      onError: onError
    }
  );
};

export default useCaseDetails;
