import { Box, Stack, Typography, styled } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import useCaseResults from '../../api/useCaseResults';
import {
  CaseResponsePayloadDTO,
  CaseResultDetails,
  EconsultProviderTypes,
  EconsultStatusTypes,
  EconsultUser,
  EconsultUserRoles,
  NoteActionTypes,
  NoteTypes
} from '@oh-vcp/components-common';
import {
  EconsultCaseInboxFilter,
  EconsultCaseInboxItem,
  EconsultCaseInboxPagination,
  EconsultCaseSearchBox,
  EconsultDelegatorSelector,
  EconsultProgramSelector,
  useConsultantService
} from '@oh-vcp/components-web';
import { useNavigate, useParams } from 'react-router-dom';
import { useEconsultCaseDetailsContext } from '@oh-vcp/components-web/src/econsultCaseDetailsContext/EconsultCaseDetailsContext';
import { useEconsultCaseResultContext } from '@oh-vcp/components-web/src/econsultCaseResultContext/EconsultCaseResultContext';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DisplayMobile } from '@oh-vcp/components-ui';
import useCaseSearch from '../../api/useCaseSearch';

const EconsultCaseInboxStack = styled(Box)({
  overflowY: 'auto',
  height: 'calc(100vh - 18rem)'
});

const EconsultCaseInboxContainerStack = styled(Stack)(({ theme }) => ({
  borderRightWidth: 1,
  borderRightColor: theme.palette.base.grey6,
  borderRightStyle: 'solid',
  paddingRight: 5,
  [theme.breakpoints.up('md')]: {
    width: 450
  }
}));

const EconsultCaseInbox = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dashboardUserRole } = useEconsultCaseResultContext();
  const { setCaseDetails } = useEconsultCaseDetailsContext();
  const { data: consultantsData } = useConsultantService();
  const { providerType, statusType } = useParams();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [subFolder, setSubFolder] = useState<string | undefined>(undefined);
  const [resultsToDisplay, setResultsToDisplay] = useState<CaseResponsePayloadDTO | undefined>();
  const [delegatorId, setDelegatorId] = useState<string | undefined>(undefined);
  const [myConsultants, setMyConsultants] = useState<EconsultUser[]>([]);
  const [selectedProgramName, setSelectedProgramName] = useState<string | undefined>(undefined);
  const [hasCasesReadyToBeAssigned, setHasCasesReadyToBeAssigned] = useState<boolean>(false);
  const inboxStackRef = useRef<HTMLDivElement>(null);
  const numToDisplay = 25;
  const { data: caseResults } = useCaseResults(
    currentPage,
    numToDisplay,
    providerType?.toUpperCase() ||
      dashboardUserRole ||
      EconsultProviderTypes.consultant.toUpperCase(),
    statusType !== EconsultStatusTypes.all ? statusType?.toUpperCase() : undefined,
    subFolder,
    delegatorId,
    selectedProgramName !== '0' ? selectedProgramName : undefined
  );
  const { data: searchResults } = useCaseSearch(
    providerType?.toLowerCase() === 'search',
    statusType,
    currentPage,
    numToDisplay
  );
  const scrollToTop = () => {
    if (inboxStackRef.current) {
      inboxStackRef.current.scrollTo({ top: 0 });
    }
  };
  useEffect(() => {
    const cases = resultsToDisplay?.data?.find((r) => {
      if (
        r.programId &&
        r.userRole === NoteTypes.triageAdmin &&
        !r.isConsultActioned &&
        (r.lastAction === NoteActionTypes.send ||
          r.lastAction === NoteActionTypes.assign ||
          r.lastAction === NoteActionTypes.unassign ||
          (r.lastAction === NoteActionTypes.addNote && r.nextActionBy === NoteTypes.triageAdmin) ||
          (r.lastAction === NoteActionTypes.addNote &&
            r.lastActionBy === NoteTypes.referrer &&
            r.nextActionBy === NoteTypes.consultant))
      ) {
        return r;
      }
      return undefined;
    });
    if (cases) {
      setHasCasesReadyToBeAssigned(true);
    } else {
      setHasCasesReadyToBeAssigned(false);
    }
  }, [resultsToDisplay]);
  useEffect(() => {
    const updateDelegators = async () => {
      if (dashboardUserRole === EconsultUserRoles.consultant) {
        let consultants = consultantsData || [];
        consultants = consultants
          .filter((c) => c.roles?.econCaseConsultOn)
          .sort((a, b) => a.firstName.localeCompare(b.firstName));
        setMyConsultants([
          ...consultants,
          {
            firstName: 'all specialists I can act on behalf of',
            userId: 0
          }
        ]);
      } else if (dashboardUserRole === EconsultUserRoles.referrer) {
        setMyConsultants([
          {
            firstName: 'all requesters I can act on behalf of',
            userId: 0
          }
        ]);
      }
    };

    updateDelegators();
  }, [dashboardUserRole, consultantsData]);
  useEffect(() => {
    if (providerType?.toLowerCase() === 'search') {
      setResultsToDisplay(searchResults);
    } else {
      setResultsToDisplay(caseResults);
    }
  }, [caseResults, searchResults, setCaseDetails, providerType]);
  useEffect(() => {
    setSubFolder('');
  }, [statusType]);
  useEffect(() => {
    setCurrentPage(1);
    scrollToTop();
  }, [providerType, statusType]);

  const onNextClicked = () => {
    if (resultsToDisplay && currentPage * numToDisplay < resultsToDisplay?.totalCount) {
      setCurrentPage(currentPage + 1);
      scrollToTop();
    }
  };
  const onPrevClicked = () => {
    if (resultsToDisplay && currentPage > 1) {
      setCurrentPage(currentPage - 1);
      scrollToTop();
    }
  };
  const getInboxTitle = () => {
    if (providerType === EconsultProviderTypes.consultant) {
      switch (statusType) {
        case EconsultStatusTypes.all:
          return t('Case.inbox.allCasesSubmittedToMe');
        case EconsultStatusTypes.needsAction:
          if (myConsultants.length > 1 || hasCasesReadyToBeAssigned) {
            return t('Case.inbox.casesThatNeedMyAttentionFor');
          }
          return t('Case.inbox.casesThatNeedMyAttention');
        case EconsultStatusTypes.waiting:
          return t('Case.inbox.casesWaitingForMoreInfo');
        case EconsultStatusTypes.provided:
          return t('Case.inbox.casesWithProvidedConsult');
        case EconsultStatusTypes.declined:
          return t('Case.inbox.casesWithReturnedConsult');
      }
    } else if (providerType === EconsultProviderTypes.referrer) {
      switch (statusType) {
        case EconsultStatusTypes.all:
          return t('Case.inbox.allCasesSubmittedByMe');
        case EconsultStatusTypes.needsAction:
          if (myConsultants.length > 1 || hasCasesReadyToBeAssigned) {
            return t('Case.inbox.casesThatNeedMyAttentionFor');
          }
          return t('Case.inbox.casesThatNeedMyAttention');
        case EconsultStatusTypes.waiting:
          return t('Case.inbox.casesWaitingForResponse');
        case EconsultStatusTypes.completed:
          return t('Case.inbox.completedCases');
        case EconsultStatusTypes.cancelled:
          return t('Case.inbox.cancelledCases');
        case EconsultStatusTypes.draft:
          return t('Case.inbox.draftCases');
      }
    }

    return '';
  };
  const handleOnFilterSelected = (value: string) => {
    navigate(`/case/${providerType}/${statusType}`);
    if (value === '') {
      setSubFolder(undefined);
    } else {
      setSubFolder(value);
    }
  };
  const handleGoBack = () => {
    navigate('/');
  };
  const handleDelegatorUserSelected = (userId: string) => {
    setDelegatorId(userId === '0' ? undefined : userId);
  };
  return (
    <EconsultCaseInboxContainerStack direction="column">
      <DisplayMobile>
        <Box mt={2}>
          <ArrowBackIcon onClick={handleGoBack} />
        </Box>
      </DisplayMobile>
      <Typography variant="body1" color="base.grey4" ml={2} mt={2}>
        {getInboxTitle()}
      </Typography>
      {providerType?.toLowerCase() === 'search' ? (
        <Box ml={1}>
          <EconsultCaseSearchBox />
        </Box>
      ) : (
        <>
          {hasCasesReadyToBeAssigned ? (
            <Box mt={1} px={1}>
              <EconsultProgramSelector
                options={resultsToDisplay?.programs || []}
                onSelected={(name) => setSelectedProgramName(name)}
                selectedProgramName={selectedProgramName || '0'}
              />
            </Box>
          ) : (
            <Box mt={1} px={1}>
              <EconsultDelegatorSelector
                userId={delegatorId || '0'}
                onUserSelected={handleDelegatorUserSelected}
                options={myConsultants}
              />
            </Box>
          )}
        </>
      )}
      {!hasCasesReadyToBeAssigned && (
        <EconsultCaseInboxFilter onSelected={handleOnFilterSelected} />
      )}
      {resultsToDisplay && resultsToDisplay.totalCount > 0 ? (
        <>
          <EconsultCaseInboxStack ref={inboxStackRef}>
            {resultsToDisplay?.data?.map((details: CaseResultDetails) => (
              <EconsultCaseInboxItem caseDetails={details} key={details.caseId} />
            ))}
          </EconsultCaseInboxStack>
          <Stack direction="row" justifyContent="right" pt={4}>
            <EconsultCaseInboxPagination
              currentPage={currentPage}
              total={resultsToDisplay?.totalCount}
              numToDisplay={25}
              onNext={onNextClicked}
              onPrev={onPrevClicked}
            />
          </Stack>
        </>
      ) : (
        <Typography variant="body2" color="base.grey4" ml={2} mt={2}>
          {t('Case.inbox.noCases')}
        </Typography>
      )}
    </EconsultCaseInboxContainerStack>
  );
};

export default EconsultCaseInbox;
