import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  styled
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OhipValidationService, RequiredFieldTypography } from '@oh-vcp/components-ui';

import { useEconsultCaseDetailsContext } from '../econsultCaseDetailsContext/EconsultCaseDetailsContext';
import ValidatorUtils from '../econsultBaseValidator/EconsultBaseValidator';
import { ValidationTypes } from '../econsultBaseValidator/EconsultValidationTypes';
import { ValidationResult } from '../econsultBaseValidator/ValidationResult';

const PatientFormTextField = styled(TextField)({
  width: '70%',
  '& .MuiInputBase-input': {
    fontSize: '0.875rem'
  },
  '& .MuiInputBase-input::placeholder': {
    fontSize: '0.875rem'
  }
});

const PatientFormTextFieldFull = styled(TextField)({
  '& .MuiInputBase-input': {
    fontSize: '0.875rem'
  },
  '& .MuiInputBase-input::placeholder': {
    fontSize: '0.875rem'
  }
});

const PatientFormDateField = styled(DesktopDatePicker)({
  '& .MuiInputBase-input': {
    height: 10,
    fontSize: '0.875rem'
  },
  '& .MuiInputBase-input::placeholder': {
    fontSize: '0.875rem'
  }
});

const EconsultCasePatientForm = forwardRef((props, ref) => {
  const { caseDetails, setCaseDetails } = useEconsultCaseDetailsContext();
  const [isOhipValid, setIsOhipValid] = useState<boolean | undefined>(undefined);
  const [, setNoOhip] = useState<boolean>(false);
  const [isOhipFieldDirty, setIsOhipFieldDirty] = useState<boolean>(false);
  const [isConsentShown, setIsConsentShown] = useState<boolean>(
    Boolean(caseDetails?.patientConsent)
  );
  const [patientConsentDetails, setPatientConsentDetails] = useState<string | undefined>(
    caseDetails?.patientConsent
  );
  const requiredFields: Map<string, ValidationTypes> = new Map();
  requiredFields.set('firstName', ValidationTypes.Text);
  requiredFields.set('lastName', ValidationTypes.Text);
  requiredFields.set('dateOfBirth', ValidationTypes.Date);
  const [dirtyFields, setFieldDiryty] = useState<string[]>([]);
  const [invalidFields, setFieldInvalid] = useState<string[]>([]);
  const { t } = useTranslation();
  const [isPatientValid, setIsPatientValid] = useState(true);
  const unSelectedOptFontColor = 'base.grey4';
  const selectedOptFontColor = '#0078C9';
  const [consentFontColor, setConsentFontColor] = useState(unSelectedOptFontColor);
  const [ohipFontColor, setOhipFontColor] = useState(unSelectedOptFontColor);
  const [maleFontColor, setMaleFontColor] = useState(unSelectedOptFontColor);
  const [femaleFontColor, setFemaleFontColor] = useState(unSelectedOptFontColor);
  const [otherFontColor, setOtherFontColor] = useState(unSelectedOptFontColor);
  const isPatientFormValid = (): boolean => {
    let isPatientDetailsValid: boolean = true;
    if (!caseDetails?.patient?.firstName) {
      setFieldInvalid(ValidatorUtils.addToList(invalidFields, 'firstName'));
      isPatientDetailsValid = false;
    }
    if (!caseDetails?.patient?.lastName) {
      setFieldInvalid(ValidatorUtils.addToList(invalidFields, 'lastName'));
      isPatientDetailsValid = false;
    }
    if (!caseDetails?.patient?.dateOfBirth) {
      setFieldInvalid(ValidatorUtils.addToList(invalidFields, 'dateOfBirth'));
      isPatientDetailsValid = false;
    }
    setFieldInvalid(invalidFields);
    setIsPatientValid(isPatientDetailsValid);
    return isPatientDetailsValid;
  };
  useImperativeHandle(ref, () => ({
    validate: () => {
      return isPatientFormValid();
    }
  }));
  const currentDate = () => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${year}-${month}-${date}`;
  };
  const [dob, setDob] = React.useState<Dayjs | null>(dayjs(''));

  const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMaleFontColor(unSelectedOptFontColor);
    setFemaleFontColor(unSelectedOptFontColor);
    setOtherFontColor(unSelectedOptFontColor);
    const { value } = event.target;
    if (value === 'M') {
      setMaleFontColor(selectedOptFontColor);
    } else if (value === 'F') {
      setFemaleFontColor(selectedOptFontColor);
    } else if (value === 'O') {
      setOtherFontColor(selectedOptFontColor);
    }
  };

  const handleGenderLoad = (gender: string) => {
    setMaleFontColor(unSelectedOptFontColor);
    setFemaleFontColor(unSelectedOptFontColor);
    setOtherFontColor(unSelectedOptFontColor);

    if (gender === 'M') {
      setMaleFontColor(selectedOptFontColor);
    } else if (gender === 'F') {
      setFemaleFontColor(selectedOptFontColor);
    } else if (gender === 'O') {
      setOtherFontColor(selectedOptFontColor);
    }
  };
  const handleNoOhiplLoad = (noOhipVal: boolean) => {
    setOhipFontColor(unSelectedOptFontColor);
    if (noOhipVal) {
      setOhipFontColor(selectedOptFontColor);
      setNoOhip(true);
    }
  };
  const handleConsentGivenLoad = (consentGiven: boolean) => {
    setConsentFontColor(unSelectedOptFontColor);
    if (consentGiven) {
      setConsentFontColor(selectedOptFontColor);
      setIsConsentShown(true);
      setPatientConsentDetails(caseDetails?.patientConsent);
    }
  };
  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    const fieldName = { name }.name;

    handleGenderChange(event);

    const res: ValidationResult = ValidatorUtils.handleValidation(
      requiredFields,
      dirtyFields,
      invalidFields,
      fieldName,
      value
    );
    setIsPatientValid(res.isValid);
    setFieldDiryty(res.dirtyFields);
    setFieldInvalid(res.invalidFields);

    const updatedCaseDetails = {
      ...caseDetails,
      patient: {
        ...caseDetails?.patient,
        [name]: value
      }
    };
    setCaseDetails(updatedCaseDetails);
  };
  const validateOhip = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isOhipFieldDirty) {
      setIsOhipFieldDirty(true);
    }
    const { value } = event.target;
    setIsOhipValid(OhipValidationService.isOhipNumberValid(value));

    const noohip = value === '';
    setNoOhip(noohip);

    const updatedCaseDetails = {
      ...caseDetails,
      patient: {
        ...caseDetails?.patient,
        ohipNumber: value,
        noOhip: noohip
      }
    };
    setCaseDetails(updatedCaseDetails);

    handleFieldChange(event);
  };

  const handleNoOhip = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOhipFieldDirty(false);
    setOhipFontColor(unSelectedOptFontColor);
    if (event.target.checked) {
      setOhipFontColor(selectedOptFontColor);
    }
    const updatedCaseDetails = {
      ...caseDetails,
      patient: {
        ...caseDetails?.patient,
        noOhip: event.target.checked
      }
    };
    setNoOhip(event.target.checked);
    delete updatedCaseDetails.patient.ohipNumber;
    delete updatedCaseDetails.patient.ohipVersion;
    setCaseDetails(updatedCaseDetails);
  };
  const handleConsentChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsConsentShown(event.target.checked);
    setConsentFontColor(event.target.checked ? selectedOptFontColor : unSelectedOptFontColor);
    if (caseDetails) {
      const { patientConsent, ...rest } = caseDetails;
      setCaseDetails(rest);
    }
    setPatientConsentDetails('');
  };
  const handlePatientConsentFieldChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPatientConsentDetails(value);
    setCaseDetails({
      ...caseDetails,
      patientConsent: value
    });
  };
  const handlePatientConsentFieldBlur = () => {
    setCaseDetails({
      ...caseDetails,
      patientConsent: patientConsentDetails
    });
  };
  useEffect(() => {
    if (caseDetails && caseDetails.patient) {
      if (caseDetails.patient.dateOfBirth) {
        setDob(dayjs(caseDetails.patient.dateOfBirth));
      } else {
        setDob(dayjs(''));
      }
      if (caseDetails.patient.gender) {
        handleGenderLoad(caseDetails.patient.gender);
      }
      if (caseDetails.patient.noOhip) {
        handleNoOhiplLoad(caseDetails.patient.noOhip);
      }
      if (caseDetails?.patientConsent) {
        handleConsentGivenLoad(true);
      }
    }
  });

  return (
    <Grid container spacing={0} rowSpacing={2}>
      <Grid item xs={12} md={3}>
        <RequiredFieldTypography variant="body2" color="base.grey4">
          {t('Case.patient.firstName')}
        </RequiredFieldTypography>
      </Grid>
      <Grid item xs={12} md={9}>
        <PatientFormTextField
          value={caseDetails?.patient?.firstName}
          name="firstName"
          id="firstName"
          placeholder={t('Case.patient.enterFirstName') as string | undefined}
          variant="standard"
          onChange={handleFieldChange}
          // eslint-disable-next-line prettier/prettier
          error={
            !isPatientValid ||
            ValidatorUtils.isFieldNotValidAndDirty(dirtyFields, invalidFields, 'firstName')
          }
          helperText={
            // eslint-disable-next-line prettier/prettier
            !isPatientValid ||
            ValidatorUtils.isFieldNotValidAndDirty(dirtyFields, invalidFields, 'firstName')
              ? t('Case.consult.invalidFirstName')
              : ''
          }
        />
      </Grid>
      <Grid item xs={12} md={3} pl={1.5}>
        <Typography variant="body2" color="base.grey4">
          {t('Case.patient.middleName')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <PatientFormTextField
          value={caseDetails?.patient?.middleName}
          name="middleName"
          placeholder={t('Case.patient.enterMiddleName') as string | undefined}
          variant="standard"
          onChange={handleFieldChange}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <RequiredFieldTypography variant="body2" color="base.grey4">
          {t('Case.patient.lastName')}
        </RequiredFieldTypography>
      </Grid>
      <Grid item xs={12} md={9}>
        <PatientFormTextField
          value={caseDetails?.patient?.lastName}
          name="lastName"
          id="lastName"
          placeholder={t('Case.patient.enterLastName') as string | undefined}
          variant="standard"
          onChange={handleFieldChange}
          // eslint-disable-next-line prettier/prettier
          error={
            !isPatientValid ||
            ValidatorUtils.isFieldNotValidAndDirty(dirtyFields, invalidFields, 'lastName')
          }
          helperText={
            // eslint-disable-next-line prettier/prettier
            !isPatientValid ||
            ValidatorUtils.isFieldNotValidAndDirty(dirtyFields, invalidFields, 'lastName')
              ? t('Case.consult.invalidLastName')
              : ''
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <RequiredFieldTypography variant="body2" color="base.grey4" pt={1}>
          {t('Case.patient.dob')}
        </RequiredFieldTypography>
      </Grid>
      <Grid item xs={12} md={9}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <PatientFormDateField
            format="YYYY-MM-DD"
            value={dob}
            onChange={(newValue) => {
              const fieldName = 'dateOfBirth';
              const updatedCaseDetails = {
                ...caseDetails,
                patient: {
                  ...caseDetails?.patient,
                  dateOfBirth: newValue?.format('YYYY-MM-DD') ?? ''
                }
              };
              setCaseDetails(updatedCaseDetails);
              if (ValidatorUtils.isValidField(newValue, ValidationTypes.Date)) {
                setFieldDiryty(ValidatorUtils.addToList(dirtyFields, fieldName));
                setFieldInvalid(ValidatorUtils.removeFromList(invalidFields, fieldName));
              }
              return setDob(newValue);
            }}
            name="dateOfBirth"
            maxDate={dayjs(currentDate())}
            slotProps={{
              textField: {
                helperText:
                  // eslint-disable-next-line prettier/prettier
                  !isPatientValid && ValidatorUtils.isFieldNotValid(invalidFields, 'dateOfBirth')
                    ? t('Case.consult.invalidDateOfBirth')
                    : '',
                error:
                  !isPatientValid && ValidatorUtils.isFieldNotValid(invalidFields, 'dateOfBirth')
              }
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={3}>
        <RequiredFieldTypography variant="body2" color="base.grey4" pt={1}>
          {t('Case.patient.gender')}
        </RequiredFieldTypography>
      </Grid>
      <Grid item xs={12} md={9}>
        <FormControl component="fieldset">
          <RadioGroup
            value={caseDetails?.patient?.gender}
            aria-label="gender"
            name="gender"
            onChange={handleFieldChange}>
            <Stack direction="row">
              <FormControlLabel
                value="M"
                control={
                  <Radio
                    sx={{
                      '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
                        color: maleFontColor
                      },
                      '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                        color: maleFontColor
                      }
                    }}
                  />
                }
                label="Male"
                sx={{
                  color: maleFontColor,
                  fontSize: '0.875rem',
                  '& .MuiTypography-root': {
                    fontSize: '0.875rem'
                  }
                }}
              />
              <FormControlLabel
                value="F"
                control={
                  <Radio
                    sx={{
                      '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
                        color: femaleFontColor
                      },
                      '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                        color: femaleFontColor
                      }
                    }}
                  />
                }
                label="Female"
                sx={{
                  color: femaleFontColor,
                  fontSize: '0.875rem',
                  '& .MuiTypography-root': {
                    fontSize: '0.875rem'
                  }
                }}
              />
              <FormControlLabel
                value="O"
                control={
                  <Radio
                    sx={{
                      '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
                        color: otherFontColor
                      },
                      '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                        color: otherFontColor
                      }
                    }}
                  />
                }
                label="Other"
                sx={{
                  color: otherFontColor,
                  fontSize: '0.875rem',
                  '& .MuiTypography-root': {
                    fontSize: '0.875rem'
                  }
                }}
              />
            </Stack>
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <RequiredFieldTypography variant="body2" color="base.grey4" pt={1}>
          {t('Case.patient.ohip')}
        </RequiredFieldTypography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Stack direction="column">
          <Stack direction="row" spacing={2}>
            <PatientFormTextFieldFull
              placeholder={t('Case.patient.enterOhip') as string | undefined}
              variant="standard"
              name="ohipNumber"
              value={caseDetails?.patient?.ohipNumber ?? ''}
              onChange={validateOhip}
              error={!isOhipValid && isOhipFieldDirty}
              helperText={!isOhipValid && isOhipFieldDirty ? t('Case.consult.invalidOhip') : ''}
            />
            <PatientFormTextFieldFull
              placeholder={t('Case.patient.versionCode') as string | undefined}
              name="ohipVersion"
              value={caseDetails?.patient?.ohipVersion ?? ''}
              variant="standard"
              onChange={handleFieldChange}
            />
          </Stack>
          <FormGroup onChange={handleNoOhip}>
            <FormControlLabel
              name="noOhip"
              control={
                <Checkbox
                  defaultChecked={Boolean(caseDetails?.patient?.noOhip)}
                  sx={{
                    '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
                      color: ohipFontColor
                    },
                    '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                      color: ohipFontColor
                    }
                  }}
                />
              }
              label={t('Case.patient.ohipNotAvailable')}
              sx={{
                color: ohipFontColor,
                fontSize: '0.875rem',
                '& .MuiTypography-root': {
                  fontSize: '0.875rem'
                }
              }}
            />
          </FormGroup>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <FormGroup onChange={handleConsentChanged}>
          <FormControlLabel
            checked={isConsentShown}
            control={
              <Checkbox
                sx={{
                  '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
                    color: consentFontColor
                  },
                  '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                    color: consentFontColor
                  }
                }}
              />
            }
            label={t('Case.patient.consentDirectives')}
            sx={{
              color: consentFontColor,
              fontSize: '0.875rem',
              '& .MuiTypography-root': {
                fontSize: '0.875rem'
              }
            }}
          />
        </FormGroup>
      </Grid>
      {isConsentShown && (
        <Grid item xs={12}>
          <PatientFormTextFieldFull
            name="patientConsent"
            value={patientConsentDetails}
            onChange={handlePatientConsentFieldChanged}
            onBlur={handlePatientConsentFieldBlur}
            fullWidth
            multiline
            rows={4}
            InputLabelProps={{
              shrink: true
            }}
            placeholder={
              // eslint-disable-next-line max-len
              'Please enter any consent limitations. Examples could include:\n1. Do not forward this consult to *name of person|Organization*\n2. Only send referral to *name of person | Organization* '
            }
          />
        </Grid>
      )}
    </Grid>
  );
});

export default EconsultCasePatientForm;
