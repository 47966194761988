import { Box, Button, Paper, Stack, Typography, styled } from '@mui/material';
import { FileMetaData, NoteActionTypes, NoteTypes, dateUtils } from '@oh-vcp/components-common';
import { CaseNote } from '@oh-vcp/components-common/src/models/case/CaseDetails';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Check } from '@mui/icons-material';
import CaseService from '../services/caseService';
import EconsultAttachmentLink from '../econsultAttachmentLink/EconsultAttachmentLink';

interface EconsultCaseNoteItemProps {
  note: CaseNote;
}

const EconsultCaseNoteItemPaper = styled(Paper)({
  paddingLeft: 15,
  paddingRight: 15,
  paddingTop: 30,
  paddingBottom: 30,
  marginBottom: 20,
  borderRadius: 0,
  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 5px 0px'
});

const CheckMarkIcon = styled(Check)(({ theme }) => ({
  color: theme.palette.primary.custom3,
  fontSize: 18
}));

const EconsultCaseNoteItem: FC<EconsultCaseNoteItemProps> = ({ note }) => {
  const {
    actionTypeCd,
    lastUpdated,
    noteContent,
    noteTypeCd,
    parameter1,
    attachments,
    patientToBeSeen
  } = note;
  const { t } = useTranslation();
  const getStatus = (status: string) => {
    switch (status) {
      case NoteActionTypes.send:
        return t('Case.notestatus.send');
      case NoteActionTypes.writeConsult:
        return t('Case.notestatus.writeConsult');
      case NoteActionTypes.askForMoreInfo:
        return t('Case.notestatus.askForMoreInfo');
      case NoteActionTypes.decline:
        return t('Case.notestatus.decline');
      case NoteActionTypes.finish:
        return t('Case.notestatus.finish');
      case NoteActionTypes.reply:
        return t('Case.notestatus.reply');
      case NoteActionTypes.cancel:
        return t('Case.notestatus.cancel');
      case NoteActionTypes.forward:
        return t('Case.notestatus.forward');
      case NoteActionTypes.addNote:
        return t('Case.notestatus.addNote');
      case NoteActionTypes.reopen:
        return t('Case.notestatus.reopen');
      case NoteActionTypes.recomplete:
        return t('Case.notestatus.recomplete');
      case NoteActionTypes.assign:
        return t('Case.notestatus.assign');
      case NoteActionTypes.unassign:
        return t('Case.notestatus.unassign');
      default:
        return t('Case.status.unknown');
    }
  };

  const getNoteHeading = () => {
    if (
      actionTypeCd === NoteActionTypes.assign &&
      noteContent &&
      noteContent.indexOf('automatically assigned') !== -1
    ) {
      return '';
    }
    if (NoteTypes.triageAdmin === noteTypeCd) {
      if (NoteActionTypes.forward === actionTypeCd) {
        return t('Case.note.cancelledDueToRedirection');
      }
      if (NoteActionTypes.addNote === actionTypeCd) {
        return `${note.lastUpdatedBy} ${t('Case.note.redirectCaseTo')} ${parameter1}`;
      }
    }

    let heading = `${note.lastUpdatedBy} ${getStatus(actionTypeCd)}`;
    if (parameter1) {
      if (noteTypeCd === NoteTypes.triageAdmin && actionTypeCd === NoteActionTypes.assign) {
        heading += ` to ${parameter1}`;
      } else if (
        (actionTypeCd === NoteActionTypes.decline || actionTypeCd === NoteActionTypes.unassign) &&
        parameter1
      ) {
        heading += `. Reason: ${parameter1}`;
      }
    }
    return heading;
  };
  return (
    <EconsultCaseNoteItemPaper>
      <Stack direction="row">
        <Stack flexGrow={1}>
          <Typography variant="body2" color="base.grey4">
            {getNoteHeading()}
          </Typography>
        </Stack>
        <Typography variant="body2" color="base.grey4">
          {moment(lastUpdated).format('MMM DD, yyyy  h:mm A')}
        </Typography>
      </Stack>
      {noteContent && (
        <Typography variant="body2" color="base.grey3" mt={1}>
          {noteContent}
        </Typography>
      )}
      {patientToBeSeen && (
        <Stack direction="row" mt={1} columnGap={0.5}>
          <CheckMarkIcon />
          <Typography variant="body4">{t('Case.consult.recommendation')}</Typography>
        </Stack>
      )}
      {attachments &&
        attachments.map((a) => <EconsultAttachmentLink key={a.attachmentId} attachment={a} />)}
    </EconsultCaseNoteItemPaper>
  );
};

export default EconsultCaseNoteItem;
