import { MenuItem, Select, SelectChangeEvent, styled, Typography } from '@mui/material';
import React, { FC } from 'react';
import {
  CaseResultDetails,
  EconsultSpecialist,
  EconsultUnavailabilityDate,
  EconsultUtils,
  NameUtils
} from '@oh-vcp/components-common';
import useProgramService from '../services/useProgramService';

const SelectContainer = styled(Select)({
  height: 25,
  width: '100%'
});

interface EconsultProgramSpecialistSelectorProps {
  caseResult: CaseResultDetails;
  selectedSpecialist: string | undefined;
  onSpecialistSelected: (
    specialist: EconsultSpecialist | undefined,
    outOfOfficeInAWeek?: EconsultUnavailabilityDate | null
  ) => void;
}

const EconsultProgramSpecialistSelector: FC<EconsultProgramSpecialistSelectorProps> = ({
  caseResult,
  selectedSpecialist,
  onSpecialistSelected
}) => {
  const { programId, consultantId, referrerLhin, patientAge } = caseResult || {};

  const { data: specialists } = useProgramService(programId?.toString());

  const getSpecialistOutOfOffice = (specialist: EconsultSpecialist) => {
    if (!specialist.unavailabilities) {
      return null;
    }
    const currentOOODate = EconsultUtils.getCurrentOutOfOfficeDate(specialist.unavailabilities);
    return currentOOODate;
  };

  const isAgeGroupRestricted = (specialist: EconsultSpecialist) => {
    if (specialist.acceptedAge === 'ADULT') {
      return patientAge < 19;
    }
    return patientAge >= 19;
  };

  const isSpecialistOptionDisabled = (specialist: EconsultSpecialist) => {
    if (consultantId === specialist.userId) {
      return false;
    }
    if (specialist.isEconsultDecomissioned || getSpecialistOutOfOffice(specialist) !== null) {
      return true;
    }
    if (specialist.acceptedAge && isAgeGroupRestricted(specialist)) {
      return true;
    }
    return (
      specialist.acceptedLhins && referrerLhin && specialist.acceptedLhins.indexOf(referrerLhin) < 0
    );
  };

  const getSpecialistOutOfOfficeInAWeek = (specialist: EconsultSpecialist) => {
    if (!specialist.unavailabilities) {
      return null;
    }
    const oooDate = EconsultUtils.getOutOfOfficeDateInAWeek(specialist.unavailabilities);
    return oooDate;
  };

  const getSpecialistOptionName = (specialist: EconsultSpecialist) => {
    let optionName = NameUtils.makeName(
      specialist.lastName,
      specialist.firstName,
      specialist.salutation,
      null,
      specialist.middleName,
      null
    );

    if (!specialist.numOfInProgress) {
      specialist.numOfInProgress = 0;
    }
    if (!specialist.numOfAssigned) {
      specialist.numOfAssigned = 0;
    }
    let disable = false;
    if (specialist.isEconsultDecomissioned) {
      optionName += ' - deregistered';
      disable = true;
    } else {
      const currentOooDate = getSpecialistOutOfOffice(specialist);
      if (currentOooDate !== null) {
        optionName += ` - unavailable from ${currentOooDate.startDate} to ${currentOooDate.endDate}`;
        disable = true;
      } else {
        const nextWeekOooDate = getSpecialistOutOfOfficeInAWeek(specialist);
        if (nextWeekOooDate !== null) {
          optionName += ` - unavailable from ${nextWeekOooDate.startDate} to ${nextWeekOooDate.endDate}`;
        }
      }
    }
    if (!disable) {
      if (specialist.acceptedAge && isAgeGroupRestricted(specialist)) {
        optionName += ' - restricted patient age group';
        disable = true;
      } else if (specialist.acceptedLhins && referrerLhin) {
        if (specialist.acceptedLhins.indexOf(referrerLhin) < 0) {
          optionName += ' - restricted Regions';
          disable = true;
        }
      }
    }
    if (!disable) {
      if (specialist.maximumCases7Days) {
        optionName += ` - ${specialist.receivedCases7Days}/${specialist.maximumCases7Days} cases in the last 7 days, `;
      } else {
        optionName += ' - ';
      }
      optionName += `${specialist.numOfAssigned} case${
        specialist.numOfAssigned === 1 ? '' : 's'
      } in the last 30 days, ${specialist.numOfInProgress} pending response`;
    }
    return optionName;
  };

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleSpecialistSelected = (e: SelectChangeEvent<unknown>) => {
    const s = specialists?.find((spec) => spec.userId === parseInt(e.target.value as string, 10));
    if (s) {
      const oooDate = EconsultUtils.getOutOfOfficeDateInAWeek(s.unavailabilities);
      onSpecialistSelected(s, oooDate);
    } else {
      onSpecialistSelected(undefined);
    }
  };
  if (!specialists || specialists.length === 0) return null;
  return (
    <SelectContainer
      onClick={handleOnClick}
      value={
        selectedSpecialist && specialists.some((s) => s.userId.toString() === selectedSpecialist)
          ? selectedSpecialist
          : '0'
      }
      onChange={handleSpecialistSelected}>
      <MenuItem value="0">
        <Typography variant="body2">Select a specialist</Typography>
      </MenuItem>
      {specialists.map((s) => (
        <MenuItem
          value={s.userId}
          disabled={isSpecialistOptionDisabled(s)}
          sx={{ color: isSpecialistOptionDisabled(s) ? 'grey' : 'inherit' }}>
          <Typography variant="body2">{getSpecialistOptionName(s)}</Typography>
        </MenuItem>
      ))}
    </SelectContainer>
  );
};

export default EconsultProgramSpecialistSelector;
