import { BorderStyle } from '@mui/icons-material';
import { Grid, styled, Typography } from '@mui/material';
import { borderColor } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { NameUtils } from '@oh-vcp/components-common';
import { useEconsultCaseDetailsContext } from '../econsultCaseDetailsContext/EconsultCaseDetailsContext';

const PatientContainer = styled(Grid)(({ theme }) => ({
  borderColor: theme.palette.base.grey5,
  borderStyle: 'solid',
  borderWidth: 1,
  padding: 5,
  paddingLeft: 10,
  width: '75%'
}));

const EconsultPatientDetailsContainer = () => {
  const { t } = useTranslation();
  const { caseDetails } = useEconsultCaseDetailsContext();
  if (!caseDetails) {
    return null;
  }
  const { patient, patientConsent } = caseDetails;
  if (!patient) return null;
  const { firstName, lastName, middleName, dateOfBirth, gender, ohipNumber } = patient;
  return (
    <PatientContainer container rowGap={1}>
      <Grid item xs={12} md={5}>
        <Typography variant="body2">{t('Case.patient.firstName')}</Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Typography variant="body2">{firstName}</Typography>
      </Grid>
      <Grid item xs={12} md={5}>
        <Typography variant="body2">{t('Case.patient.middleName')}</Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Typography variant="body2">{middleName}</Typography>
      </Grid>
      <Grid item xs={12} md={5}>
        <Typography variant="body2">{t('Case.patient.lastName')}</Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Typography variant="body2">{lastName}</Typography>
      </Grid>
      <Grid item xs={12} md={5}>
        <Typography variant="body2">{t('Case.patient.dateOfBirth')}</Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Typography variant="body2">{moment(dateOfBirth).format('YYYY-MM-DD')}</Typography>
      </Grid>
      <Grid item xs={12} md={5}>
        <Typography variant="body2">{t('Case.patient.gender')}</Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Typography variant="body2">{NameUtils.makeGenderName(gender)}</Typography>
      </Grid>
      <Grid item xs={12} md={5}>
        <Typography variant="body2">{t('Case.patient.ohip')}</Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Typography variant="body2">{ohipNumber || t('Case.patient.notAvailable')}</Typography>
      </Grid>
      {patientConsent && (
        <>
          <Grid item xs={12} md={5}>
            <Typography variant="body2">{t('Case.consult.consentDirectives')}</Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography variant="body2">{patientConsent}</Typography>
          </Grid>
        </>
      )}
    </PatientContainer>
  );
};

export default EconsultPatientDetailsContainer;
