import { EconsultUserSettings } from '@oh-vcp/components-common';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useQuery } from 'react-query';
import apiService from './apiService';

const fetchUserSettings = async (): Promise<EconsultUserSettings> => {
  const response = await apiService.get('users/selfserve/settings');
  return response?.data;
};

const useEconsultUserSettingService = (token: string | null) => {
  return useQuery<EconsultUserSettings>(
    ['useEconsultUserSettingService'],
    () => fetchUserSettings(),
    {
      refetchOnWindowFocus: false,
      enabled: !!token
    }
  );
};

export default useEconsultUserSettingService;
