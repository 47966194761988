import { transform } from '@babel/core';
import {
  AppBar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputBase,
  Link,
  SvgIcon,
  Typography,
  Checkbox,
  Button,
  Toolbar,
  Stack,
  TextField,
  AccordionSummary,
  List,
  Popper,
  Theme,
  FormControlLabel
} from '@mui/material';
import { border, height, minWidth, styled, width } from '@mui/system';
import Carousel from 'react-material-ui-carousel';

export const StyledBoxContainer = styled(Box)({
  height: '100%',
  width: '100%'
});

export const OutlinedStyledBoxContainer = styled(StyledBoxContainer)(({ theme }) => ({
  border: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.primary.main
}));

export const StyledCardContent = styled(CardContent)({
  textAlign: 'left',
  padding: '16px 0 0 0'
});

export const StyledBox = styled(Box)({
  paddingLeft: 48
});

export const StyledLocationBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.A100,
  height: '100%',
  borderTop: 1,
  borderTopStyle: 'solid',
  borderTopColor: theme.palette.base.grey6,
  paddingRight: 10
}));

export const StyledCardHeaderTitle = styled(Typography)(() => ({
  textAlign: 'left',
  paddingBottom: 5
}));

export const StyledCardHeaderSubTitle = styled(Typography)(() => ({
  fontWeight: 500,
  textAlign: 'left'
}));

export const NavBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: '0px 3px 6px #00000029',
  padding: '5px 20px 5px 0',
  zIndex: 1101
}));

export const Logo = styled(SvgIcon)(({ theme }) => ({
  width: 150,
  height: 40,
  color: theme.palette.text.primary
}));

export const PaddedContainer = styled(Box)(({ theme }) => ({
  padding: '0 15%',
  [theme.breakpoints.down('lg')]: {
    padding: 0
  }
}));

export const PaddedLayout = styled(PaddedContainer)({
  flexGrow: 1
});

export const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#f2fbff',
  padding: '.25% 5%',
  [theme.breakpoints.down('lg')]: {
    padding: '1% 5%'
  }
}));

export const SelectInputBase = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    border: 'none'
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main
  }
}));

export const HelpIcon = styled(SvgIcon)(({ theme }) => ({
  borderRadius: '50%',
  backgroundColor: theme.palette.secondary.A200,
  [theme.breakpoints.up('xl')]: {
    transform: 'scale(1.5)'
  }
}));

export const IconContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.A200,
  borderRadius: '50%',
  padding: '15px',
  height: '50px',
  width: '50px'
}));

export const FooterSection = styled(Box)({
  paddingTop: '.5%',
  paddingBottom: '.5%'
});

export const ResponsiveFooterSection = styled(FooterSection)(({ theme }) => ({
  paddingRight: '10%',
  [theme.breakpoints.down('xs')]: {
    paddingRight: 0
  }
}));

export const ResponsiveGrid = styled(Grid)(({ theme }) => ({
  flexDirection: 'row',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column'
  }
}));

export const FullSizeIcon = styled(SvgIcon)({
  height: '100%',
  width: '100%'
});

export const DisplayDesktop = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none'
  }
}));

export const DisplayMobile = styled(Box)(({ theme }) => ({
  display: 'inherit',
  [theme.breakpoints.up('lg')]: {
    display: 'none'
  }
}));

export const DisplayDesktopPaddedContainer = styled(PaddedContainer)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: 0
  }
}));

export const NavBarPaddedContainer = styled(Box)(({ theme }) => ({
  padding: '0 4%',
  [theme.breakpoints.down('lg')]: {
    padding: 0
  }
}));

export const FlexGrow = styled(Box)({
  flexGrow: 1
});

export const LanguageSelectorContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    marginRight: 20
  },
  columnGap: 20
}));

export const ResultHeaderIcon = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#F3F3F3 0% 0% no-repeat padding-box',
  border: '1px solid #D7DBEC',
  width: 24,
  height: 24,
  borderRadius: '50%',
  marginRight: 10
}));

export const SearchBarIcon = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 15,
  height: 15
}));

export const ResultContainer = styled(Box)(({ theme }) => ({
  paddingTop: 16,
  textAlign: 'left',
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 48,
    paddingTop: 0,
    paddingBottom: 5,
    paddingRight: 5
  }
}));

export const ResultCardHeader = styled(CardHeader)({
  padding: 0,
  paddingRight: 40,
  margin: 16,
  overflowWrap: 'anywhere'
});

export const ResultContentIcon = styled(SvgIcon)({
  marginTop: 5,
  transform: 'scale(1.2)'
});

export const ResultLocationLabelContainer = styled(Box)({
  minWidth: '22rem',
  paddingTop: 5,
  paddingLeft: 45
});

export const ResultLocationHoursContainer = styled(Box)(({ theme }) => ({
  padding: 0,
  [theme.breakpoints.down('lg')]: {
    paddingTop: 5,
    paddingLeft: '.5rem',
    minWidth: '15.3rem'
  }
}));

export const ProfileCard = styled(Card)({
  padding: '1rem 1rem',
  display: 'flex',
  flexDirection: 'column'
});

export const ProfileCardFullHeight = styled(ProfileCard)(({ theme }) => ({
  height: '100%',
  [theme.breakpoints.down('md')]: {
    paddingBottom: 30
  }
}));

export const ProfileHeaderIcon = styled(ResultHeaderIcon)(({ theme }) => ({
  // transform: 'scale(2.5)',
  width: 74,
  height: 74,
  // margin: '1rem 0 0 1rem',
  [theme.breakpoints.down('lg')]: {
    margin: '0 0 1.5rem 0'
  }
}));

export const ProfileHeaderContainer = styled(Stack)({
  flexDirection: 'column',
  marginTop: 25,
  rowGap: 5
});

export const ProfilePropertyContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  marginTop: 15,
  marginRight: 50,
  [theme.breakpoints.only('sm')]: {
    marginTop: 0,
    marginRight: 0
  }
}));

export const LinkImage = styled(SvgIcon)({
  transform: 'scale(0.7)',
  marginRight: 5
});

export const LinkContainer = styled(Link)({
  marginTop: 10
});

export const MapContainer = styled(Box)(({ theme }) => ({
  '& img': {
    height: '30%',
    marginTop: 15,
    marginRight: 10,
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      height: '100%'
    }
  }
}));

export const FullSizeImage = styled(Box)({
  '& img': {
    width: '100%'
  }
});

export const ResponsiveFlexContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column'
  }
}));

export const ProfilePropertyContainerSm = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  marginBottom: 40,
  marginRight: 30,
  [theme.breakpoints.down('lg')]: {
    marginBottom: 10
  }
}));

export const ServicesOfferedContainer = styled(Box)(({ theme }) => ({
  borderTop: 1,
  borderTopStyle: 'solid',
  borderTopColor: theme.palette.base.grey6,
  height: '100%',
  padding: 5,
  paddingLeft: 50
}));

export const StaticCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.Mui-disabled': {
    color: theme.palette.base.grey2
  },
  '&.Mui-checked': {
    color: theme.palette.primary.main
  }
}));

export const OverflowFlexContainer = styled(Stack)({
  flexDirection: 'row',
  maxWidth: '100%',
  overflow: 'hidden'
});

export const EllipsisTypography = styled(Typography)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '100%'
});

export const TextSeparator = styled(Box)(({ theme }) => ({
  borderRight: 2,
  borderRightColor: theme.palette.base.grey5,
  borderRightStyle: 'solid',
  marginLeft: 5,
  marginRight: 5
}));

export const CenterAlign = styled(Box)({
  textAlign: 'center'
});

export const FilterListItem = styled(Box)(({ theme }) => ({
  marginLeft: 10,
  marginRight: 10,
  borderTopWidth: 1,
  borderTopColor: theme.palette.base.grey5,
  borderTopStyle: 'solid',
  '&:last-child': {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.base.grey5,
    borderBottomStyle: 'solid'
  }
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  borderWidth: 2,
  borderStyle: 'solid',
  margin: 0,
  padding: 0,
  paddingLeft: 20,
  paddingRight: 20
}));

export const ActionMenuItemLabel = styled(Box)(() => ({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center'
}));

export const ProfileSummaryIconContainer = styled(Box)({
  background: '#F3F3F3 0% 0% no-repeat padding-box',
  border: '1px solid #D7DBEC',
  width: 40,
  height: 40,
  borderRadius: '50%',
  paddingLeft: 7,
  paddingTop: 7
});

export const ProfileSummaryIcon = styled(SvgIcon)({
  width: 50,
  height: '100%'
});

export const DetailsSectionProfileProperty = styled(Box)({
  marginTop: 20
});

export const OfficeReferralCarousel = styled(Carousel)({
  width: '117%',
  left: '-8%'
});

export const OfficeReferralCarouselContainer = styled(Box)({
  width: '85%',
  marginLeft: '7%'
});

export const OfficeReferralMap = styled(Card)({
  width: '100%',
  height: '100%',
  '& img': {
    width: '100%',
    height: '100%'
  },
  '@media print': {
    display: 'none'
  }
});

export const ProfileMenuButton = styled(Button)(({ theme }) => ({
  marginTop: 2,
  marginBottom: 2,
  marginRight: 15,
  display: 'block',
  '&:active': {
    backgroundColor: theme.palette.primary.custom2
  }
}));

export const ProfileAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.base.grey6,
  marginTop: 5,
  boxShadow: 'none',
  top: 59,
  '@media print': {
    display: 'none'
  }
}));

export const NoPrintBox = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  '@media print': {
    display: 'none'
  }
}));
export const PrintBox = styled(Box)(() => ({
  display: 'none',
  '@media print': {
    display: 'block'
  }
}));

export const ProfileMenuToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'flex-start',
  [theme.breakpoints.down('lg')]: {
    overflowX: 'auto',
    paddingLeft: 25
  }
}));

export const HeaderContainerStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    display: 'flex'
  },
  display: 'block'
}));

export const HeaderStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  flexDirection: 'row'
}));

export const HeaderIconStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    display: 'none'
  },
  flexGrow: 1
}));

export const TextFieldNoOutline = styled(TextField)(() => {
  return {
    '& .MuiOutlinedInput-notchedOutline': {
      // border: 'none'
      'border-width': 0
    },
    '& .MuiFormLabel-root': {
      position: 'absolute',
      width: 1,
      height: 1,
      margin: -1,
      padding: 0,
      overflow: 'hidden',
      clip: 'rect(0, 0, 0, 0)',
      border: 0
    }
  };
});

export const GridWithTopBorderOnly = styled(Grid)(({ theme }) => ({
  borderTopWidth: 1,
  borderTopColor: theme.palette.base.grey5,
  borderTopStyle: 'solid'
}));

export const SystemAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: theme.palette.primary.custom,
  backgroundColor: theme.palette.primary.custom4
}));

export const PrintContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.only('sm')]: {
    marginTop: 20,
    paddingBottom: 50
  }
}));

export const FilterListContainer = styled(List)(() => ({
  maxHeight: 300,
  overflow: 'auto'
}));

export const FilterChipContainer = styled(List)(() => ({
  maxHeight: 80,
  overflow: 'auto'
}));

export const UnpaddedTypography = styled(Typography)(() => ({
  '& ul': {
    marginTop: 0
  }
}));

export const WidePopper = styled(Popper)(() => ({
  width: '76%'
}));

export const MenuListPopper = styled(Popper)(({ theme }) => ({
  zIndex: '1100',
  [theme.breakpoints.up('lg')]: {
    /* display: 'none', */
  }
}));

export const MapDrawerStack = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 2,
  height: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: 'auto',
    bottom: 0,
    top: 'auto',
    left: 0,
    flexDirection: 'column-reverse'
  }
}));

export const MapDrawer = styled(Box)<{ isMapDrawerOpen: boolean }>(
  ({ theme, isMapDrawerOpen }) => ({
    backgroundColor: 'white',
    height: '100%',
    overflow: 'auto',
    zIndex: 3,
    width: isMapDrawerOpen ? 430 : 0,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: isMapDrawerOpen ? 'auto' : 0,
      minHeight: isMapDrawerOpen ? 250 : 0
    }
  })
);

export const FilterModalOverlay = styled(Box)<{ isMinimalView: boolean }>(
  ({ theme, isMinimalView }) => ({
    position: 'absolute',
    top: isMinimalView ? 280 : '50%',
    left: isMinimalView ? 200 : '50%',
    transform: 'translate(-50%, -50%)',
    width: 375,
    maxHeight: 490,
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
    padding: 4,
    boxShadow: isMinimalView ? '0 0.1rem 0.25rem' : ''
  })
);

export const SearchIcon = styled(SvgIcon)(({ theme }) => ({
  '& path': {
    fill: theme.palette.base.grey4,
    stroke: theme.palette.base.grey4
  }
}));

export const CaseActionList = styled(List)(({ theme }) => ({
  paddingLeft: '3.5rem',
  paddingRight: '1rem',
  minWidth: 320
}));

export const CancelButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.base.grey4,
  '&:hover': {
    backgroundColor: theme.palette.base.grey4
  }
}));

export const RequiredFieldTypography = styled(Typography)(() => ({
  '&:before': {
    color: '#F19F53',
    content: '"* "',
    fontWeight: 'bold',
    fontSize: '18px'
  }
}));

export const RequiredFieldTypographyWithInfo = styled(Typography)(() => ({
  '&:before': {
    color: '#F19F53',
    content: '"* "',
    fontWeight: 'bold',
    fontSize: '18px'
  },
  '&:after': {
    color: '#ffffff',
    content: '"?"',
    height: '30px',
    border: '1px',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#999999',
    borderRadius: '15px',
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingLeft: '5px',
    paddingRight: '5px',
    marginLeft: '3px',
    marginBottom: '3px'
  }
}));

export const DynamicLink = styled(Link)({
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
});

export const EconsultCancelButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.base.grey5,
  color: theme.palette.base.grey2,
  '&:hover': {
    backgroundColor: theme.palette.base.grey5
  }
}));

export const EconsultTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    fontSize: 14
  }
});

export const CheckBoxOptionControl = styled(FormControlLabel)({
  color: 'base.grey4',
  '& .MuiFormControlLabel-label': {
    fontSize: 14
  }
});
