import { CaseDetails } from '@oh-vcp/components-common';
import lodash from 'lodash';

const caseReferrerChanged = (caseDetails: CaseDetails, oldCaseDetails: CaseDetails) => {
  if (
    (caseDetails.referrer && !oldCaseDetails.referrer) ||
    (!caseDetails.referrer && oldCaseDetails.referrer)
  ) {
    return true;
  }
  if (
    caseDetails.referrer &&
    oldCaseDetails.referrer &&
    caseDetails.referrer.userId !== oldCaseDetails.referrer.userId
  ) {
    return true;
  }
  return false;
};
const casePatientChanged = (caseDetails: CaseDetails, oldCaseDetails: CaseDetails) => {
  if (
    (caseDetails.patient && !oldCaseDetails.patient) ||
    (!caseDetails.patient && oldCaseDetails.patient)
  ) {
    return true;
  }
  if (caseDetails.patient && !lodash.isEqual(caseDetails.patient, oldCaseDetails.patient)) {
    return true;
  }
  return false;
};
const caseSpecialtyChanged = (caseDetails: CaseDetails, oldCaseDetails: CaseDetails) => {
  const newSpecialty = caseDetails.specialty;
  const oldSpecialty = oldCaseDetails ? oldCaseDetails.specialty : null;
  if ((newSpecialty && !oldSpecialty) || (!newSpecialty && oldSpecialty)) {
    return true;
  }
  if (newSpecialty && oldSpecialty && newSpecialty.code !== oldSpecialty.code) {
    return true;
  }
  return false;
};
const caseSubSpecialtyChanged = (caseDetails: CaseDetails, oldCaseDetails: CaseDetails) => {
  const newSubSpecialty = caseDetails.subSpecialty;
  const oldSubSpecialty = oldCaseDetails ? oldCaseDetails.subSpecialty : null;
  if ((newSubSpecialty && !oldSubSpecialty) || (!newSubSpecialty && oldSubSpecialty)) {
    return true;
  }
  if (newSubSpecialty && oldSubSpecialty && newSubSpecialty.subCode !== oldSubSpecialty.subCode) {
    return true;
  }
  return false;
};
const caseParticipantChanged = (caseDetails: CaseDetails, oldCaseDetails: CaseDetails) => {
  const newParticipant = caseDetails.participant;
  const oldParticipant = oldCaseDetails ? oldCaseDetails.participant : null;
  if ((newParticipant && !oldParticipant) || (!newParticipant && oldParticipant)) {
    return true;
  }
  if (newParticipant && oldParticipant) {
    if (
      newParticipant.consultant &&
      oldParticipant.consultant &&
      newParticipant.consultant.userId !== oldParticipant.consultant.userId
    ) {
      return true;
    }
    if (
      newParticipant.program &&
      oldParticipant.program &&
      newParticipant.program.programId !== oldParticipant.program.programId
    ) {
      return true;
    }
    if (
      (newParticipant.program && oldParticipant.consultant) ||
      (newParticipant.consultant && oldParticipant.program)
    ) {
      return true;
    }
    if (
      (newParticipant.program && !oldParticipant.program) ||
      (!newParticipant.program && oldParticipant.program)
    ) {
      return true;
    }
    if (typeof oldParticipant !== typeof newParticipant) {
      return true;
    }
  }
  return false;
};
const attachmentDescriptionsChanged = (caseDetails: CaseDetails, oldCaseDetails: CaseDetails) => {
  const newAttachments = caseDetails.notes ? caseDetails.notes[0].attachments : null;
  const oldAttachments =
    oldCaseDetails && oldCaseDetails.notes ? oldCaseDetails.notes[0].attachments : null;
  let attachmentsChanged = false;
  if ((newAttachments && !oldAttachments) || (!newAttachments && oldAttachments)) {
    return true;
  }
  if (newAttachments && oldAttachments) {
    oldAttachments.forEach((oldAttachment) => {
      newAttachments?.forEach((newAttachment) => {
        if (
          oldAttachment.attachmentId === newAttachment.attachmentId &&
          oldAttachment.fileDescription !== newAttachment.fileDescription
        ) {
          attachmentsChanged = true;
        }
      });
    });
  }
  return attachmentsChanged;
};
export const hasCaseChanged = (
  caseDetails: CaseDetails | undefined,
  oldCaseDetails: CaseDetails | undefined
) => {
  if (!oldCaseDetails) {
    return true;
  }
  if (!caseDetails) {
    return false;
  }
  if (caseReferrerChanged(caseDetails, oldCaseDetails)) {
    return true;
  }
  if (casePatientChanged(caseDetails, oldCaseDetails)) {
    return true;
  }
  if (caseDetails.programType !== oldCaseDetails.programType) {
    return true;
  }
  if (caseSpecialtyChanged(caseDetails, oldCaseDetails)) {
    return true;
  }
  if (caseSubSpecialtyChanged(caseDetails, oldCaseDetails)) {
    return true;
  }
  if (caseDetails.priorityCd !== oldCaseDetails.priorityCd) {
    return true;
  }
  if (caseDetails.chiefComplaint !== oldCaseDetails.chiefComplaint) {
    return true;
  }
  if (caseDetails.patientConsent !== oldCaseDetails.patientConsent) {
    return true;
  }
  if (
    caseDetails.notes &&
    oldCaseDetails.notes &&
    caseDetails.notes[0] &&
    oldCaseDetails.notes[0] &&
    caseDetails.notes[0].noteContent !== oldCaseDetails.notes[0].noteContent
  ) {
    return true;
  }
  if (caseParticipantChanged(caseDetails, oldCaseDetails)) {
    return true;
  }
  if (attachmentDescriptionsChanged(caseDetails, oldCaseDetails)) {
    return true;
  }
  return false;
};

const addNoteTitleReferrer = 'I want to send a note to the specialist';
const addNoteTitleSpecialist = 'I want to send a note to the requester';
const redirectTitle = 'I want to send this case to another recipient';
const provideMoreInfoTitle = 'I want to provide more info about the case';
const cancelTitle = 'I want to cancel the case';
const provideTitle = 'I have all I need to provide consult';
const askMoreInfoTitle = 'I need more info to provide consult';
const returnCaseTitle = 'I cannot provide consult';
const completeCaseTitle = 'I received the answer to my question and want to complete the case';
const requestClarificationTitle = 'Keep case open / need clarification';
const setFlagTitle =
  'Cases can be flagged as Education, Research or Other which enables filtering by flag for easier retrieval';

export const getCaseActionTitle = (tab: string) => {
  if (!tab) {
    return '';
  }
  if (tab === 'AddNoteRef') {
    return addNoteTitleReferrer;
  }
  if (tab === 'AddNoteSpec') {
    return addNoteTitleSpecialist;
  }
  if (tab === 'Re-direct') {
    return redirectTitle;
  }
  if (tab === 'Provide More Info') {
    return provideMoreInfoTitle;
  }
  if (tab === 'Cancel') {
    return cancelTitle;
  }
  if (tab === 'Provide Consult') {
    return provideTitle;
  }
  if (tab === 'Request More Info') {
    return askMoreInfoTitle;
  }
  if (tab === 'Return Consult') {
    return returnCaseTitle;
  }
  if (tab === 'Complete') {
    return completeCaseTitle;
  }
  if (tab === 'Request Clarification') {
    return requestClarificationTitle;
  }
  if (tab === 'flag') {
    return setFlagTitle;
  }
  return '';
};
