import { FileMetaData } from '@oh-vcp/components-common';
import { useQuery } from 'react-query';
import apiService from './apiService';

const fetchFileDetails = async (fileId: string): Promise<FileMetaData | null> => {
  const response = await apiService.get(`files/${fileId}/info`, {
    ignoreError: true
  });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

const useFileService = (fileId: number | undefined) => {
  return useQuery<FileMetaData | null>(
    ['useFileService', fileId],
    () => fetchFileDetails(fileId?.toString() || ''),
    {
      enabled: !!fileId,
      refetchOnWindowFocus: false
    }
  );
};

export default useFileService;
