/* eslint-disable no-nested-ternary */
import { ListItemButton, ListItemIcon, ListItemText, styled } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CaseNote, NoteActionTypes, NoteStatusCodes, NoteTypes } from '@oh-vcp/components-common';
import { useEconsultCaseDetailsContext } from '../econsultCaseDetailsContext/EconsultCaseDetailsContext';
import EconsultCompleteCaseDialog from '../econsultCompleteCaseDialog/EconsultCompleteCaseDialog';
import EconsultUserService from '../services/econsultUserService';
import { useAuth } from '../auth/AuthContext';
import CaseService from '../services/caseService';

interface EconsultCaseActionItemProps {
  icon?: any;
  activeIcon?: any;
  text?: string | null;
  isSubItem?: boolean;
  url?: string;
  active?: boolean;
  count?: number;
}

const CaseActionText = styled(
  ({
    isActive,
    primary,
    ...otherProps
  }: {
    isActive: boolean | undefined;
    primary?: string | null;
  }) => <ListItemText primary={primary} {...otherProps} />
)<{ isActive: boolean | undefined }>(({ theme, isActive }) => ({
  '& .MuiTypography-root': {
    fontSize: theme.typography.body2.fontSize,
    color: isActive ? theme.palette.primary.custom3 : theme.palette.base.grey4
  }
}));
const CaseActionButton = styled(ListItemButton)({
  padding: '0.3rem',
  '&:hover': {
    backgroundColor: 'transparent'
  }
});
const CaseActionSubMenuButton = styled(CaseActionButton)({
  paddingLeft: '1.5rem'
});
const CaseActionIcon = styled(ListItemIcon)({
  minWidth: '2rem'
});

const EconsultCaseActionItem: FC<EconsultCaseActionItemProps> = ({
  icon: Icon = null,
  activeIcon: ActiveIcon = null,
  text,
  isSubItem,
  url,
  active,
  count
}) => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isCaseCompleteDialogOpen, setIsCaseCompleteDialogOpen] = useState<boolean>(false);
  const CaseButton = isSubItem ? CaseActionSubMenuButton : CaseActionButton;
  const { caseDetails, setCaseDetails, userRole } = useEconsultCaseDetailsContext();
  const { user } = useAuth();

  useEffect(() => {
    setIsActive(active || false);
  }, [active]);

  const navigateToOption = () => {
    if (url) {
      setCaseDetails(undefined);
      navigate(url);
    }
  };

  const onCompleteDialogCancelled = () => {
    setIsCaseCompleteDialogOpen(false);
    navigateToOption();
  };

  const onCompleteDialogCompleted = async () => {
    setIsCaseCompleteDialogOpen(false);
    if (!caseDetails) return;

    const draftFinishNote = caseDetails?.notes?.find(
      (n) => n.statusCd === NoteStatusCodes.draft && n.actionTypeCd === NoteActionTypes.finish
    );
    if (!draftFinishNote) {
      const newNote: CaseNote = {
        actionTypeCd: NoteActionTypes.finish,
        caseId: caseDetails?.caseId,
        noteTypeCd: NoteTypes.referrer,
        ownerId: user?.userid,
        statusCd: NoteStatusCodes.draft
      };
      await CaseService.upsertCaseNote(newNote);
      setCaseDetails({
        ...caseDetails,
        notes: [...(caseDetails.notes ? caseDetails.notes : []), newNote]
      });
    }
  };

  const handleOnClick = () => {
    if (
      caseDetails &&
      userRole &&
      EconsultUserService.isReferrerResolveCompletedConsultAction(caseDetails, userRole)
    ) {
      setIsCaseCompleteDialogOpen(true);
    } else {
      navigateToOption();
    }
  };
  return (
    <>
      <CaseButton
        onMouseEnter={() => setIsActive(true)}
        onMouseLeave={() => setIsActive(active || false)}
        onClick={handleOnClick}>
        <CaseActionIcon>
          {isActive || active ? ActiveIcon ? <ActiveIcon /> : null : Icon ? <Icon /> : null}
        </CaseActionIcon>
        <CaseActionText
          isActive={isActive || active}
          primary={count && count > 0 ? `${text} (${count})` : text}
        />
      </CaseButton>
      <EconsultCompleteCaseDialog
        isOpen={isCaseCompleteDialogOpen}
        onClose={onCompleteDialogCancelled}
        onComplete={onCompleteDialogCompleted}
      />
    </>
  );
};

export default EconsultCaseActionItem;
