import { MenuItem, Select, SelectChangeEvent, styled, Typography } from '@mui/material';
import React, { FC } from 'react';

const SelectContainer = styled(Select)({
  height: 25,
  width: '100%'
});

interface EconsultProgramSelectorProps {
  options: string[];
  onSelected: (programName: string) => void;
  selectedProgramName: string;
}

const EconsultProgramSelector: FC<EconsultProgramSelectorProps> = ({
  options,
  onSelected,
  selectedProgramName
}) => {
  const handleChange = (e: SelectChangeEvent<unknown>) => {
    onSelected(e.target.value as string);
  };
  return (
    <SelectContainer value={selectedProgramName} onChange={handleChange}>
      <MenuItem value="0">
        <Typography variant="body2">All Groups</Typography>
      </MenuItem>
      {options.map((o, i) => (
        <MenuItem value={o} key={i}>
          <Typography variant="body2">{o}</Typography>
        </MenuItem>
      ))}
    </SelectContainer>
  );
};

export default EconsultProgramSelector;
