import React, { FC, PropsWithChildren, useContext, useState, createContext, useMemo } from 'react';

export type NotificationType = 'error' | 'warning' | 'info' | 'success';

export type Notification = {
  id?: number;
  type: NotificationType;
  message: string;
  solution?: string;
  details?: string;
  duration?: number;
};
type NotifierContextProps = {
  notifications: Notification[];
  addNotification: (notification: Notification) => number;
  removeNotification: (id: number) => void;
};
const NotifierContext = createContext<NotifierContextProps | undefined>(undefined);

export const useNotifierContext = () => {
  const value = useContext(NotifierContext);
  if (!value) {
    throw new Error('no value found for NotifierContext, did you forget to wrap it in a provider?');
  }
  return value;
};

export const NotifierContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const addNotification = (notification: Notification) => {
    // TODO find better way to gen rundom number id
    const addedNotification = {
      ...notification,
      id: notification.id || Date.now(),
      duration: notification.type === 'success' ? 5000 : undefined
    };

    setNotifications((prevNotifications) => {
      const isDuplicate = prevNotifications.some(
        (existingNotification) => existingNotification.id === addedNotification.id
      );

      if (!isDuplicate) {
        return [...prevNotifications, addedNotification];
      }

      return prevNotifications;
    });
    return addedNotification.id;
  };

  const removeNotification = (id: number) => {
    setNotifications(notifications.filter((n) => n.id !== id));
  };
  const value = useMemo(() => {
    return {
      notifications,
      addNotification,
      removeNotification
    };
  }, [notifications]);
  return <NotifierContext.Provider value={value}>{children}</NotifierContext.Provider>;
};
