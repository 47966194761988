import { Box, styled, Typography } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { FileWithProgress } from '@oh-vcp/components-common';
import EconsultAttachmentFileItem from '../econsultAttachmentFileItem/EconsultAttachmentFileItem';
import CaseService from '../services/caseService';

const DragAndDropArea = styled(Box)(({ theme }) => ({
  border: '2px dashed',
  borderColor: theme.palette.base.grey5,
  padding: '40px',
  textAlign: 'center',
  backgroundColor: '#ffffff',
  minHeight: 100
}));

export interface EconsultDragAndDropRef {
  attachFile: () => void;
}

interface EconsultDragAndDropProps {
  onFileUploaded?: (fileId: number, fileName: string) => void;
  onFileDeleted?: (fileName: string) => void;
}

const EconsultDragAndDrop = forwardRef<EconsultDragAndDropRef, EconsultDragAndDropProps>(
  (props, ref) => {
    const [uploadedFiles, setUploadedFiles] = useState<FileWithProgress[]>([]);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const { onFileUploaded, onFileDeleted } = props;

    useImperativeHandle(ref, () => ({
      attachFile() {
        if (fileInputRef && fileInputRef.current) {
          fileInputRef.current.click();
        }
      }
    }));

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const handleFileUpload = async (files: FileWithProgress[]) => {
      const uploadPromises = files.map(async (file) => {
        const fileInfo = await CaseService.uploadFile(file.file, (progress: number) => {
          setUploadedFiles((prevFiles) =>
            prevFiles.map((prevFile) =>
              prevFile.file.name === file.file.name ? { ...prevFile, progress } : prevFile
            )
          );
        });
        return fileInfo;
      });
      const responses = await Promise.all(uploadPromises);
      if (onFileUploaded) {
        responses.forEach((response) => {
          onFileUploaded(response.id, response.fileName);
        });
      }
    };

    const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const files: FileWithProgress[] = Array.from(event.dataTransfer.files).map((file) => ({
        file: {
          ...file,
          name: file.name.replace(',', '')
        },
        progress: 0
      }));
      setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
      await handleFileUpload(files);
    };

    const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const files = Array.from(event.target.files).map((file) => ({
          file: {
            ...file,
            name: file.name.replace(',', '')
          },
          progress: 0
        }));
        setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
        await handleFileUpload(files);
      }
    };

    const handleFileDelete = (fileName: string) => {
      setUploadedFiles((prevFiles) => prevFiles.filter((f) => f.file.name !== fileName));
      if (fileInputRef && fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      if (onFileDeleted) {
        onFileDeleted(fileName);
      }
    };

    return (
      <DragAndDropArea onDragOver={handleDragOver} onDrop={handleDrop}>
        <input
          ref={fileInputRef}
          type="file"
          multiple
          onChange={handleFileSelect}
          style={{
            display: 'none'
          }}
          id="fileUpload"
        />
        {uploadedFiles.map((file, index) => (
          <EconsultAttachmentFileItem key={index} file={file} onDelete={handleFileDelete} />
        ))}
        <Typography sx={{ fontSize: 20, color: 'base.grey4' }}>Drag and drop files here</Typography>
        {uploadedFiles && uploadedFiles.length > 0 && (
          <Typography variant="body4" sx={{ color: 'base.grey4' }}>
            Please ensure that the recipient has appropriate viewers for the attached files.
          </Typography>
        )}
      </DragAndDropArea>
    );
  }
);

export default EconsultDragAndDrop;
