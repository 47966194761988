import { Dialog, DialogTitle, Typography, DialogContent, Button, Grid } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EconsultCancelButton } from '@oh-vcp/components-ui';
import { useAuth } from '../auth/AuthContext';

interface EconsultExportEmrDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const EconsultExportEmrDialog: FC<EconsultExportEmrDialogProps> = ({
  isOpen,
  onClose,
  onConfirm
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        <Typography variant="h3" color="base.grey3">
          {t('Case.emr.doYouWantExportToEmr')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="base.grey3" mb={3}>
          {t('Case.emr.content1')}
        </Typography>
        {user && user.organization && (
          <Typography variant="body1" color="base.grey3" component="i">
            {t('Case.emr.content2')} {user.organization} {t('Case.emr.content3')}
          </Typography>
        )}
        <Grid container item xs={12} justifyContent="flex-end" pt={3}>
          <Button variant="contained" color="primary" sx={{ mr: 1 }} onClick={onConfirm}>
            {t('Case.button.confirm')}
          </Button>
          <EconsultCancelButton variant="contained" onClick={onClose}>
            {t('Case.button.cancel')}
          </EconsultCancelButton>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EconsultExportEmrDialog;
