import { Grid, styled, Typography } from '@mui/material';
import { Referrer } from '@oh-vcp/components-common/src/models/case/CaseDetails';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const RequesterContainer = styled(Grid)(({ theme }) => ({
  borderColor: theme.palette.base.grey5,
  borderStyle: 'solid',
  borderWidth: 1,
  padding: 5,
  paddingLeft: 10,
  width: '75%'
}));

interface EconsultRequesterDetailsProps {
  referrer: Referrer;
}

const EconsultRequesterDetails: FC<EconsultRequesterDetailsProps> = ({ referrer }) => {
  const { t } = useTranslation();
  const { address, specialty, phoneNumber, ohipBillingNo } = referrer;
  return (
    <RequesterContainer container rowGap={1}>
      <Grid item xs={12} md={5}>
        <Typography variant="body2" color="base.grey4">
          {t('Case.requester.address')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Typography variant="body2" color="base.grey3">
          {address}
        </Typography>
      </Grid>
      <Grid item xs={12} md={5}>
        <Typography variant="body2" color="base.grey4">
          {t('Case.requester.specialty')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Typography variant="body2" color="base.grey3">
          {specialty}
        </Typography>
      </Grid>
      <Grid item xs={12} md={5}>
        <Typography variant="body2" color="base.grey4">
          {t('Case.requester.phone')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Typography variant="body2" color="base.grey3">
          {phoneNumber}
        </Typography>
      </Grid>
      <Grid item xs={12} md={5}>
        <Typography variant="body2" color="base.grey4">
          {t('Case.requester.billingCode')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Typography variant="body2" color="base.grey3">
          {ohipBillingNo}
        </Typography>
      </Grid>
    </RequesterContainer>
  );
};

export default EconsultRequesterDetails;
