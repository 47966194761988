import { Box, Button, Stack, styled } from '@mui/material';
import { PaperClipIcon } from '@oh-vcp/components-ui';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface EconsultDraftCaseActionsProps {
  onSaveDraft?: () => void;
  onDeleteDraft?: () => void;
  onSend?: () => void;
  onAttach?: () => void;
  getSaveDraftDisabled?: () => boolean;
  disableActionButtons?: boolean;
}

const CaseActionButton = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  '&:hover': {
    backgroundColor: '#77B800'
  }
}));

const CaseSaveDraftButton = styled(CaseActionButton)(() => ({
  backgroundColor: 'transparent',
  color: '#a9a8a9',
  '&:hover': {
    backgroundColor: 'transparent',
    color: '#77B800'
  }
}));

const EconsultDraftCaseActions: FC<EconsultDraftCaseActionsProps> = ({
  onSaveDraft,
  onDeleteDraft,
  onSend,
  onAttach,
  getSaveDraftDisabled,
  disableActionButtons
}) => {
  const [saved, setSaved] = useState<boolean | false>(false);
  const { t } = useTranslation();
  const draftSave = async () => {
    if (onSaveDraft) {
      onSaveDraft();
    }
    setSaved(true);
  };
  return (
    <Stack direction="row" justifyContent="space-between">
      <Box onClick={onAttach}>
        <img
          draggable={false}
          src={PaperClipIcon}
          alt="Attach files"
          style={{ height: 30, cursor: 'pointer' }}
        />
      </Box>
      <Stack direction="row" spacing={1} justifyContent="end">
        {onSaveDraft && (!getSaveDraftDisabled || !getSaveDraftDisabled()) && (
          <CaseSaveDraftButton variant="contained" onClick={draftSave}>
            {saved ? t('Case.draft.draftSaved') : t('Case.draft.saveAsDraft')}
          </CaseSaveDraftButton>
        )}
        {onSaveDraft && getSaveDraftDisabled && getSaveDraftDisabled() && (
          <CaseActionButton variant="contained" onClick={draftSave} disabled>
            {saved ? t('Case.draft.draftSaved') : t('Case.draft.saveAsDraft')}
          </CaseActionButton>
        )}
        {onDeleteDraft && (
          <CaseActionButton
            variant="contained"
            color="primary"
            onClick={onDeleteDraft}
            disabled={disableActionButtons}>
            {t('Case.draft.deleteDraft')}
          </CaseActionButton>
        )}
        {onSend && (
          <CaseActionButton
            variant="contained"
            color="primary"
            onClick={onSend}
            disabled={disableActionButtons}>
            {t('Case.draft.send')}
          </CaseActionButton>
        )}
      </Stack>
    </Stack>
  );
};

export default EconsultDraftCaseActions;
