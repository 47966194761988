import { EconsultPreferenceItem } from '../types/EconsultPreferenceItem';
import { EconsultUnavailabilityDate } from '../types/EconsultUnavailabilityDate';
import { Region } from '../types/Region';

const todayDate = () => {
  const today = new Date().setHours(0, 0, 0, 0);
  return new Date(today);
};

const parseUnavailabilityDate = (date: string) => {
  return new Date(`${date}T00:00:00`);
};

const isPastDate = (date: string) => {
  if (date && parseUnavailabilityDate(date) < todayDate()) {
    return true;
  }
  return false;
};

const isPastUnavailability = (startDate: string, endDate: string) => {
  let isPast = false;
  if (startDate && isPastDate(endDate)) {
    isPast = true;
  }
  return isPast;
};

const dateValidate = (dateStr: string) => {
  if (!dateStr || Number.isNaN(Date.parse(dateStr))) {
    return false;
  }
  return true;
};

const getTimeInMsRounded = (dateObject: Date) => {
  const time = dateObject.getTime();
  const rounded = Math.round(time / 1000) * 1000;
  return rounded;
};

const isOutOfOffice = (outOfOfficeStart: string | null, outOfOfficeEnd: string | null) => {
  if (outOfOfficeStart && outOfOfficeEnd) {
    const currentDate = new Date();
    const endTime = new Date(outOfOfficeEnd);
    const startTime = new Date(outOfOfficeStart);
    if (currentDate >= startTime && currentDate <= endTime) {
      return true;
    }
  }
  return false;
};

const isOutOfOfficeWithinNDays = (
  outOfOfficeStart: string | null,
  outOfOfficeEnd: string | null,
  numDays: number
) => {
  if (!outOfOfficeStart && !outOfOfficeEnd) {
    return false;
  }
  const msInDay = 24 * 60 * 60 * 1000;
  const currentDate = new Date();
  const startTime = new Date(outOfOfficeStart as string);
  const endTime = new Date(outOfOfficeEnd as string);

  if (getTimeInMsRounded(endTime) < getTimeInMsRounded(currentDate)) {
    return false;
  }
  if (getTimeInMsRounded(startTime) > getTimeInMsRounded(currentDate) + numDays * msInDay) {
    return false;
  }
  return true;
};

const createNewUnavailabilityforEconAction = (
  startDate: string,
  endDate: string
): EconsultUnavailabilityDate => {
  const isValid = dateValidate(startDate) && dateValidate(endDate);
  const isPast = isValid ? isPastUnavailability(startDate, endDate) : true;
  let startDateStr;
  if (isValid) {
    if (startDate.length === 10) {
      startDateStr = `${startDate} 00:00:00`;
    } else {
      startDateStr = startDate;
    }
  } else {
    startDateStr = null;
  }
  let endDateStr;
  if (isValid) {
    if (endDate.length === 10) {
      endDateStr = `${endDate} 23:59:59`;
    } else {
      endDateStr = endDate;
    }
  } else {
    endDateStr = null;
  }
  return {
    isPast,
    isValid,
    startDateTime: startDateStr,
    endDateTime: endDateStr,
    startDate,
    endDate
  };
};

const transformUnavailabilityDatesFromMTforEconAction = (unavailabilityDatesMT?: string) => {
  const unavailabilityDates: EconsultUnavailabilityDate[] = [];
  if (!unavailabilityDatesMT) {
    return unavailabilityDates;
  }
  const unavailabilityDatesAsStringArray = unavailabilityDatesMT.split('||');
  if (unavailabilityDatesAsStringArray && unavailabilityDatesAsStringArray.length > 0) {
    unavailabilityDatesAsStringArray.forEach((unavailabilityDatesAsString: string) => {
      const unavailabilityStartAndEndDates = unavailabilityDatesAsString.split('|');
      if (unavailabilityStartAndEndDates && unavailabilityStartAndEndDates.length === 2) {
        unavailabilityDates.push(
          createNewUnavailabilityforEconAction(
            unavailabilityStartAndEndDates[0],
            unavailabilityStartAndEndDates[1]
          )
        );
      }
    });
  }
  return unavailabilityDates;
};

const getOutOfOfficeDateInAWeek = (
  unavailabilityDates?: string
): EconsultUnavailabilityDate | null => {
  const outOfOfficeDates = transformUnavailabilityDatesFromMTforEconAction(unavailabilityDates);
  let nextWeekOOODate = null;
  let ret = false;
  outOfOfficeDates.forEach((unavailabilityDate: EconsultUnavailabilityDate) => {
    if (unavailabilityDate.isValid && !unavailabilityDate.isPast && !ret) {
      ret = isOutOfOfficeWithinNDays(
        unavailabilityDate.startDateTime,
        unavailabilityDate.endDateTime,
        7
      );
      if (ret) {
        nextWeekOOODate = unavailabilityDate;
      }
    }
  });
  return nextWeekOOODate;
};

const getCurrentOutOfOfficeDate = (
  unavailabilityDates?: string
): EconsultUnavailabilityDate | null => {
  const outOfOfficeDates = transformUnavailabilityDatesFromMTforEconAction(unavailabilityDates);
  if (!outOfOfficeDates || outOfOfficeDates.length === 0) {
    return null;
  }
  let currentOOODate = null;
  let outOfOffice = false;
  outOfOfficeDates.forEach((unavailabilityDate) => {
    if (unavailabilityDate.isValid && !unavailabilityDate.isPast && !outOfOffice) {
      outOfOffice = isOutOfOffice(unavailabilityDate.startDateTime, unavailabilityDate.endDateTime);
      if (outOfOffice) {
        currentOOODate = unavailabilityDate;
      }
    }
  });
  return currentOOODate;
};

const getCurrentOutOfOfficeDateStr = (outOfOfficeDates: any[]) => {
  if (!outOfOfficeDates || outOfOfficeDates.length === 0) {
    return null;
  }
  let currentOOODate = null;
  let outOfOffice = false;
  outOfOfficeDates.forEach((unavailabilityDate: any) => {
    if (unavailabilityDate.isValid && !unavailabilityDate.isPast && !outOfOffice) {
      outOfOffice = isOutOfOffice(unavailabilityDate.startDateTime, unavailabilityDate.endDateTime);
      if (outOfOffice) {
        currentOOODate = unavailabilityDate.endDate;
      }
    }
  });
  return currentOOODate;
};

const getOutOfOfficeEndDate = (unavailability: string) => {
  if (!unavailability) {
    return null;
  }
  const outOfOfficeDates = transformUnavailabilityDatesFromMTforEconAction(unavailability);
  return getCurrentOutOfOfficeDateStr(outOfOfficeDates);
};

const getIsPriorityRegionNoteShown = (
  programRegion?: Region,
  econsultPreferences?: EconsultPreferenceItem[]
) => {
  if (programRegion && econsultPreferences) {
    const priorityRegions = econsultPreferences.find((e) => e.name === 'PRIORITY_REGION');
    if (priorityRegions) {
      const priorityRegionsArr = priorityRegions.value.split('||');
      if (
        !programRegion.isProvincial &&
        !priorityRegionsArr.find((p) => p === programRegion.id.toString())
      ) {
        return true;
      }
    }
  }
  return false;
};

const getFlagColor = (flagCode: string) => {
  switch (flagCode) {
    case 'OTHER':
      return 'primary.custom3';
    case 'RESEARCH':
      return 'primary.custom5';
    case 'NONE':
      return 'base.grey4';
    case 'EDUCATION':
    default:
      return 'primary.main';
  }
};

const EconsultUtils = {
  getOutOfOfficeDateInAWeek,
  getCurrentOutOfOfficeDate,
  getIsPriorityRegionNoteShown,
  getFlagColor,
  getOutOfOfficeEndDate
};

export default EconsultUtils;
