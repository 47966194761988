import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  Stack,
  Typography,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  styled,
  Tooltip,
  useTheme,
  Button,
  Select,
  MenuItem,
  tooltipClasses,
  TooltipProps,
  TextareaAutosize
} from '@mui/material';
import { useEconsultCaseDetailsContext } from '@oh-vcp/components-web/src/econsultCaseDetailsContext/EconsultCaseDetailsContext';
import {
  AppConfig,
  CaseAttachment,
  CaseDetails,
  DraftNoteAttachment,
  EconsultManagedSpecialtyProgram,
  EconsultManagedSpecialtyProgramRegion,
  EconsultRestrictionNoteSection,
  EconsultUnavailabilityDate,
  EconsultUtils,
  NameUtils,
  NoteActionTypes,
  NoteStatusCodes,
  NoteTypes,
  UserDTO
} from '@oh-vcp/components-common';
import {
  DragDropFileUpload,
  EconsultAttachmentListing,
  EconsultCasePatientForm,
  EconsultCaseSpecialtySection,
  EconsultDeleteDraftDialog,
  EconsultDraftCaseActions,
  EconsultDragAndDrop,
  EconsultOutOfOfficeDialog,
  EconsultPriorityRegionDialog,
  EconsultRestrictionNote,
  EconsultSpecialistSearch,
  NotificationFactory,
  NotificationsConfig,
  useAuth,
  useConsultantService
} from '@oh-vcp/components-web';
import CaseService from '@oh-vcp/components-web/src/services/caseService';
import {
  OhipValidationService,
  RedirectService,
  RequiredFieldTypographyWithInfo,
  useNotifierContext,
  RequiredFieldTypography
} from '@oh-vcp/components-ui';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useManagedSpecialtyProgramLookup from '../../api/useManagedSpecialtyProgramLookup';
import useProgramLookup from '../../api/useProgramLookup';
import ValidatorUtils from '@oh-vcp/components-web/src/econsultBaseValidator/EconsultBaseValidator';
import { ValidationTypes } from '@oh-vcp/components-web/src/econsultBaseValidator/EconsultValidationTypes';
import { ValidationResult } from '@oh-vcp/components-web/src/econsultBaseValidator/ValidationResult';
import { EconsultSpecialistSearchRef } from '@oh-vcp/components-web/src/econsultSpecialistSearch/EconsultSpecialistSearch';
import { EconsultDragAndDropRef } from '@oh-vcp/components-web/src/econsultDragAndDrop/EconsultDragAndDrop';
import { Referrer } from '@oh-vcp/components-common/src/models/case/CaseDetails';
import { useQueryClient } from 'react-query';

const DraftDetailsSection = styled(Grid)(({ theme }) => ({
  borderBottomColor: theme.palette.base.grey6,
  borderBottomStyle: 'solid',
  borderBottomWidth: 2,
  paddingTop: 10,
  paddingBottom: 10
}));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}));

const StyledTextAreaAutoResize = styled(TextareaAutosize)(({ theme }) => ({
  '&::placeholder': {
    fontSize: '0.875rem',
    color: theme.palette.base.grey5
  }
}));

interface EconsultCasePatientFormHandle {
  validate: () => boolean;
}

const EconsultCaseDraftDetailsView = () => {
  const { caseDetails, setCaseDetails } = useEconsultCaseDetailsContext();
  const { draftCaseSaveDisable, setDraftCaseSaveDisable } = useEconsultCaseDetailsContext();
  const [disableActionButtons, setDisableActionButtons] = useState<boolean>(false);
  const { addNotification } = useNotifierContext();
  const { data: consultantsData } = useConsultantService();
  const [caseAttachments, setCaseAttachments] = useState<DraftNoteAttachment[]>([]);
  const [isDeleteDraftDialogOpen, setIsDeleteDraftDialogOpen] = useState<boolean>(false);
  const [regionList, setRegionList] = useState<EconsultManagedSpecialtyProgramRegion[] | undefined>(
    undefined
  );
  const [selectedRegionId, setSelectedRegionId] = useState<number | undefined>(undefined);
  const [selectedProgramId, setSelectedProgamId] = useState<number | undefined>(undefined);
  const [specialtyRestriction, setSpecialtyRestrictions] = useState<
    EconsultRestrictionNoteSection[] | []
  >([]);
  const [programRestrictions, setProgramRestrictions] = useState<
    EconsultRestrictionNoteSection[] | []
  >([]);
  const [isRegionSelectShown, setIsRegionSelectShown] = useState<boolean>(false);
  const [isPriorityRegionDialogOpen, setIsPriorityRegionDialogOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<(UserDTO | EconsultManagedSpecialtyProgram)[]>([]);
  const [outOfOfficeConsultant, setOutOfOfficeConsultant] = useState<UserDTO | undefined>(
    undefined
  );
  const [consultOutOfOfficeDate, setConsultOutOfOfficeDate] = useState<
    EconsultUnavailabilityDate | null | undefined
  >(undefined);
  const theme = useTheme();
  const { t } = useTranslation();
  const { caseId } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { data: managedPrograms } = useManagedSpecialtyProgramLookup(
    user?.userid,
    caseDetails?.specialty?.code,
    caseDetails?.subSpecialty?.subCode
  );
  const { data: managedProgram } = useProgramLookup(selectedProgramId);
  const specialistClearRef = useRef<EconsultSpecialistSearchRef>(null);
  const attachFileRef = useRef<EconsultDragAndDropRef>(null);
  const queryClient = useQueryClient();

  /* validation configuration */

  let [dirtyFields, setFieldDiryty] = useState<string[]>([]);
  let [invalidFields, setFieldInvalid] = useState<string[]>([]);
  const requiredFields: Map<string, ValidationTypes> = new Map();
  requiredFields.set('requestNte', ValidationTypes.Text);
  const vaildFieldMessageType = {
    display: 'none'
  };
  const inVaildFieldMessageType = {
    display: 'block',
    color: 'red'
  };
  const childRef = useRef<EconsultCasePatientFormHandle>();
  const [requestNteErrorMessageStyle, setRequestNteErrorMessageStyle] =
    useState(vaildFieldMessageType);

  /* end of validation configuration */

  const unSelectedOptFontColor = 'base.grey4';
  const selectedOptFontColor = '#0078C9';
  const [managedSpecialtyFontColor, setManagedSpecialtyFontColor] =
    useState(unSelectedOptFontColor);
  const [specificProviderFontColor, seSpecificProviderFontColor] = useState(unSelectedOptFontColor);

  useEffect(() => {
    if (consultantsData && consultantsData.length > 0) {
      const delegateRequester = consultantsData.find((r) => r.roles?.econCaseCreate);
      if (delegateRequester) {
        setCaseDetails({
          ...caseDetails,
          referrer: delegateRequester as Referrer
        });
      }
    }
  }, [caseDetails, consultantsData, setCaseDetails]);

  useEffect(() => {
    const regions = managedPrograms?.map((program) => {
      return program.region;
    });
    setRegionList(regions);
    setSelectedRegionId(regions?.[0]?.id);
  }, [managedPrograms]);

  useEffect(() => {
    const program = managedPrograms?.find((program) => {
      return program.region.id === selectedRegionId;
    });
    setSelectedProgamId(program?.programId);
  }, [selectedRegionId, managedPrograms]);

  useEffect(() => {
    if (!managedProgram) return;
    let econsultRestrictions: EconsultRestrictionNoteSection = {
      title: 'eConsult Case Conditions',
      properties: []
    };
    let aboutGroupRestrictions: EconsultRestrictionNoteSection = {
      title: 'About the Group',
      properties: [
        {
          name: '',
          value: managedProgram.programDescription
        }
      ]
    };
    if (managedProgram.econCondRegionText) {
      econsultRestrictions.properties.push({
        name: 'Only accepted from LHINs/Regions',
        value: managedProgram.econCondRegionText
      });
    }
    if (managedProgram.econCondition) {
      econsultRestrictions.properties.push({
        name: 'Other conditions',
        value: managedProgram.econCondition
      });
    }
    setProgramRestrictions([econsultRestrictions, aboutGroupRestrictions]);
  }, [managedProgram]);

  useEffect(() => {
    let restrictions: EconsultRestrictionNoteSection[] = [];
    if (caseDetails?.participant?.consultant?.specializedAreaOfPractice) {
      restrictions.push({
        title: 'Telemedicine Service Details',
        properties: [
          {
            name: '',
            value: caseDetails.participant.consultant.specializedAreaOfPractice
          }
        ]
      });
    }
    if (
      caseDetails?.participant?.consultant?.econsult &&
      (caseDetails.participant.consultant.econsult.catchmentArea ||
        caseDetails.participant.consultant.econsult.otherConditions)
    ) {
      let econsultRestriction: EconsultRestrictionNoteSection = {
        title: 'eConsult Case Conditions',
        properties: []
      };
      if (
        caseDetails.participant.consultant.econsult &&
        caseDetails.participant.consultant.econsult.catchmentArea
      ) {
        econsultRestriction.properties.push({
          name: 'Only accepted from LHINs/Regions',
          value: caseDetails.participant.consultant.econsult.catchmentArea
        });
      }
      if (
        caseDetails.participant.consultant.econsult &&
        caseDetails.participant.consultant.econsult.otherConditions
      ) {
        econsultRestriction.properties.push({
          name: 'Other conditions',
          value: caseDetails.participant.consultant.econsult.otherConditions
        });
      }
    }
    setSpecialtyRestrictions(restrictions);
  }, [caseDetails?.participant?.consultant]);

  const handleModelLoad = (value: string) => {
    setManagedSpecialtyFontColor(unSelectedOptFontColor);
    seSpecificProviderFontColor(unSelectedOptFontColor);
    if (value === 'DIRECT') {
      setIsRegionSelectShown(false);
      seSpecificProviderFontColor(selectedOptFontColor);
    } else if (value === 'MANAGED_SPECIALTY_GROUP') {
      setManagedSpecialtyFontColor(selectedOptFontColor);
    }
  };

  useEffect(() => {
    handleModelLoad(caseDetails?.programType || '');
  });

  if (!caseDetails || caseDetails.caseId?.toString() !== caseId) {
    return null;
  }

  const { referrer, programType, notes } = caseDetails;

  const requestTextAreaStyle = {
    width: '100%',
    outline: 'none',
    border: 'none',
    resize: 'none' as 'none',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    paddingTop: 20
  };

  const handleSearchRecipientInputChange = async (inputValue: string) => {
    if (inputValue.length > 2) {
      const specialistsAndGroups = await CaseService.fetchCaseSpecialistsAndGroups(inputValue);
      setOptions(specialistsAndGroups);
    } else {
      setOptions([]);
    }
  };
  const isUserDTO = (obj: unknown): obj is UserDTO => {
    return typeof obj === 'object' && obj !== null && ('userid' in obj || 'userId' in obj);
  };
  const isEconsultManagedSpecialtyProgram = (
    obj: unknown
  ): obj is EconsultManagedSpecialtyProgram => {
    return typeof obj === 'object' && obj !== null && 'programId' in obj && !('userId' in obj);
  };
  const updateConsultant = (consultant: UserDTO | EconsultManagedSpecialtyProgram | null) => {
    const updatedCaseDetails = {
      ...caseDetails,
      participant: {
        ...caseDetails.participant,
        consultant:
          consultant && isUserDTO(consultant) ? consultant : caseDetails?.participant?.consultant,
        program:
          consultant && isEconsultManagedSpecialtyProgram(consultant)
            ? consultant
            : caseDetails?.participant?.program
      }
    };
    setCaseDetails(updatedCaseDetails);
  };
  const handleConsultantChanged = (
    consultant: UserDTO | EconsultManagedSpecialtyProgram | null
  ) => {
    if (isUserDTO(consultant)) {
      const consultantUser = consultant as UserDTO;
      if (consultantUser?.unavailability) {
        const unavailableDate = EconsultUtils.getOutOfOfficeDateInAWeek(
          consultantUser.unavailability
        );
        if (unavailableDate) {
          setOutOfOfficeConsultant(consultant);
          setConsultOutOfOfficeDate(unavailableDate);
          return;
        }
      }
    }
    updateConsultant(consultant);
  };
  const handleOutOfOfficeContinue = () => {
    if (outOfOfficeConsultant) {
      updateConsultant(outOfOfficeConsultant);
      setOutOfOfficeConsultant(undefined);
      setConsultOutOfOfficeDate(undefined);
    }
  };
  const handleOutOfOfficeCancel = () => {
    setOutOfOfficeConsultant(undefined);
    setConsultOutOfOfficeDate(undefined);
    specialistClearRef.current?.clear();
  };
  const handleProgramTypeChanged = async (event: ChangeEvent<HTMLInputElement>, value: string) => {
    if (!value) {
      return;
    }
    const updatedCaseDetails = {
      ...caseDetails,
      programType: value
    };
    setManagedSpecialtyFontColor(unSelectedOptFontColor);
    seSpecificProviderFontColor(unSelectedOptFontColor);
    if (value === 'DIRECT') {
      delete updatedCaseDetails?.specialty;
      delete updatedCaseDetails?.subSpecialty;
      setIsRegionSelectShown(false);
      seSpecificProviderFontColor(selectedOptFontColor);
    } else if (value === 'MANAGED_SPECIALTY_GROUP') {
      delete updatedCaseDetails?.participant?.consultant;
      setManagedSpecialtyFontColor(selectedOptFontColor);
      if (!user?.seenPriorityRegion) {
        setIsPriorityRegionDialogOpen(true);
      }
    }
    setCaseDetails(updatedCaseDetails);
    await CaseService.upsertCaseDetails(updatedCaseDetails);
  };

  const handleNoteContentChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    const { name } = event.target;
    const fieldName = { name }.name;
    const res: ValidationResult = ValidatorUtils.handleValidation(
      requiredFields,
      dirtyFields,
      invalidFields,
      fieldName,
      value
    );
    setFieldDiryty(res.dirtyFields);
    setFieldInvalid(res.invalidFields);

    const valid = res.isValid;

    if (valid) {
      setRequestNteErrorMessageStyle(vaildFieldMessageType);
    } else {
      setRequestNteErrorMessageStyle(inVaildFieldMessageType);
    }

    const updatedCaseDetails = {
      ...caseDetails,
      notes: [
        ...(caseDetails.notes
          ? [
              {
                ...caseDetails.notes[0],
                noteContent: value
              }
            ]
          : []),
        ...(caseDetails.notes ? caseDetails.notes.slice(1) : [])
      ]
    };
    setCaseDetails(updatedCaseDetails);
  };
  const handleDraftSaved = async () => {
    setDisableActionButtons(true);
    await CaseService.upsertCaseDetails(caseDetails);
    setTimeout(() => {
      queryClient.invalidateQueries(['caseResults']);
    }, 500);
    addNotification(
      NotificationFactory.createNotification(
        'success',
        NotificationsConfig.econsultSaveDraft.success
      )
    );
    setDisableActionButtons(false);
  };

  const getSaveDraftDisabled = () => {
    return draftCaseSaveDisable;
  };

  const handleAddNoteAttachment = async (fileId: number, fileName: string) => {
    setCaseAttachments((prevAttachments) => [
      ...prevAttachments,
      {
        fileId: fileId,
        statusCd: NoteStatusCodes.active,
        fileName: fileName
      }
    ]);
    addNotification({
      type: 'success',
      message: t('Case.draft.fileAddedSuccessfully')
    });
  };
  const handleAttachmentDeleted = async (fileName: string) => {
    setCaseAttachments((prevFiles) => prevFiles.filter((f) => f.fileName !== fileName));
  };

  const isPatientFormValid = (): boolean => {
    let isPatientDetailsValid: boolean = true;

    if (
      !OhipValidationService.isOhipNumberValid(caseDetails?.patient?.ohipNumber) &&
      !caseDetails?.patient?.noOhip
    ) {
      isPatientDetailsValid = false;
    }
    if (childRef.current) {
      const isPatientValid = childRef.current.validate();
      isPatientDetailsValid = isPatientValid;
    }
    const isRequestNteValid = caseDetails.notes?.[0].noteContent;

    if (isRequestNteValid) {
      setRequestNteErrorMessageStyle(vaildFieldMessageType);
    } else {
      setRequestNteErrorMessageStyle(inVaildFieldMessageType);
      isPatientDetailsValid = false;
    }

    setFieldInvalid(invalidFields);
    return isPatientDetailsValid;
  };
  const handleDraftDeleteConfirm = async () => {
    const updatedCaseDetails: CaseDetails = {
      ...caseDetails,
      statusCd: NoteStatusCodes.deleted
    };
    setCaseDetails(updatedCaseDetails);
    await CaseService.upsertCaseDetails(updatedCaseDetails);
    addNotification(
      NotificationFactory.createNotification(
        'success',
        NotificationsConfig.econsultDeleteDraft.success
      )
    );
    navigate(`/case/${NoteTypes.referrer}/${NoteActionTypes.draft}`);
  };
  const handleDraftDeleted = () => {
    setIsDeleteDraftDialogOpen(true);
  };

  const handleFileAttach = () => {
    if (attachFileRef && attachFileRef.current) {
      attachFileRef.current.attachFile();
    }
  };

  const handleDraftSend = async () => {
    if (!isPatientFormValid()) {
      return;
    }
    setDisableActionButtons(true);
    const updatedCaseDetails: CaseDetails = {
      ...caseDetails,
      statusCd: NoteStatusCodes.active,
      participant: managedProgram
        ? {
            program: {
              ...managedProgram
            },
            statusCd: NoteStatusCodes.active,
            triageStatusCd: NoteStatusCodes.active
          }
        : {
            participantId: caseDetails?.participant?.participantId || null,
            ...caseDetails.participant,
            statusCd: NoteStatusCodes.active
          },
      notes: [
        ...(caseDetails.notes
          ? [
              {
                ...caseDetails.notes[0],
                ownerId: user?.userid,
                statusCd: NoteStatusCodes.active,
                noteTypeCd: NoteTypes.referrer,
                actionTypeCd: NoteActionTypes.send,
                attachments: caseAttachments
                  ? caseAttachments.map((c) => ({
                      fileId: c.fileId,
                      statusCd: c.statusCd
                    }))
                  : []
              }
            ]
          : []),
        ...(caseDetails.notes ? caseDetails.notes.slice(1) : [])
      ]
    };
    setCaseDetails(updatedCaseDetails);
    await CaseService.upsertCaseDetails(updatedCaseDetails);
    addNotification(
      NotificationFactory.createNotification(
        'success',
        NotificationsConfig.econsultDraftConsult.success
      )
    );
    setDisableActionButtons(false);
    navigate(`/case/${NoteTypes.referrer}/${NoteActionTypes.draft}`);
  };
  const handleViewProgram = () => {
    if (selectedProgramId) {
      RedirectService.redirectToProgramProfile(selectedProgramId.toString());
    }
  };
  const handleViewPeopleProfile = () => {
    if (caseDetails?.participant?.consultant?.userId) {
      RedirectService.redirectToDirectoryPeopleProfile(
        caseDetails.participant.consultant.userId.toString()
      );
    } else if (caseDetails?.participant?.program?.programId) {
      RedirectService.redirectToProgramProfile(caseDetails?.participant?.program?.programId);
    }
  };
  const handleSkipPriorityRegion = () => {
    setIsPriorityRegionDialogOpen(false);
  };
  const handleSetPriorityRegion = () => {
    window.open(
      `${AppConfig.baseSelfServeUrl}/#/serviceSettings/econsult`,
      '_blank',
      'noopener,noreferrer'
    );
  };

  return (
    <>
      <Stack direction="column" p={4}>
        <Grid container spacing={0}>
          <DraftDetailsSection item xs={12} md={2}>
            <Typography variant="body2" color="base.grey4">
              {t('Case.draft.details.view.requester')}
            </Typography>
          </DraftDetailsSection>
          <DraftDetailsSection item xs={12} md={10}>
            <Typography variant="body2" color="primary.main" mb={2}>
              {referrer &&
                NameUtils.makeName(
                  referrer.lastName || '',
                  referrer.firstName,
                  referrer.salutation,
                  referrer.specialty,
                  null,
                  null
                )}
            </Typography>
          </DraftDetailsSection>
          <DraftDetailsSection item xs={12} md={2}>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <b>BASE Managed Specialty:</b>
                  This option enables you to submit cases to either a regional (where available) or
                  provincial managed specialty group, which is a group of specialists responding to
                  eConsult cases for a given specialty or sub-specialty (e.g. pediatric cardiology).
                  The case is then assigned based on specialist availability.'
                  <br />
                  <br />
                  <b>Specific Provider or Group: </b> This option allows you to submit cases to
                  specific consultants by name or to organizational or regional groups (ex. UHN\'s
                  SCOPE group). Cases are sent directly to the consultant or group.'
                </React.Fragment>
              }>
              <RequiredFieldTypographyWithInfo variant="body2" color="base.grey4" pt={1}>
                {t('Case.detials.model')}
              </RequiredFieldTypographyWithInfo>
            </HtmlTooltip>
          </DraftDetailsSection>
          <DraftDetailsSection item xs={12} md={10}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                onChange={handleProgramTypeChanged}
                value={programType}>
                <FormControlLabel
                  value="MANAGED_SPECIALTY_GROUP"
                  control={
                    <Radio
                      sx={{
                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
                          color: managedSpecialtyFontColor
                        },
                        '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                          color: managedSpecialtyFontColor
                        }
                      }}
                    />
                  }
                  label="BASE Managed Specialty"
                  labelPlacement="end"
                  sx={{
                    color: managedSpecialtyFontColor,
                    fontSize: '0.875rem',
                    '& .MuiTypography-root': {
                      fontSize: '0.875rem'
                    }
                  }}
                />
                <FormControlLabel
                  value="DIRECT"
                  control={
                    <Radio
                      sx={{
                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
                          color: specificProviderFontColor
                        },
                        '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                          color: specificProviderFontColor
                        }
                      }}
                    />
                  }
                  label="Specific Provider or Group"
                  labelPlacement="end"
                  sx={{
                    color: specificProviderFontColor,
                    fontSize: '0.875rem',
                    '& .MuiTypography-root': {
                      fontSize: '0.875rem'
                    }
                  }}
                />
              </RadioGroup>
            </FormControl>
          </DraftDetailsSection>
          {(caseDetails?.programType || caseDetails?.patient) && (
            <>
              {caseDetails?.programType === 'DIRECT' && (
                <>
                  <DraftDetailsSection item xs={12} md={2}>
                    <RequiredFieldTypography variant="body2" color="base.grey4" pt={2}>
                      {t('Case.draft.details.view.recipient')}
                    </RequiredFieldTypography>
                  </DraftDetailsSection>
                  <DraftDetailsSection item xs={12} md={10}>
                    <EconsultSpecialistSearch
                      options={options}
                      onInputChange={handleSearchRecipientInputChange}
                      onSelectionChange={handleConsultantChanged}
                      ref={specialistClearRef}
                    />
                    {caseDetails.participant &&
                      caseDetails.participant.consultant &&
                      ((caseDetails.participant.consultant.econsult &&
                        (caseDetails.participant.consultant.econsult.catchmentArea ||
                          caseDetails.participant.consultant.econsult.otherConditions)) ||
                        caseDetails.participant.consultant.patientEligibility ||
                        caseDetails.participant.consultant.specializedAreaOfPractice) && (
                        <>
                          <EconsultRestrictionNote
                            note={t('Case.consult.specialistRestrictionsNote')}
                            properties={specialtyRestriction}
                          />
                        </>
                      )}
                    {(caseDetails?.participant?.consultant ||
                      caseDetails?.participant?.program) && (
                      <Button variant="text" sx={{ pl: 0 }} onClick={handleViewPeopleProfile}>
                        {t('Case.consult.viewDirectoryProfile')}
                      </Button>
                    )}
                  </DraftDetailsSection>
                </>
              )}
              {caseDetails?.programType === 'MANAGED_SPECIALTY_GROUP' && (
                <>
                  <DraftDetailsSection item xs={12} md={2}>
                    <RequiredFieldTypography variant="body2" color="base.grey4" pt={1}>
                      {t('Case.draft.details.view.speciality')}
                    </RequiredFieldTypography>
                  </DraftDetailsSection>
                  <DraftDetailsSection item xs={12} md={10}>
                    <EconsultCaseSpecialtySection />
                  </DraftDetailsSection>
                  <DraftDetailsSection item xs={12} md={2}>
                    <RequiredFieldTypography variant="body2" color="base.grey4" pt={2}>
                      Region
                    </RequiredFieldTypography>
                  </DraftDetailsSection>
                  <DraftDetailsSection item xs={12} md={10}>
                    <Stack direction="row" alignItems="center">
                      {regionList && regionList.length > 0 && (
                        <>
                          {!isRegionSelectShown ? (
                            <>
                              <Typography>{regionList?.[0]?.name}</Typography>
                              <Button
                                variant="text"
                                sx={{ ml: 3 }}
                                onClick={() => setIsRegionSelectShown(true)}>
                                Change
                              </Button>
                            </>
                          ) : (
                            <Select
                              value={selectedRegionId}
                              onChange={(e) =>
                                setSelectedRegionId(e.target.value as number | undefined)
                              }>
                              {regionList.map((region) => (
                                <MenuItem value={region.id}>{region.name}</MenuItem>
                              ))}
                            </Select>
                          )}
                        </>
                      )}
                    </Stack>
                    {(managedProgram?.econCondRegionText || managedProgram?.econCondition) && (
                      <>
                        <EconsultRestrictionNote
                          note={t('Case.consult.restrictionsNote')}
                          properties={programRestrictions}
                        />
                      </>
                    )}
                    {regionList && regionList.length > 0 && (
                      <Button variant="text" sx={{ pl: 0 }} onClick={handleViewProgram}>
                        {t('Case.consult.viewDirectoryProfile')}
                      </Button>
                    )}
                  </DraftDetailsSection>
                </>
              )}
              <DraftDetailsSection item xs={12} md={2}>
                <Typography variant="body2" color="base.grey4">
                  {t('Case.draft.details.view.patient')}
                </Typography>
              </DraftDetailsSection>
              <DraftDetailsSection item xs={12} md={10}>
                <EconsultCasePatientForm ref={childRef} />
              </DraftDetailsSection>
              <DraftDetailsSection item xs={12} md={2}>
                <RequiredFieldTypography variant="body2" color="base.grey4" pt={2}>
                  {t('Case.draft.details.view.request')}
                </RequiredFieldTypography>
              </DraftDetailsSection>
              <DraftDetailsSection item xs={12} md={10}>
                <StyledTextAreaAutoResize
                  onChange={handleNoteContentChanged}
                  placeholder={t('Case.draft.requestPlaceholder') as string}
                  maxRows={6}
                  value={notes?.[0].noteContent || ''}
                  style={requestTextAreaStyle}
                  name="requestNte"
                />
                <Typography id="requestNteValidationdMessage" style={requestNteErrorMessageStyle}>
                  {t('Case.draft.details.view.request.nte.required')}
                </Typography>
              </DraftDetailsSection>
            </>
          )}
        </Grid>
        {(caseDetails?.programType || caseDetails?.patient) && (
          <>
            <Box mt={3}>
              <EconsultDraftCaseActions
                onSaveDraft={handleDraftSaved}
                onDeleteDraft={handleDraftDeleted}
                onSend={handleDraftSend}
                onAttach={handleFileAttach}
                getSaveDraftDisabled={getSaveDraftDisabled}
                disableActionButtons={disableActionButtons}
              />
            </Box>
            <Box mt={3}>
              <EconsultDragAndDrop
                ref={attachFileRef}
                onFileUploaded={handleAddNoteAttachment}
                onFileDeleted={handleAttachmentDeleted}
              />
            </Box>
          </>
        )}
      </Stack>
      <EconsultDeleteDraftDialog
        isOpen={isDeleteDraftDialogOpen}
        onConfirm={handleDraftDeleteConfirm}
        onCancel={() => setIsDeleteDraftDialogOpen(false)}
      />
      <EconsultOutOfOfficeDialog
        isDialogOpen={Boolean(outOfOfficeConsultant)}
        startDate={consultOutOfOfficeDate?.startDate}
        endDate={consultOutOfOfficeDate?.endDate}
        onCancelClicked={handleOutOfOfficeCancel}
        onContinueClicked={handleOutOfOfficeContinue}
        userName={NameUtils.makeName(
          outOfOfficeConsultant?.lastName || '',
          outOfOfficeConsultant?.firstName,
          null,
          null,
          null,
          null,
          null,
          null
        )}></EconsultOutOfOfficeDialog>
      <EconsultPriorityRegionDialog
        isOpen={isPriorityRegionDialogOpen}
        onClose={handleSkipPriorityRegion}
        onComplete={handleSetPriorityRegion}
      />
    </>
  );
};

export default EconsultCaseDraftDetailsView;
