import { Button, Stack, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EconsultTextField, SearchSVGIcon } from '@oh-vcp/components-ui';
import { useNavigate, useParams } from 'react-router-dom';

const SearchTextField = styled(EconsultTextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: 0
  }
});

const SearchButton = styled(Button)({
  borderRadius: 0
});

const EconsultCaseSearchBox = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { statusType } = useParams();
  const [keyword, setKeyword] = useState<string>('');
  const handleKeywordChanged = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setKeyword(event.target.value);
  };
  useEffect(() => {
    if (statusType && !keyword) {
      setKeyword(statusType);
    }
  }, [statusType]);
  const handleCaseSearch = () => {
    if (keyword && keyword.length > 0) {
      navigate(`/case/search/${keyword}`);
    }
  };
  return (
    <Stack direction="row">
      <SearchTextField
        fullWidth
        type="search"
        placeholder={t('Case.search.searchForCases') as string}
        value={keyword}
        onChange={handleKeywordChanged}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleCaseSearch();
          }
        }}
      />
      <SearchButton variant="contained" onClick={handleCaseSearch}>
        <SearchSVGIcon />
      </SearchButton>
    </Stack>
  );
};

export default EconsultCaseSearchBox;
