import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import {
  EconsultCaseConsultActionDetails,
  EconsultCaseHeader,
  EconsultCaseNotes,
  EconsultUserService,
  useAuth
} from '@oh-vcp/components-web';
import {
  ConsultTab,
  NoteTypes,
  NoteActionTypes,
  EconsultUserRoles,
  NoteStatusCodes,
  EconsultActionTab
} from '@oh-vcp/components-common';
import { useEconsultCaseDetailsContext } from '@oh-vcp/components-web/src/econsultCaseDetailsContext/EconsultCaseDetailsContext';
import useTimeOptions from '../../api/useTimeOptions';
import CaseService from '@oh-vcp/components-web/src/services/caseService';
import { useParams } from 'react-router-dom';
import { useEconsultTabContext } from '@oh-vcp/components-web/src/econsultTabContext/EconsultTabContext';
import { useTranslation } from 'react-i18next';
import {
  AskMoreInfoActiveIcon,
  AskMoreInfoInactiveIcon,
  CancelActiveIcon,
  CancelInactiveIcon,
  CloseCaseActiveIcon,
  CloseCaseInactiveIcon,
  DeclineActiveIcon,
  DeclineInactiveIcon,
  ReassignActiveIcon,
  ReassignInactiveIcon,
  WriteConsultActiveIcon,
  WriteConsultInactiveIcon
} from '@oh-vcp/components-ui';

const EconsultCaseConsultDetailsView = () => {
  const [tabOptions, setTabOptions] = useState<EconsultActionTab[]>([]);
  const [isPdfEnabled, setIsPdfEnabled] = useState<boolean>(false);
  const [isEmrEnabled, setIsEmrEnabled] = useState<boolean>(false);
  const { caseDetails, setTimeOptions, userRole, assignerPrograms } =
    useEconsultCaseDetailsContext();
  // const [selectedConsultTab, setSelectedConsultTab] = useState<ConsultTab | undefined>(undefined);
  const { selectedTab, setSelectedTab } = useEconsultTabContext();
  const { data: options } = useTimeOptions();
  const { caseId } = useParams();
  const { user } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    if (options) {
      setTimeOptions(options);
    }
  }, [options, setTimeOptions]);

  useEffect(() => {
    const addNote: EconsultActionTab = {
      label: t('Case.consult.addNote'),
      tab: ConsultTab.AddNote,
      imageSrc:
        selectedTab === ConsultTab.AddNote ? WriteConsultActiveIcon : WriteConsultInactiveIcon,
      imageAltText: 'Add note icon',
      iconPosition: 'start',
      role: ''
    };

    const provideConsult: EconsultActionTab = {
      label: t('Case.consult.provideConsult'),
      tab: ConsultTab.Provided,
      imageSrc:
        selectedTab === ConsultTab.Provided ? WriteConsultActiveIcon : WriteConsultInactiveIcon,
      imageAltText: 'Provide consult icon',
      iconPosition: 'start',
      role: ''
    };

    const requestMoreInfo: EconsultActionTab = {
      label: t('Case.consult.requestMoreInfo'),
      tab: ConsultTab.MoreInfo,
      imageSrc:
        selectedTab === ConsultTab.MoreInfo ? AskMoreInfoActiveIcon : AskMoreInfoInactiveIcon,
      imageAltText: 'More info icon',
      iconPosition: 'start',
      role: ''
    };

    const cancel: EconsultActionTab = {
      label: t('Case.consult.cancel'),
      tab: ConsultTab.Cancel,
      imageSrc: selectedTab === ConsultTab.Cancel ? CancelActiveIcon : CancelInactiveIcon,
      imageAltText: 'Cancel icon',
      iconPosition: 'start',
      role: ''
    };

    const returnConsult: EconsultActionTab = {
      label: t('Case.consult.returnConsult'),
      tab: ConsultTab.Returned,
      imageSrc: selectedTab === ConsultTab.Returned ? DeclineActiveIcon : DeclineInactiveIcon,
      imageAltText: 'Return consult icon',
      iconPosition: 'start',
      role: ''
    };

    const complete: EconsultActionTab = {
      label: t('Case.consult.complete'),
      tab: ConsultTab.Complete,
      imageSrc: selectedTab === ConsultTab.Complete ? CloseCaseActiveIcon : CloseCaseInactiveIcon,
      imageAltText: 'Complete consult icon',
      iconPosition: 'start',
      role: ''
    };

    const requestClarification: EconsultActionTab = {
      label: t('Case.consult.requestClarification'),
      tab: ConsultTab.RequestClarification,
      imageSrc:
        selectedTab === ConsultTab.RequestClarification
          ? WriteConsultActiveIcon
          : WriteConsultInactiveIcon,
      imageAltText: 'Request clarification icon',
      iconPosition: 'start',
      role: ''
    };

    const redirect: EconsultActionTab = {
      label: t('Case.consult.redirect'),
      tab: ConsultTab.Redirect,
      imageSrc: selectedTab === ConsultTab.Redirect ? ReassignActiveIcon : ReassignInactiveIcon,
      imageAltText: 'Redirect icon',
      iconPosition: 'start',
      role: ''
    };

    const provideMoreInfo: EconsultActionTab = {
      label: t('Case.consult.provideMoreInfo'),
      tab: ConsultTab.ProvideMoreInfo,
      imageSrc:
        selectedTab === ConsultTab.ProvideMoreInfo
          ? WriteConsultActiveIcon
          : WriteConsultInactiveIcon,
      imageAltText: 'Provide more info icon',
      iconPosition: 'start',
      role: ''
    };

    const isSpecialistRespondAction = () => {
      if (
        !caseDetails ||
        (userRole !== EconsultUserRoles.consultant &&
          userRole !== EconsultUserRoles.consultantDelegate)
      ) {
        return false;
      }
      const lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }

      return (
        lastActiveNote.noteTypeCd !== NoteTypes.consultant &&
        (lastActiveNote.actionTypeCd === NoteActionTypes.send ||
          lastActiveNote.actionTypeCd === NoteActionTypes.reply ||
          lastActiveNote.actionTypeCd === NoteActionTypes.reopen ||
          lastActiveNote.actionTypeCd === NoteActionTypes.assign)
      );
    };

    const isSpecialistProvidedConsultAction = () => {
      if (
        !caseDetails ||
        (userRole !== EconsultUserRoles.consultant &&
          userRole !== EconsultUserRoles.consultantDelegate)
      ) {
        return false;
      }
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }
      return (
        lastActiveNote.actionTypeCd === NoteActionTypes.writeConsult ||
        lastActiveNote.actionTypeCd === NoteActionTypes.recomplete
      );
    };

    const isReferrerReopenedCaseAction = () => {
      if (
        !caseDetails ||
        (userRole !== EconsultUserRoles.referrer && userRole !== EconsultUserRoles.referrerDelegate)
      ) {
        return false;
      }
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }
      return (
        caseDetails.statusCd !== NoteStatusCodes.closed &&
        caseDetails.participant?.statusCd !== NoteStatusCodes.closed &&
        caseDetails.participant?.completedOn !== undefined &&
        CaseService.containsConsultProvidedNote(caseDetails) &&
        lastActiveNote.actionTypeCd !== NoteActionTypes.askForMoreInfo &&
        lastActiveNote.actionTypeCd !== NoteActionTypes.decline
      );
    };

    const isReferrerResolveCompletedConsultAction = function () {
      if (
        !caseDetails ||
        (userRole !== EconsultUserRoles.referrer && userRole !== EconsultUserRoles.referrerDelegate)
      )
        return false;
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote || caseDetails.statusCd === NoteStatusCodes.closed) {
        return false;
      }
      if (
        lastActiveNote.actionTypeCd === NoteActionTypes.writeConsult ||
        lastActiveNote.actionTypeCd === NoteActionTypes.recomplete
      ) {
        return true;
      }
      return (
        lastActiveNote.actionTypeCd === NoteActionTypes.decline &&
        CaseService.containsConsultProvidedNote(caseDetails)
      );
    };

    const isReferrerResolveDeclinedConsultAction = () => {
      if (
        !caseDetails ||
        (userRole !== EconsultUserRoles.referrer && userRole !== EconsultUserRoles.referrerDelegate)
      ) {
        return false;
      }
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }
      if (
        lastActiveNote.actionTypeCd === NoteActionTypes.decline &&
        CaseService.containsConsultProvidedNote(caseDetails)
      ) {
        return false;
      }
      return (
        lastActiveNote.actionTypeCd === NoteActionTypes.decline ||
        lastActiveNote.actionTypeCd === NoteActionTypes.unassign
      );
    };

    const isReferrerUpdateSentCaseAction = () => {
      if (
        !caseDetails ||
        (userRole !== EconsultUserRoles.referrer && userRole !== EconsultUserRoles.referrerDelegate)
      ) {
        return false;
      }
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }
      return (
        caseDetails.statusCd !== NoteStatusCodes.closed &&
        caseDetails.participant?.statusCd !== NoteStatusCodes.closed &&
        caseDetails.participant?.completedOn === undefined &&
        (caseDetails.participant?.program === undefined ||
          caseDetails.participant?.consultant === undefined) &&
        !CaseService.hasSpecialistResponded(caseDetails) &&
        lastActiveNote.actionTypeCd !== NoteActionTypes.decline &&
        lastActiveNote.actionTypeCd !== NoteActionTypes.unassign
      );
    };

    const isReferrerUpdateTriagedCaseAction = () => {
      if (
        !caseDetails ||
        (userRole !== EconsultUserRoles.referrer && userRole !== EconsultUserRoles.referrerDelegate)
      ) {
        return false;
      }
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }

      if (
        caseDetails.statusCd === NoteStatusCodes.closed ||
        caseDetails.participant?.statusCd === NoteStatusCodes.closed ||
        caseDetails.participant?.completedOn ||
        !caseDetails.participant?.program ||
        !caseDetails.participant?.consultant
      ) {
        return false;
      }

      return lastActiveNote.actionTypeCd === NoteActionTypes.assign;
    };

    const isReferrerUpdateRepliedCaseAction = () => {
      if (
        !caseDetails ||
        (userRole !== EconsultUserRoles.referrer && userRole !== EconsultUserRoles.referrerDelegate)
      ) {
        return false;
      }
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }

      if (
        caseDetails.statusCd === NoteStatusCodes.closed ||
        caseDetails.participant?.statusCd === NoteStatusCodes.closed ||
        caseDetails.participant?.completedOn ||
        !caseDetails.participant?.consultant
      ) {
        return false;
      }

      return (
        lastActiveNote.actionTypeCd === NoteActionTypes.assign ||
        (lastActiveNote.actionTypeCd === NoteActionTypes.reply &&
          !CaseService.containsConsultProvidedNote(caseDetails))
      );
    };

    const isReferrerSendMoreInfoAction = () => {
      if (
        !caseDetails ||
        (userRole !== EconsultUserRoles.referrer && userRole !== EconsultUserRoles.referrerDelegate)
      ) {
        return false;
      }
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }
      return (
        lastActiveNote.actionTypeCd === NoteActionTypes.askForMoreInfo &&
        !CaseService.containsConsultProvidedNote(caseDetails)
      );
    };

    const isSpecialistAddNoteAction = () => {
      if (
        !caseDetails ||
        (userRole !== EconsultUserRoles.consultant &&
          userRole !== EconsultUserRoles.consultantDelegate)
      ) {
        return false;
      }
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }
      return lastActiveNote.actionTypeCd === NoteActionTypes.askForMoreInfo;
    };

    const isUserTriageAssignerInProgram = function (programId: number | undefined) {
      var program = assignerPrograms?.triageAssigner?.find((id: number) => id === programId);
      if (program) return true;
      return false;
    };

    const isCurrentUserCaseTriageAdmin = () => {
      return NoteTypes.triageAdmin === userRole;
    };

    const isCurrentUserCaseTriageAssigner = () => {
      if (!isCurrentUserCaseTriageAdmin()) {
        return false;
      }
      const programId = caseDetails?.participant?.program?.programId
        ? parseInt(caseDetails.participant.program.programId)
        : undefined;
      return isUserTriageAssignerInProgram(programId);
    };

    const isTriageRedirect = () => {
      if (!caseDetails || !isCurrentUserCaseTriageAdmin()) {
        return false;
      }
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }

      return lastActiveNote.actionTypeCd === NoteActionTypes.unassign;
    };

    const isTriageAssignerRedirect = () => {
      if (!caseDetails || !isCurrentUserCaseTriageAssigner()) {
        return false;
      }
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }

      return lastActiveNote.actionTypeCd === NoteActionTypes.unassign;
    };

    const isTriageAssignerRedirectAndCancel = () => {
      if (!caseDetails || !isCurrentUserCaseTriageAssigner()) {
        return false;
      }
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }

      return lastActiveNote.actionTypeCd === NoteActionTypes.send;
    };

    const isReferrerSendMoreInfoNoCancelAction = () => {
      if (!caseDetails || !user || !EconsultUserService.isEconsultReferrer(user)) {
        return false;
      }
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }
      return (
        lastActiveNote.actionTypeCd === NoteActionTypes.askForMoreInfo &&
        CaseService.containsConsultProvidedNote(caseDetails)
      );
    };

    if (isSpecialistRespondAction()) {
      setTabOptions([provideConsult, requestMoreInfo, returnConsult]);
    } else if (isReferrerResolveCompletedConsultAction()) {
      setTabOptions([complete, requestClarification]);
    } else if (isSpecialistProvidedConsultAction() || isReferrerReopenedCaseAction()) {
      if (isSpecialistProvidedConsultAction()) {
        addNote.role = 'consultant';
      } else {
        addNote.role = 'referrer';
      }
      setTabOptions([addNote]);
    } else if (isReferrerResolveDeclinedConsultAction()) {
      setTabOptions([redirect, cancel]);
    } else if (isReferrerUpdateSentCaseAction()) {
      setTabOptions([addNote, redirect, cancel]);
    } else if (isReferrerUpdateTriagedCaseAction() || isReferrerUpdateRepliedCaseAction()) {
      setTabOptions([cancel, addNote]);
    } else if (isReferrerSendMoreInfoAction()) {
      setTabOptions([cancel, provideMoreInfo]);
    } else if (isSpecialistAddNoteAction()) {
      setTabOptions([provideConsult, addNote, returnConsult]);
    } else if (isTriageRedirect() || isTriageAssignerRedirect()) {
      setTabOptions([redirect]);
    } else if (isTriageAssignerRedirectAndCancel()) {
      setTabOptions([redirect, cancel]);
    } else if (isReferrerSendMoreInfoNoCancelAction()) {
      setTabOptions([provideMoreInfo]);
    } else {
      setTabOptions([]);
    }
    setIsPdfEnabled(!isCurrentUserCaseTriageAdmin() || isCurrentUserCaseTriageAssigner());
    if (
      !caseDetails?.patient?.noOhip &&
      caseDetails?.patient?.ohipNumber &&
      (userRole === EconsultUserRoles.consultant ||
        userRole === EconsultUserRoles.consultantDelegate ||
        userRole === EconsultUserRoles.referrer ||
        userRole === EconsultUserRoles.referrerDelegate ||
        user?.econsult?.caseConsultOn ||
        user?.econsult?.caseCreate) &&
      caseDetails.participant &&
      caseDetails.participant.consultant
    ) {
      setIsEmrEnabled(true);
    } else {
      setIsEmrEnabled(false);
    }
  }, [caseDetails, userRole, assignerPrograms, user, t, selectedTab]);

  if (!caseDetails || caseDetails.caseId?.toString() !== caseId) {
    return null;
  }

  return (
    <>
      <Stack direction="column" p={4}>
        <EconsultCaseHeader isPdfEnabled={isPdfEnabled} isEmrEnabled={isEmrEnabled} />
        <EconsultCaseConsultActionDetails options={tabOptions} />
        <EconsultCaseNotes />
      </Stack>
    </>
  );
};

export default EconsultCaseConsultDetailsView;
